import React, { useState } from 'react';
import { connect } from 'react-redux';
import ContainerWrapper from '../../components/ContainerWrapper';
import Header from '../../components/Header';
import Reports from '../../components/Reports';

const ReportsContainer = (props) => {
  const [isNavOpen, toggleContainerWidth] = useState(true);

  const keepSidebarOpen =
    props.history.action === 'PUSH' && props.location.state && props.location.state.keepSidebarOpen;
  const handleSideBarToggle = (isOpen) => {
    toggleContainerWidth(isOpen);
  };

  return (
    <>
      <Header
        isPro={props.isPro}
        isPlus={props.isPlus}
        includeNavSideBar
        keepSidebarOpen={keepSidebarOpen}
        onSideBarToggle={handleSideBarToggle}
      />
      <ContainerWrapper isNavOpen={isNavOpen}>
        <Reports />
      </ContainerWrapper>
    </>
  );
};

function mapStateToProps(state, props) {
  const { isPro, isPlus } = state.proOffer;
  return { isPro, isPlus };
}

export default connect(mapStateToProps)(ReportsContainer);
