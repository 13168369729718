import background from 'assets/img/medikura-background.png';
import LoginReset from 'components/LoginReset';
import { LoginFormWrapper, LoginHeader, LoginWrapper } from 'containers/LoginContainer/style';
import React, { Component } from 'react';

class LoginResetContainer extends Component {
  render() {
    return (
      <>
        <LoginWrapper>
          <LoginHeader />
          <LoginFormWrapper imageUrl={background}>
            <LoginReset />
          </LoginFormWrapper>
        </LoginWrapper>
      </>
    );
  }
}

export default LoginResetContainer;
