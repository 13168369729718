import I18n from 'i18n';
/* global Blob */
import React, { useState } from 'react';

import Button from '@material-ui/core/Button';

import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { CloudDownload } from '@material-ui/icons';

import * as palette from 'styles/palette';

const useStyles = makeStyles((theme) => ({
  fileDownloadContainer: {
    display: 'flex',
    margin: '0.5rem 0',
  },
  root: {
    margin: 0,
    textTransform: 'none',
    padding: theme.spacing(0.5, 2),
  },
  containedPrimary: {
    backgroundColor: palette.primaryLightGreen,
    '&:hover': {
      backgroundColor: palette.primaryLightGreen,
    },
  },
  downloadStatusContainer: {
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(0, 2),

    '& .MuiCircularProgress-colorPrimary': {
      color: palette.primaryLightGreen,
    },
  },
  errorText: {
    color: palette.primaryLightRed,
  },
}));

function FileDownload({ fileId, fileName, templateId, onFileDownload }) {
  const classes = useStyles();
  const [downloadStatus, setDownloadStatus] = useState(null);

  const handleFileDownload = async () => {
    setDownloadStatus('in-progress');

    const response = await onFileDownload(fileId, templateId);
    if (response.type === 'DOWNLOAD_FILE_SUCCESS') {
      const blobData = new Blob([response.payload.data], {
        type: 'application/octet-stream',
      });
      const url = URL.createObjectURL(blobData);

      const link = document.createElement('a');
      link.setAttribute('href', url);
      link.setAttribute('download', fileName);
      link.style.display = 'none';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(link.href);

      setDownloadStatus('success');
    }

    if (response.type === 'DOWNLOAD_FILE_FAIL') {
      console.error(response.type);
      setDownloadStatus('error');
    }
  };

  return (
    <>
      <div className={classes.fileDownloadContainer}>
        <Button
          variant="contained"
          color="primary"
          classes={{
            root: classes.root,
            containedPrimary: classes.containedPrimary,
          }}
          size="small"
          startIcon={<CloudDownload />}
          onClick={handleFileDownload}
        >
          {I18n.t('components.FileDownload.download')}
        </Button>
        <div className={classes.downloadStatusContainer}>
          {downloadStatus === 'in-progress' && <CircularProgress color="primary" size={20} />}
          {downloadStatus === 'error' && (
            <span className={classes.errorText}>{I18n.t('components.FileDownload.errorText')}</span>
          )}
        </div>
      </div>
    </>
  );
}

export default FileDownload;
