import I18n from 'i18n';
import DlgActions from 'lib/elements/DlgActions';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { GET_USER_DATA, GET_USER_DATA_PENDING } from '../../../actions';
import { updateUserData } from '../../../api/UserData';
import ErrorLabel from '../../ErrorLabel';

import { getUserToken } from 'selectors/user';
import {
  ErrorMessageContainer,
  ErrorTextLabel,
  InputContainerStyled,
  InputStyled,
  InputsContainer,
  LabelStyled,
  // MenuBtn,
  // ExplanationText,
  NoBtn,
  UpdateDataBtn,
} from './style';

class MyProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFocusOnPwd: false,
      strength: 0,
      userData: {},
      isValid: {
        firstName: true,
        lastName: true,
        email: true,
        position: true,
        phone: true,
      },
    };
  }

  componentDidMount() {
    if (this.props.userData.name) {
      this.setState({
        ...this.state,
        userData: {
          firstName: this.props.userData.name.firstName,
          lastName: this.props.userData.name.lastName,
          email: this.props.userData.email,
          phone: this.props.userData.phoneNumber,
          position: this.props.userData.position,
        },
      });
    }
  }

  async handleSendUserdataBtn() {
    // the default value comes from this.props
    // if the user doesnt touch the input fields, the state for these fields remains undefined and they need to be filled with default data and sent to the server

    const { firstName, lastName, email, position, role, phone } = this.state.userData;
    const {
      email: defaultEmail,
      phone: defaultPhone,
      role: defaultRole,
      position: defaultposition,
      name,
    } = this.props.userData;
    const { firstName: defaultFirstName, lastName: defaultLastName } = name;

    if (typeof firstName === 'undefined') {
      await this.setState({
        userData: { ...this.state.userData, firstName: defaultFirstName },
      });
    }

    if (typeof lastName === 'undefined') {
      await this.setState({
        userData: { ...this.state.userData, lastName: defaultLastName },
      });
    }

    if (typeof email === 'undefined') {
      await this.setState({
        userData: { ...this.state.userData, email: defaultEmail },
      });
    }

    if (typeof position === 'undefined') {
      await this.setState({
        userData: { ...this.state.userData, position: defaultposition },
      });
    }

    if (typeof role === 'undefined') {
      await this.setState({
        userData: { ...this.state.userData, role: defaultRole },
      });
    }

    if (typeof phone === 'undefined') {
      await this.setState({
        userData: { ...this.state.userData, phone: defaultPhone },
      });
    }

    if (Object.values(this.state.isValid).includes(false)) {
      return;
    }

    const dataToSend = {
      name: {
        firstName,
        lastName,
      },
      position: position,
      phone,
    };

    try {
      await updateUserData(dataToSend, this.props.userToken);
      this.props.onClose();
      this.props.getUserData(this.props.userToken);
    } catch (error) {}
  }

  onChangeData(element, value) {
    // phone and position are optional so they are always 'valid'
    if (value.length < 46) {
      this.setState({
        userData: { ...this.state.userData, [element]: value },
        isValid: {
          ...this.state.isValid,
          [element]: element !== 'phone' && element !== 'position' ? !!value.length : true,
        },
      });
    }
  }

  render() {
    const { isValid, userData } = this.state;
    const { firstName, lastName, email, phone, position } = userData;

    return (
      <>
        <InputsContainer>
          <InputContainerStyled>
            <LabelStyled htmlFor="firstName">
              {I18n.t('components.UserDataModal.MyProfile.firstname')}
            </LabelStyled>
            <InputStyled
              haserror={!isValid.firstName}
              id="firstName"
              name="firstName"
              value={firstName}
              onChange={(event) => this.onChangeData('firstName', event.currentTarget.value)}
              type="text"
              required
            />
          </InputContainerStyled>
          <InputContainerStyled>
            <LabelStyled htmlFor="lastName">
              {I18n.t('components.UserDataModal.MyProfile.lastName')}
            </LabelStyled>
            <InputStyled
              haserror={!isValid.lastName}
              id="lastName"
              name="lastName"
              onChange={(event) => {
                this.onChangeData('lastName', event.currentTarget.value);
              }}
              value={lastName}
              type="text"
              required
            />
          </InputContainerStyled>
          <InputContainerStyled>
            <LabelStyled htmlFor="email">
              {I18n.t('components.UserDataModal.MyProfile.email')}
            </LabelStyled>
            <InputStyled
              haserror={!isValid.email}
              id="email"
              name="email"
              value={email}
              type="text"
              disabled
              required
            />
            <ErrorLabel errText="Dies ist keine zulässige E-Mail" hasError={!isValid.email} />
          </InputContainerStyled>
          <InputContainerStyled>
            <LabelStyled htmlFor="phone">
              {I18n.t('components.UserDataModal.MyProfile.phone')}
            </LabelStyled>
            <InputStyled
              haserror={!isValid.phone}
              id="phone"
              name="phone"
              onChange={(event) => {
                this.onChangeData('phone', event.currentTarget.value);
              }}
              value={phone}
              type="text"
            />
          </InputContainerStyled>
          <InputContainerStyled>
            <LabelStyled htmlFor="position">
              {I18n.t('components.UserDataModal.MyProfile.position')}
            </LabelStyled>
            <InputStyled
              haserror={!isValid.position}
              id="position"
              name="position"
              onChange={(event) => {
                this.onChangeData('position', event.currentTarget.value);
              }}
              type="text"
              value={position}
              required
            />
          </InputContainerStyled>
          <ErrorMessageContainer>
            {this.state.showErrorMessage && (
              <ErrorTextLabel>{I18n.t('components.UserDataModal.MyProfile.error')}</ErrorTextLabel>
            )}
          </ErrorMessageContainer>
          <DlgActions style={{ justifyContent: 'space-between', padding: 0 }}>
            <NoBtn onClick={this.props.onClose}>
              {I18n.t('components.UserDataModal.MyProfile.cancelButton')}
            </NoBtn>
            <UpdateDataBtn onClick={this.handleSendUserdataBtn.bind(this)} variant="contained">
              {I18n.t('components.UserDataModal.MyProfile.saveButton')}
            </UpdateDataBtn>
          </DlgActions>
        </InputsContainer>
      </>
    );
  }
}

function mapStateToProps(state) {
  const { userData } = state.userData;
  return { userData, userToken: getUserToken(state) };
}

function mapDispatchToProps(dispatch) {
  return {
    getUserData: async (token) => {
      dispatch({ type: GET_USER_DATA_PENDING });
      dispatch(await GET_USER_DATA(token));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MyProfile);
