import CircularProgress from '@material-ui/core/CircularProgress';
import { ACCOUNT_CREATION, ACCOUNT_CREATION_PENDING } from 'actions/register/index';
// TODO uncomment in v2 to enable checkbox
import MedCheckbox from 'lib/elements/Checkbox';
import Input from 'lib/elements/Input';
import InputContainer from 'lib/elements/InputContainer';
import passwordMeter from 'passwordmeter';
import React, { Component } from 'react';
import { Trans, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { getUserToken, isUserLoggedIn } from 'selectors/user';
import * as palette from 'styles/palette';
import validator from 'validator';
import ErrorLabel from '../ErrorLabel';
import PwdValidationLabel from '../PwdValidationLabel';
import {
  // TODO uncomment in v2 to enable checkbox
  AgreementBox,
  AgreementTxt,
  ErrorMessageContainer,
  ErrorTextLabel,
  RegisterBtn,
  RegisterContainer,
  // RegisterTitle,
  // RegisterText,
  RegisterForm,
  RegisterLabel,
  RegisterTitle,
} from './style';

const styledLink = {
  color: `${palette.blueGrey}`,
};

class Register extends Component {
  state = {
    isChecked: false,
    goToConfirm: false,
    isLoading: false,

    // Form values
    userData: {
      name: {
        firstName: '',
        lastName: '',
      },
      email: '',
      password: '',
      confirmpassword: '',
      phoneNumber: '',
    },

    isAnInvitation: false,
    isFocusOnPwd: false,
    strength: 0,

    // validations
    isValid: {
      firstName: true,
      lastName: true,
      email: true,
      password: true,
      confirmpassword: true,
      isChecked: true,
      phoneNumber: true,
    },
    showErrorMessage: false,
  };

  async componentDidMount() {
    const decodedQueryParams = decodeURIComponent(window.location.href).split('?')[1] || '';
    const arrayQueryParams = decodedQueryParams.split('&');

    const paramsObj = {};
    for (const queryParam of arrayQueryParams) {
      paramsObj[queryParam.split('=')[0]] = queryParam.split('=')[1];
    }

    if (paramsObj.email) {
      this.setState({ isAnInvitation: true });
    }

    if (paramsObj.token && this.props.isUserAuthenticated) {
      // this means that the user is already registered and logged in
      window.location.href = '/reports';
    }
    delete paramsObj.token;

    await this.setState({
      userData: {
        ...this.state.userData,
        email: paramsObj.email,
        name: {
          firstName: paramsObj.firstName,
          lastName: paramsObj.lastName,
        },
      },
    });
  }

  handleChangeCheckBox = () => {
    const { isValid } = this.state;
    this.setState({ isChecked: !this.state.isChecked }, () => {
      isValid.isChecked = this.state.isChecked;
      this.setState({ isValid });
    });
  };

  handleChangeFName = (event) => {
    const { isValid, userData } = this.state;
    const { name } = userData;

    this.setState(
      {
        userData: {
          ...userData,
          name: {
            ...name,
            firstName: event.currentTarget.value,
          },
        },
      },
      () => {
        if (!validator.isEmpty(this.state.userData.name.firstName)) {
          isValid.firstName = true;
        } else {
          isValid.firstName = false;
        }
        this.setState({ isValid });
      },
    );
  };

  handleChangeLName = (event) => {
    const { isValid, userData } = this.state;
    const { name } = userData;

    this.setState(
      {
        userData: {
          ...userData,
          name: {
            ...name,
            lastName: event.currentTarget.value,
          },
        },
      },
      () => {
        if (!validator.isEmpty(this.state.userData.name.lastName)) {
          isValid.lastName = true;
        } else {
          isValid.lastName = false;
        }
        this.setState({ isValid });
      },
    );
  };

  handleChangeEmail = (event) => {
    const { isValid, userData } = this.state;
    this.setState(
      {
        userData: {
          ...userData,
          email: event.currentTarget.value,
        },
      },
      () => {
        if (validator.isEmail(this.state.userData.email)) {
          isValid.email = true;
        } else {
          isValid.email = false;
        }
        this.setState({ isValid });
      },
    );
  };

  handleChangePhoneNr = (event) => {
    // check if it's a phone number. For now it only accepts numbers and special chars / and +
    // TODO: improve phone number check with external library
    const checkIfPhoneNrHasLetters = /[a-z]/i;
    const phoneNumber = event.currentTarget.value;
    const { isValid, userData } = this.state;

    this.setState(
      {
        userData: {
          ...userData,
          phoneNumber,
        },
      },
      () => {
        if (!checkIfPhoneNrHasLetters.test(phoneNumber) && phoneNumber.length < 26) {
          isValid.phoneNumber = true;
        } else {
          isValid.phoneNumber = false;
        }
        this.setState({ isValid });
      },
    );
  };

  handleChangePassword = (event) => {
    const { isValid, userData } = this.state;
    this.setState(
      {
        userData: {
          ...userData,
          password: event.currentTarget.value,
        },
      },
      () => {
        if (
          validator.isEmpty(this.state.userData.password) ||
          Math.floor(passwordMeter.checkPass(this.state.userData.password, 8) / 25) < 1
        ) {
          isValid.password = false;
        } else {
          isValid.password = true;
        }
        this.setState({
          isValid,
          strength: passwordMeter.checkPass(this.state.userData.password, 8),
        });
      },
    );
  };

  handleChangeConfirmPassword = (event) => {
    const { isValid, userData } = this.state;
    this.setState(
      {
        userData: {
          ...userData,
          confirmpassword: event.currentTarget.value,
        },
      },
      () => {
        if (
          validator.isEmpty(this.state.userData.confirmpassword) ||
          this.state.userData.password !== this.state.userData.confirmpassword
        ) {
          isValid.confirmpassword = false;
        } else {
          isValid.confirmpassword = true;
        }
        this.setState({ isValid });
      },
    );
  };

  handlePwdFocus = () => {
    this.setState({ isFocusOnPwd: true });
  };

  handlePwdBlur = () => {
    this.setState({ isFocusOnPwd: false });
  };

  isValidation = () => {
    const { isChecked, isValid, userData } = this.state;
    const { name, email, password, confirmpassword, phoneNumber } = userData;
    const { firstName, lastName } = name;

    // check if it's a phone number. For now it only accepts numbers and special chars / and +
    // TODO: improve phone number check with external library
    const checkIfPhoneNrHasLetters = /[a-z]/i;

    if (!firstName.toString().trim().length) {
      isValid.firstName = false;
    }

    if (!lastName.toString().trim().length) {
      isValid.lastName = false;
    }

    if (!validator.isEmail(email)) {
      isValid.email = false;
    }

    if (
      !password.toString().trim().length ||
      Math.floor(passwordMeter.checkPass(password, 8) / 25) < 1
    ) {
      isValid.password = false;
    }

    if (!confirmpassword.toString().trim().length || password !== confirmpassword) {
      isValid.confirmpassword = false;
    }

    if (checkIfPhoneNrHasLetters.test(phoneNumber) || phoneNumber.length > 25) {
      isValid.phoneNumber = false;
    }

    if (!isChecked) {
      isValid.isChecked = false;
    }

    const formValid =
      isValid.firstName &&
      isValid.lastName &&
      isValid.email &&
      isValid.password &&
      isValid.confirmpassword &&
      isValid.isChecked &&
      isValid.phoneNumber;

    this.setState({ isValid });

    return formValid;
  };

  handleGoToConfirm = async () => {
    this.setState({ isLoading: true });
    if (!this.isValidation()) {
      this.setState({ isLoading: false });
      return;
    }
    await this.props.register(this.state.userData, this.props.userToken);
    const hasErrors = !!Object.entries(this.props.errors).length;
    const errorMessage =
      this.props.errors.errors === 400
        ? this.props.t('components.Register.errors.emailAlreadyUsed')
        : this.props.t('components.Register.errors.unknown');

    this.setState({
      goToConfirm: !hasErrors && !this.props.onDoneRegistration,
      isLoading: false,
      showErrorMessage: hasErrors,
      errorMessage: errorMessage,
    });
    this.props.onDoneRegistration &&
      !hasErrors &&
      this.props.onDoneRegistration(this.state.isAnInvitation);
  };

  render() {
    const {
      // TODO uncomment in v2 to enable checkbox
      isChecked,
      goToConfirm,
      isValid,
      isFocusOnPwd,
      strength,
      userData,
      isAnInvitation,
    } = this.state;
    if (goToConfirm) {
      return <Redirect to="/register-confirm" />;
    }

    return (
      <RegisterContainer>
        <RegisterForm>
          <RegisterTitle>{this.props.t('components.Register.title')}</RegisterTitle>
          {/* <br /> */}
          {/* <RegisterText>Das Beste daran? Sie dürfen das alles kostenfrei testen und sich selsbt überzeugen!</RegisterText> */}
          <RegisterLabel>{this.props.t('components.Register.label')}</RegisterLabel>
          <InputContainer>
            <Input
              haserror={!isValid.firstName}
              name="firstName"
              onChange={this.handleChangeFName}
              placeholder={this.props.t('components.Register.placeholders.firstName')}
              type="text"
              value={userData.name.firstName}
            />
          </InputContainer>
          <InputContainer>
            <Input
              haserror={!isValid.lastName}
              name="lastName"
              onChange={this.handleChangeLName}
              placeholder={this.props.t('components.Register.placeholders.lastName')}
              type="text"
              value={userData.name.lastName}
            />
          </InputContainer>
          <InputContainer>
            <Input
              haserror={!isValid.email}
              name="email"
              onChange={this.handleChangeEmail}
              placeholder={this.props.t('components.Register.placeholders.email')}
              type="text"
              value={userData.email}
              disabled={isAnInvitation}
            />
            <ErrorLabel
              errText={this.props.t('components.Register.errors.invalidEmail')}
              hasError={!isValid.email}
            />
          </InputContainer>
          <InputContainer>
            <InputContainer>
              <Input
                haserror={!isValid.password}
                name="password"
                onBlur={this.handlePwdBlur}
                onChange={this.handleChangePassword}
                onFocus={this.handlePwdFocus}
                placeholder={this.props.t('components.Register.placeholders.password')}
                type="password"
              />
              <ErrorLabel
                errText={this.props.t('components.Register.errors.weakPassword')}
                hasError={!isValid.password}
              />
            </InputContainer>
            <InputContainer>
              <Input
                haserror={!isValid.confirmpassword}
                name="confirmpassword"
                onChange={this.handleChangeConfirmPassword}
                placeholder={this.props.t('components.Register.placeholders.confirmPassword')}
                type="password"
              />
              <ErrorLabel
                errText={this.props.t('components.Register.errors.passwordsDoesNotMatch')}
                hasError={!isValid.confirmpassword}
              />
            </InputContainer>
            {!validator.isEmpty(this.state.userData.password) && (
              <PwdValidationLabel isFocus={isFocusOnPwd} strength={strength} />
            )}
          </InputContainer>
          {!isAnInvitation && (
            <InputContainer>
              <Input
                haserror={!isValid.phoneNumber}
                name="phoneNumber"
                onChange={this.handleChangePhoneNr}
                placeholder={this.props.t('components.Register.placeholders.phoneNumber')}
                type="text"
              />
              <ErrorLabel
                errText={this.props.t('components.Register.errors.invalidPhoneNumber')}
                hasError={!isValid.phoneNumber}
              />
            </InputContainer>
          )}
          {/* TODO uncomment in v2 */}
          <InputContainer>
            <AgreementBox>
              <MedCheckbox checked={isChecked} onChange={this.handleChangeCheckBox} />
              <AgreementTxt hasError={!isValid.isChecked}>
                <Trans i18nKey="components.Register.agreementText">
                  <a
                    href="https://xo-life.com/agb-13-03-2023"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={styledLink}
                  />
                  <a
                    href="https://www.xo-life.com/datenschutz/"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={styledLink}
                  />
                </Trans>
              </AgreementTxt>
            </AgreementBox>
          </InputContainer>

          {this.state.showErrorMessage && (
            <ErrorMessageContainer>
              <ErrorTextLabel>{this.state.errorMessage}</ErrorTextLabel>
            </ErrorMessageContainer>
          )}

          {this.state.isLoading ? (
            <CircularProgress />
          ) : (
            <RegisterBtn onClick={this.handleGoToConfirm} variant="contained">
              {this.props.t('components.Register.register')}
            </RegisterBtn>
          )}
        </RegisterForm>
      </RegisterContainer>
    );
  }
}

function mapStateToProps(state) {
  const { errors, isRegistering, registered } = state.register;
  return {
    errors,
    isRegistering,
    registered,
    userToken: getUserToken(state),
    isUserAuthenticated: isUserLoggedIn(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    register: async (data, token) => {
      dispatch({ type: ACCOUNT_CREATION_PENDING });
      const res = await ACCOUNT_CREATION(data, token);
      return dispatch(res);
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Register));
