import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import React from 'react';
import styled from 'styled-components';
import * as palette from 'styles/palette';

export const HeaderReportContainer = styled.div`
  border-radius: 0.7rem;
  padding: 1.5rem 2rem;
  background-color: ${palette.white};
  justify-content: space-between;
`;

export const Title = styled.span`
  font-style: normal;
  font-weight: bold;
  font-size: 1.4rem;
  line-height: 19px;
  color: ${palette.primaryDarkGreen};

  padding-right: 5px;
  padding-bottom: 15px;
`;

export const Value = Title.extend`
  font-style: normal;
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 19px;
  color: ${palette.primaryDarkGrey};

  padding-right: 15px;
  padding-bottom: 15px;
`;

export const MetadataTable = styled.table`
  table-layout: fixed;
  width: 100%;
`;

const Cell = styled.td`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: top;
`;

export const HeaderCell = styled(Cell)`
  font-style: normal;
  font-weight: bold;
  font-size: 1.4rem;
  line-height: 20px;
  color: ${palette.primaryDarkGrey};
`;

export const LabelCell = styled(Cell)`
  font-style: normal;
  font-weight: bold;
  font-size: 1.3rem;
  line-height: 20px;
  color: ${palette.primaryDarkGreen};
`;

export const ValueCell = styled(Cell)`
  font-style: normal;
  font-weight: normal;
  font-size: 1.3rem;
  line-height: 20px;
  color: ${palette.primaryDarkGrey};
`;

export const AddressCell = styled(Cell)`
  font-style: normal;
  font-weight: normal;
  font-size: 1.3rem;
  line-height: 20px;
  color: ${palette.primaryDarkGrey};
`;

export const DownloadBtn = styled((props) => <Button {...props} />)`
  right: 25px;
  width: 140px;
  background-color: ${palette.primaryLightGreen} !important;
  border-radius: 7px !important;
  span {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: ${palette.white};
  }
  float: right;
  text-transform: none !important;
`;

export const DownloadProgress = styled((props) => <CircularProgress {...props} />)`
  margin-right: 10px;
  svg {
    color: ${palette.white};
  }
`;

export const MenuItemTxt = styled.span`
  color: ${palette.primaryDarkGrey};
  opacity: ${(props) => (props.isDisabled ? '0.3' : '1')};
  padding-right: 1rem;
  line-height: 4rem;
`;

export const ProText = styled.span`
  color: ${palette.primaryDarkRed};
  font-size: 0.9rem;
  line-height: 1rem;
  font-weight: 700;
`;

export const MenuLineItem = styled((props) => <MenuItem {...props} />)`
  &&& {
    display: flex;
    justify-content: space-between;
    padding: 0 15px !important;
    width: 140px;
    opacity: 1;
    border-bottom: 0.5px solid ${palette.lineBGColor};
    &:last-child {
      border-bottom: none;
    }
  }
`;

export const CustomMenu = styled((props) => <Menu {...props} />)`
  &&& {
    border-radius: 0.7rem;
    width: 18rem;
    margin-top: 5rem;
  }
`;
export const ErrorText = styled.div`
  margin-top: 15px;
  text-align: right;
  color: red;
`;
export const PremiumTeaser = styled.span`
  font-weight: 600;
  font-size: 10px;
  color: ${palette.primaryDarkGreen};
`;
export const PharmaInternalIdContainer = styled.div`
  display: flex;
  align-items: baseline;
  margin-top: 3px;
  margin-bottom: 10px;
`;
