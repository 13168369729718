import seedrandom from 'seedrandom';
import { PROFILE_COLORS } from '../constants';

export const getProfileColor = (userId) => {
  // returns a color predictably based on a seed
  if (userId) {
    const seededRandom = seedrandom(userId);
    return PROFILE_COLORS[Math.floor(seededRandom() * (PROFILE_COLORS.length - 1))];
  }
};
