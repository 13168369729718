import I18n from 'i18n';
import PropTypes from 'prop-types';
import React from 'react';
import * as palette from 'styles/palette';
import {
  Line,
  LineBox,
  PwdValidationContainer,
  ShouldContain,
  StengthBox,
  WeightText,
} from './style';

const strengths = ['Schwach', 'Normal', 'Stark', 'Sehr stark'];
const colors = [
  `${palette.errorBGColor}`,
  `${palette.yellow}`,
  `${palette.shinyGreen}`,
  `${palette.green}`,
];
const defaultColor = `${palette.lineBGColor}`;
const weight = (strength) =>
  Math.floor(strength / 25) > 3 ? Math.floor(strength / 25) - 1 : Math.floor(strength / 25);

const PwdValidationLabel = ({ strength, isFocus }) => {
  return (
    <PwdValidationContainer isFocus={isFocus}>
      <StengthBox>
        <span>{I18n.t('components.PwdValidationLabel.passwordStrength')}</span>
        <WeightText
          color={Math.floor(strength / 25) > 3 ? colors[3] : colors[Math.floor(strength / 25)]}
        >
          {strengths[weight(strength)]}
        </WeightText>
      </StengthBox>
      <LineBox>
        <Line
          color={
            Math.floor(strength / 25) > -1
              ? colors[Math.floor(strength / 25) > 2 ? 3 : Math.floor(strength / 25)]
              : defaultColor
          }
        />
        <Line
          color={
            Math.floor(strength / 25) > 0
              ? colors[Math.floor(strength / 25) > 2 ? 3 : Math.floor(strength / 25)]
              : defaultColor
          }
        />
        <Line
          color={
            Math.floor(strength / 25) > 1
              ? colors[Math.floor(strength / 25) > 2 ? 3 : Math.floor(strength / 25)]
              : defaultColor
          }
        />
        <Line color={Math.floor(strength / 25) > 2 ? colors[3] : defaultColor} />
      </LineBox>
      <ShouldContain>
        <div>{I18n.t('components.PwdValidationLabel.shouldContain')}</div>
        {I18n.t('components.PwdValidationLabel.variables')}
        <br />
        {I18n.t('components.PwdValidationLabel.strength')}
      </ShouldContain>
    </PwdValidationContainer>
  );
};

PwdValidationLabel.propTypes = {
  strength: PropTypes.number.isRequired,
  isFocus: PropTypes.bool,
};

export default PwdValidationLabel;
