import styled from 'styled-components';
import * as palette from 'styles/palette';

export const DetalViewContainer = styled.div`
  padding-top: 1.5rem;
`;

export const DetailViewBody = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: ${palette.white};
  border-radius: 5px;
  overflow-y: scroll;
  height: calc(100vh - 492px);
`;

export const BrufinImg = styled.img`
  padding-right: 1rem;
`;
