import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MedButton from 'lib/elements/MedButton';
import React from 'react';
import styled from 'styled-components';
import * as palette from 'styles/palette';

export const MedModalDlg = styled(({ isClicked, ...props }) => <Dialog {...props} />)`
  &&& > div {
    border-radius: 0.7rem;
  }

  &&& > div > div {
    overflow: visible;
  }
`;
export const InviteMemberForm = styled.form`
  padding: 3rem 5rem 3rem 5rem;
  text-align: center;
  @media (max-width: 375px) {
    padding: 0 2rem;
  }
  width: 300px;
  height: 100%;
`;

export const InvitationLabel = styled.span`
  color: ${palette.primaryDarkGrey};
  font-size: 1.3em;
  font-weight: 600;
  color: ${palette.primaryDarkGreen};
`;

export const ErrorMessageContainer = styled.div`
  height: 30px;
  padding: 10px 0 10px 0;
}
`;
export const ErrorTextLabel = styled.div`
  color: ${palette.primaryLightRed};
  font-size: 14px;
`;
export const InvitationBtn = styled(MedButton)`
  &&& {
    background-color: ${palette.primaryDarkGreen};
    &:hover {
      background-color: ${palette.primaryLightGreen};
    }
  }
`;

export const MenuBtn = styled(({ hasError, isDefault, ...props }) => <Button {...props} />)`
  &&& {
    width: 100%;
    border: 1px solid
      ${(props) => (props.hasError ? palette.errorBGColor : palette.primaryDarkGreen)};
    border-radius: 0.7rem;
    padding: 0 0.5rem;
    text-align: center;
    min-height: 2.4rem;
    height: 2.4rem;
    padding-left: 0;
    opacity: ${(props) => (props.isDisabled === true ? '0.3' : '1')};
    & > span {
      display: flex;
      justify-content: center;
      align-items: center;
      color: ${(props) => (props.isDefault ? palette.primaryDarkGreen : palette.primaryDarkGrey)};
      text-transform: none;
      & > svg {
        position: absolute;
        right: 0;
      }
    }
  }
`;

export const ExplanationText = styled.span``;

export const NoBtn = styled(MedButton)`
  &&& {
    background-color: ${palette.primaryDarkRed};
    &:hover {
      background-color: ${palette.primaryLightRed};
    }
  }
`;
