import { passwordResetAPI } from 'api';

export const PASSWORD_RESET_PENDING = 'PASSWORD_RESET_PENDING';
export const PASSWORD_RESET_SUCCESS = 'PASSWORD_RESET_SUCCESS';
export const PASSWORD_RESET_FAIL = 'PASSWORD_RESET_FAIL';

export const PASSWORD_RESET = async (data) => {
  try {
    const res = await passwordResetAPI(data);
    return { type: PASSWORD_RESET_SUCCESS, payload: { user: res.data } };
  } catch (err) {
    return { type: PASSWORD_RESET_FAIL, payload: err.response.data };
  }
};
