import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import {
  GET_USER_COMPANY_DATA,
  LOGOUT_AUTH_SUCCESS,
  SET_INVALID_TOKEN_ERROR,
  SET_TOKEN_EXPIRED_ERROR,
  SET_URL_TOKEN,
} from 'actions';
import { getUserToken, isUserLoggedIn } from 'selectors/user';
import { getToken, isTokenExpired } from './../utilities/urlHelper';

/**
 * WithUrlToken is a HOF that wraps a HOC, used at route level for route which consumes token from url
 * @param {Component} ComponentToRender Component from route config
 * @returns {Component} if user already logged In or token present in URL ComponentToRender
 * else redirect to login
 */

export default function WithUrlToken(ComponentToRender) {
  class AnonymousAuthComponent extends Component {
    state = {
      isSettingToken: false,
    };

    // TODO :: Change this legacy lifecycle method via MED-1351
    async componentWillMount() {
      // If user is already loggedIn (Basic Plus or Pro user), do nothing just render the route component
      if (!this.props.userLoggedIn) {
        // If user not logged In, fetch token from url
        // If token is present in Url, a token is returned else null is returned
        const token = getToken();

        if (token === null) {
          this.props.setInvalidTokenError();
        } else {
          this.setState({ isSettingToken: true });

          // if token is present but expired, show error message
          const expiredToken = await isTokenExpired(token);
          if (token && expiredToken) {
            this.props.setTokenExpiredError();
          } else if (token && !expiredToken) {
            // dispatch action to set the token
            await this.setToken(token);

            if (this.props.location.pathname.includes('/reports/')) {
              await this.props.getCompanyData(token);
            }
          }

          this.setState({
            isSettingToken: false,
          });
        }
      }
    }

    setToken = async (token) => {
      await this.props.setUrlToken(token);
    };

    render() {
      const { userToken } = this.props;

      // Setting the url token, return nothing, spinner will be shown
      if (this.state.isSettingToken) {
        return '';
      }
      // No token or expired token, no access
      if (!this.state.isSettingToken && !userToken) {
        if (this.props.location.pathname === '/login/newPassword') {
          return (
            <Redirect
              to={{
                pathname: '/login/reset',
              }}
            />
          );
        }
        return (
          <Redirect
            to={{
              pathname: '/error',
            }}
          />
        );
      } else if (userToken) {
        return <ComponentToRender {...this.props} />;
      }
    }
  }

  const mapStateToProps = (state) => ({
    userLoggedIn: isUserLoggedIn(state),
    userToken: getUserToken(state),
  });

  const mapDispatchToProps = (dispatch) => {
    return {
      setUrlToken: async (token) => {
        await dispatch({ type: SET_URL_TOKEN, payload: token });
      },
      getCompanyData: async (token) => {
        await dispatch(await GET_USER_COMPANY_DATA(token));
      },
      logout: async () => {
        dispatch({ type: LOGOUT_AUTH_SUCCESS });
      },
      setTokenExpiredError: () => {
        dispatch({ type: SET_TOKEN_EXPIRED_ERROR, payload: true });
      },
      setInvalidTokenError: () => {
        dispatch({ type: SET_INVALID_TOKEN_ERROR, payload: true });
      },
    };
  };

  AnonymousAuthComponent.propTypes = {
    userLoggedIn: PropTypes.bool.isRequired,
    userToken: PropTypes.string.isRequired,
  };

  return connect(mapStateToProps, mapDispatchToProps)(AnonymousAuthComponent);
}
