import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import I18n from 'i18n';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import DropDownMenu from '../../../../../../DropDownMenu';
import { DisplayFlex, HiddenCommentBtn, InputLabel, MenuBtn, TextBox } from './style';

const menuList = [
  I18n.t(
    'components.ReportDetail.DetailContainer.RightDetailView.HabitsModal.HabitsComment.stress',
  ),
  I18n.t(
    'components.ReportDetail.DetailContainer.RightDetailView.HabitsModal.HabitsComment.alcohol',
  ),
  I18n.t('components.ReportDetail.DetailContainer.RightDetailView.HabitsModal.HabitsComment.sport'),
  I18n.t('components.ReportDetail.DetailContainer.RightDetailView.HabitsModal.HabitsComment.diet'),
  I18n.t('components.ReportDetail.DetailContainer.RightDetailView.HabitsModal.HabitsComment.other'),
];
const defaultTagName = I18n.t(
  'components.ReportDetail.DetailContainer.RightDetailView.HabitsModal.HabitsComment.defaultTagName',
);

class HabitsComment extends Component {
  static propTypes = {
    onAddHabits: PropTypes.func,
    onRemoveHabits: PropTypes.func,
    index: PropTypes.number,
  };

  state = {
    anchorEl: null,
    tagName: defaultTagName,
    isShowDetails: false,
  };

  handleClose = (item) => {
    this.setState({ anchorEl: null, tagName: item !== 'close' ? item : this.state.tagName }, () => {
      if (this.state.tagName !== 'Bitte wählen Sie') {
        this.setState({ isShowDetails: true }, () => {
          this.props.onAddHabits();
        });
      } else {
        this.setState({ isShowDetails: false }, () => {
          this.props.onRemoveHabits();
        });
      }
    });
  };

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleHiddenComment = () => {
    this.setState({ isShowDetails: false }, () => {
      this.setState({ tagName: defaultTagName });
      this.props.onRemoveHabits();
    });
  };

  render() {
    const { anchorEl, tagName, isShowDetails } = this.state;
    return (
      <div>
        {this.props.index < 1 ? (
          <InputLabel>
            {I18n.t(
              'components.ReportDetail.DetailContainer.RightDetailView.HabitsModal.HabitsComment.label1',
            )}
          </InputLabel>
        ) : isShowDetails ? (
          <InputLabel style={{ marginTop: this.props.index < 1 ? '0' : '1.3rem' }} />
        ) : (
          <InputLabel style={{ marginTop: this.props.index < 1 ? '0' : '1.3rem' }}>
            {I18n.t(
              'components.ReportDetail.DetailContainer.RightDetailView.HabitsModal.HabitsComment.label2',
            )}
          </InputLabel>
        )}
        <DisplayFlex>
          <MenuBtn
            aria-haspopup="true"
            aria-owns={anchorEl ? 'customs-menu' : null}
            hasError={false}
            id="customs-btn"
            isDefault={
              tagName ===
              I18n.t(
                'components.ReportDetail.DetailContainer.RightDetailView.HabitsModal.HabitsComment.defaultTagName',
              )
            }
            onClick={this.handleClick}
          >
            <span>{tagName}</span>
            <ArrowDropDownIcon />
          </MenuBtn>
          {isShowDetails && <HiddenCommentBtn onClick={this.handleHiddenComment} />}
        </DisplayFlex>
        <DropDownMenu
          anchorEl={anchorEl}
          buttonId="customs-btn"
          dropdownList={menuList}
          menuId="customs-menu"
          onHandleClose={this.handleClose}
        />
        {isShowDetails && (
          <TextBox
            placeholder={I18n.t(
              'components.ReportDetail.DetailContainer.RightDetailView.HabitsModal.HabitsComment.defaultTagName',
            )}
          />
        )}
      </div>
    );
  }
}

export default HabitsComment;
