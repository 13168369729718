import { Button } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Edit } from '@material-ui/icons';
import MaterialTable from 'material-table';
import React from 'react';
import styled from 'styled-components';
import * as palette from 'styles/palette';
import MenuItemComponent from '../LoopDropdownMenu';

export const FollowupsContainer = styled.div`
  background-color: ${palette.white};
  max-width: 126.3rem;
  margin: auto;
  margin-top: 6.6em;
  padding: 4em;
  border-radius: 7px;
  @media only screen and (max-width: 1470px) {
    padding: 2.6em;
    margin-left: 1.4rem;
  }
`;

export const MaterialTableStyled = styled((props) => <MaterialTable {...props} />)`
  th > {
    font-size: 3rem;
  }
`;

export const FollowupsTitle = styled.span`
  color: ${palette.primaryDarkGrey};
  font-size: 1.3em;
  font-weight: 550;
  color: ${palette.primaryDarkGreen};
`;
export const NewFollowupBtn = styled((props) => <Button {...props} />)`
  background-color: ${palette.primaryLightGreen} !important;
  border-radius: 7px !important;
  width: 140px;
  span {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: ${palette.white};
  }
  float: right;
  text-transform: none !important;
  opacity: ${(props) => (props.isDisabled === true ? '0.3' : '1')} !important;
`;

export const MenuItemComponentStyled = styled((props) => <MenuItemComponent {...props} />)``;

export const EditStyled = styled((props) => <Edit {...props} />)`
  &&& {
    color: ${palette.primaryDarkGreen};
    margin-right: 20px;
    &:hover {
      opacity: 0.2;
      cursor: pointer;
    }
  }
`;

export const LoadingSpinner = styled((props) => <CircularProgress {...props} />)`
  &&& {
    color: ${palette.primaryLightGreen};
  }
`;

export const SpinnerContainer = styled.div`
  position: absolute;
  left: 50%;
`;
