import Tooltip from '@material-ui/core/Tooltip';
import { Delete, Done } from '@material-ui/icons';
import {
  DELETE_TEAM_MEMBER,
  DELETE_TEAM_MEMBER_PENDING,
  GET_TEAM,
  GET_TEAM_PENDING,
  REINVITE_COLLEAGUE_PENDING,
  REINVITE_TEAM_MEMBER,
} from 'actions';
import moment from 'moment';
import React, { Component } from 'react';
import { Trans, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { getUserToken } from 'selectors/user';
import { withMedTracker } from 'services/tracking';
import * as palette from 'styles/palette';
import TeamInviteModal from './InviteTeamMember';
import { MedModalDlg } from './InviteTeamMember/style';
import {
  CloseIconStyled,
  DeleteUserContent,
  DeleteUserHeader,
  DeleteUserTitle,
  // RoleBadge,
  Divider,
  DlgActionsStyled,
  ExpirationDate,
  InviteTeamMemberButton,
  NoBtn,
  PendingInvitationActionsContainer,
  PendingInvitationInfoContainer,
  PendingStatus,
  SendInvitationLink,
  StyledAvatar,
  StyledSpinner,
  TeamContainer,
  TeamTitle,
  UserEmail,
  UserName,
  UserProfileContainer,
  YesBtn,
} from './style';

class Team extends Component {
  state = {
    showInviteTeamModal: false,
    loading: false,
    resend_invitation_link_loading: {},
    showDeleteUserPopup: false,
    userToDelete: {},
  };

  async componentDidMount() {
    this.props.MedTrackPageView('Team Settings Page');
    this.setState({ loading: true });
    await this.props.getTeamMembers(this.props.userToken);
    this.setState({
      loading: false,
      showDeleteUserPopup: false,
    });
  }

  handleTeamMemberInviteModal = () => {
    this.setState({ showInviteTeamModal: !this.state.showInviteTeamModal }, () => {
      if (!this.state.showInviteTeamModal) {
        this.props.getTeamMembers(this.props.userToken);
      }
    });
  };

  handleOnDeleteUser = async (memberId) => {
    const teamMemberDeleted = await this.props.deleteTeamMember(memberId, this.props.userToken);
    if (teamMemberDeleted.type === 'DELETE_TEAM_MEMBER_SUCCESS') {
      await this.props.getTeamMembers(this.props.userToken);
    }

    this.setState({ showDeleteUserPopup: false });
  };

  onCloseModal = () => {
    this.setState({ showInviteTeamModal: false }, async () => {
      await this.props.getTeamMembers(this.props.userToken);
    });
  };

  reInviteTeamMember = async (member) => {
    this.setState({
      resend_invitation_link_loading: {
        ...this.state.resend_invitation_link_loading,
        [member.email]: true,
      },
    });

    const infoToSend = {
      _id: member._id,
      callbackUrl: `${window.location.origin}/register`,
    };

    await this.props.reInviteTeamMember(infoToSend, this.props.userToken);
    await this.props.getTeamMembers(this.props.userToken);
  };

  getTooltipMessage = (canBeDeleted, isAnAdmin) => {
    if (canBeDeleted) {
      return 'components.Settings.Team.remove';
    } else if (!isAnAdmin) {
      return 'components.Settings.Team.notAnAdmin';
    } else {
      return 'components.Settings.Team.notYetExpired';
    }
  };

  render() {
    const { t } = this.props;
    return (
      <>
        <TeamContainer>
          <TeamTitle>{t('components.Settings.Team.title')}</TeamTitle>
          <InviteTeamMemberButton onClick={this.handleTeamMemberInviteModal} variant="contained">
            {t('components.Settings.Team.inviteBn')}
          </InviteTeamMemberButton>
          <Divider />
          <br />
          {this.state.showDeleteUserPopup && (
            <MedModalDlg
              aria-describedby="alert-dialog-description"
              aria-labelledby="alert-dialog-title"
              open={this.state.showDeleteUserPopup}
              onBackdropClick={() => {
                this.setState({ showDeleteUserPopup: false });
              }}
              scroll="body"
            >
              <DeleteUserHeader>
                <DeleteUserTitle>{t('components.Settings.Team.deleteUser')}</DeleteUserTitle>
                <CloseIconStyled onClick={() => this.setState({ showDeleteUserPopup: false })} />
              </DeleteUserHeader>
              <DeleteUserContent>
                <Trans
                  i18nKey="components.Settings.Team.areYouSure"
                  values={{
                    name: this.state.userToDelete.name,
                    lastName: this.state.userToDelete.lastName,
                    boldStart: '<strong>',
                    boldFinish: '</strong>',
                    break: '<br />',
                  }}
                />
              </DeleteUserContent>

              <DlgActionsStyled style={{ justifyContent: 'space-between', padding: 0 }}>
                <YesBtn onClick={() => this.handleOnDeleteUser(this.state.userToDelete.id)}>
                  {t('components.Settings.Team.yesDelete')}
                </YesBtn>
                <NoBtn
                  onClick={() => this.setState({ showDeleteUserPopup: false })}
                  variant="contained"
                >
                  {t('components.Settings.Team.noKeep')}
                </NoBtn>
              </DlgActionsStyled>
            </MedModalDlg>
          )}
          {this.state.loading ? (
            <StyledSpinner aria-label="loading spinner" />
          ) : (
            this.props.teamMembers.map((member) => {
              // pending member can be deleted by an admin, only if he doesnt have any token expiration date, or when his token has expired
              const canPendingMemberBeDeleted =
                this.props.userData.role === 'admin' &&
                (!member.expiresAt || new Date(member.expiresAt) < new Date());
              if (!member.deleted) {
                return (
                  <UserProfileContainer key={member._id} status={member.status}>
                    <StyledAvatar>
                      <span>
                        {member.name && member.name.firstName.slice(0, 1)}
                        {member.name && member.name.lastName.slice(0, 1)}
                      </span>
                    </StyledAvatar>
                    <UserName>
                      {member.name && member.name.firstName} {member.name && member.name.lastName}
                    </UserName>
                    {/* <RoleBadge>{member.role || 'Admin'}</RoleBadge> */}
                    <span />
                    <br />
                    <UserEmail>{member.email}</UserEmail>
                    <PendingInvitationActionsContainer>
                      {member.status === 'pending' && (
                        <SendInvitationLink
                          onClick={() => this.reInviteTeamMember(member)}
                          variant="contained"
                        >
                          {!this.state.resend_invitation_link_loading[member.email] ? (
                            t('components.Settings.Team.resendInvitation')
                          ) : (
                            <>
                              {t('components.Settings.Team.invitationSent')} <Done />
                            </>
                          )}
                        </SendInvitationLink>
                      )}
                      {member._id !== this.props.userData._id && (
                        <Tooltip
                          arrow
                          title={
                            <span>
                              {t(
                                this.getTooltipMessage(
                                  canPendingMemberBeDeleted,
                                  this.props.userData.role === 'admin',
                                ),
                              )}
                            </span>
                          }
                        >
                          <Delete
                            style={{
                              cursor: canPendingMemberBeDeleted ? 'pointer' : 'not-allowed',
                              fontSize: '28px',
                              color: canPendingMemberBeDeleted && palette.primaryDarkRed,
                            }}
                            color={canPendingMemberBeDeleted ? 'auto' : 'disabled'}
                            onClick={(e) => {
                              if (canPendingMemberBeDeleted) {
                                this.setState({
                                  userToDelete: {
                                    name: member.name.firstName,
                                    lastName: member.name.lastName,
                                    id: member._id,
                                  },
                                  showDeleteUserPopup: true,
                                });
                              }
                            }}
                          />
                        </Tooltip>
                      )}
                    </PendingInvitationActionsContainer>
                    {member.status === 'pending' && (
                      <PendingInvitationInfoContainer>
                        <PendingStatus>
                          {t('components.Settings.Team.invitationPending')}
                        </PendingStatus>
                        <br />
                        {member.expiresAt && (
                          <ExpirationDate>
                            {t('components.Settings.Team.expiringBy')}{' '}
                            {moment(member.expiresAt).format('DD.MM.YYYY H:m')}
                          </ExpirationDate>
                        )}
                      </PendingInvitationInfoContainer>
                    )}
                  </UserProfileContainer>
                );
              }
            })
          )}
        </TeamContainer>
        <TeamInviteModal
          openModal={this.state.showInviteTeamModal}
          closeModal={this.onCloseModal}
        />
      </>
    );
  }
}

function mapStateToProps(state) {
  const { teamMembers } = state.team;
  const { isPlus } = state.proOffer;
  const { userData } = state.userData;

  return { teamMembers, isPlus, userData, userToken: getUserToken(state) };
}

function mapDispatchToProps(dispatch) {
  return {
    getTeamMembers: async (token) => {
      dispatch({ type: GET_TEAM_PENDING });
      const teamMembers = await GET_TEAM(token);
      return dispatch(teamMembers);
    },
    reInviteTeamMember: async (data, token) => {
      dispatch({ type: REINVITE_COLLEAGUE_PENDING });
      const response = await REINVITE_TEAM_MEMBER(data, token);
      return dispatch(response);
    },
    deleteTeamMember: async (memberId, token) => {
      dispatch({ type: DELETE_TEAM_MEMBER_PENDING });
      const teamMemberDeleted = await DELETE_TEAM_MEMBER(memberId, token);
      return dispatch(teamMemberDeleted);
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(withMedTracker(Team)));
