import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import MedButton from 'lib/elements/MedButton';
import React from 'react';
import styled from 'styled-components';
import * as palette from 'styles/palette';

export const MedModalDlg = styled((props) => <Dialog {...props} />)`
  &&& > div {
    border-radius: 0.7rem;
  }
`;

export const Content = styled((props) => <DialogContent {...props} />)`
  &&& {
    padding: 2.2rem 2.7rem;
    padding-bottom: 2.6rem;
  }
`;

export const ContentText = styled.div`
  line-height: 1.8rem;
  font-size: 12px;
  color: ${palette.dlgFontColor};
  margin: auto;
`;

export const DlgActions = styled((props) => <DialogActions {...props} />)`
  &&& {
    margin: 0;
    padding: 2.4rem 3.2rem;
    padding-top: 0;
  }
`;

export const YesBtn = styled((props) => <MedButton {...props} />)`
  &&& {
    background-color: ${palette.primaryDarkGreen};
    &:hover {
      background-color: ${palette.primaryLightGreen};
    }
  }
`;

export const NoBtn = styled((props) => <MedButton {...props} />)`
  &&& {
    background-color: ${palette.primaryDarkRed};
    &:hover {
      background-color: ${palette.primaryLightRed};
    }
  }
`;

export const ReportHeader = styled.p`
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 1.8rem;
  color: ${palette.primaryDarkGreen};
  margin: 0;
  margin-bottom: 1.3rem;
`;

export const AlertHeader = ReportHeader.extend`
  color: ${palette.primaryDarkRed};
`;
