import PropTypes from 'prop-types';
import React from 'react';
import {
  ButtonGroupContainer,
  Content,
  OKButton,
  TextArea,
  TextLine,
  TextLineTitle,
  TextLineValue,
  Title,
  TutorialDlg,
} from './style';

import I18n from 'i18n';

const ReportModal = ({ isReport, onClose }) => {
  return (
    <TutorialDlg
      aria-describedby="alert-dialog-description"
      aria-labelledby="alert-dialog-title"
      maxWidth="lg"
      onClose={onClose}
      open={isReport}
    >
      <Content>
        <Title>{I18n.t('components.Reports.ReportModal.title')}</Title>
        <TextArea>
          <TextLine>
            <TextLineTitle>{I18n.t('components.Reports.ReportModal.textLineTitle1')}</TextLineTitle>
            <TextLineValue>{I18n.t('components.Reports.ReportModal.textLineValue1')}</TextLineValue>
          </TextLine>
          <TextLine>
            <TextLineTitle>{I18n.t('components.Reports.ReportModal.textLineTitle2')}</TextLineTitle>
            <TextLineValue>{I18n.t('components.Reports.ReportModal.textLineValue2')}</TextLineValue>
          </TextLine>
        </TextArea>
        <ButtonGroupContainer>
          <OKButton onClick={onClose} variant="contained">
            {I18n.t('components.Reports.ReportModal.okBtn')}
          </OKButton>
        </ButtonGroupContainer>
      </Content>
    </TutorialDlg>
  );
};

ReportModal.propTypes = {
  isReport: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
};

export default ReportModal;
