import Tabs from '@material-ui/core/Tabs';
import I18n from 'i18n';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as palette from 'styles/palette';
import Company from './Company';
import Notifications from './Notifications';
import Team from './Team';

import { MenuItem, SettingsContainer, SettingsTitle } from './style';

function TabPanel(props) {
  switch (props.value) {
    case 'company':
      return <Company />;

    case 'team':
      return <Team />;

    case 'notifications':
      return <Notifications />;

    default:
      return <Company />;
  }
}

class Settings extends Component {
  state = {
    activeMenu: 'company',
  };

  handleSettingsMenuChange = (event, newValue) => {
    this.setState({ activeMenu: newValue });
  };

  render() {
    return (
      <>
        <SettingsContainer>
          <SettingsTitle>{I18n.t('components.Settings.title')}</SettingsTitle>
          <br /> <br />
          <Tabs
            onChange={this.handleSettingsMenuChange}
            value={this.state.activeMenu}
            TabIndicatorProps={{
              style: { backgroundColor: palette.primaryDarkGreen },
            }}
          >
            <MenuItem label={I18n.t('components.Settings.company')} value="company" />
            <MenuItem label={I18n.t('components.Settings.team')} value="team" />
            <MenuItem label={I18n.t('components.Settings.notifications')} value="notifications" />
          </Tabs>
          <br /> <br />
          <TabPanel value={this.state.activeMenu} />
        </SettingsContainer>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
