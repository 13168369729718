import React from 'react';
import { Trans, withTranslation } from 'react-i18next';
import { ConfirmBtn, ConfirmForm, SuccessContainer } from './style';

import PropTypes from 'prop-types';
import { ConfirmationText, ModalTitle } from '../RegisterModal/style';

const RegisterConfirm = ({ onClose, registeredFromBasic, t }) => {
  if (!onClose) {
    onClose = () => {
      window.location.href = '/login';
    };
  }
  return (
    <SuccessContainer>
      <ConfirmForm>
        <ModalTitle>{t('components.RegisterConfirm.title')}</ModalTitle>
        <br /> <br />
        {(!registeredFromBasic && (
          <>
            <ConfirmationText>
              <Trans
                i18nKey="components.RegisterConfirm.proConfirm"
                values={{
                  break: '<br />',
                }}
              />
            </ConfirmationText>
            <ConfirmBtn variant="contained" onClick={onClose}>
              {t('components.RegisterConfirm.proConfirmButton')}
            </ConfirmBtn>
          </>
        )) || (
          <>
            <ConfirmationText>
              <Trans
                i18nKey="components.RegisterConfirm.fromBasicConfirm"
                values={{
                  break: '<br />',
                }}
              />
            </ConfirmationText>
            <ConfirmBtn variant="contained" onClick={onClose}>
              {t('components.RegisterConfirm.fromBasicConfirmButton')}
            </ConfirmBtn>
          </>
        )}
      </ConfirmForm>
    </SuccessContainer>
  );
};

RegisterConfirm.propTypes = {
  onClose: PropTypes.func,
  registeredFromBasic: PropTypes.bool.isRequired,
};

export default withTranslation()(RegisterConfirm);
