import React, { useState } from 'react';

import { Container, Label, StyledInlineEdit } from './style';

const InlineEditField = (props) => {
  const [editing, setEditing] = useState(false);

  const handleOnCancel = (value) => {
    setEditing(false);
  };
  const enableEditing = () => {
    setEditing(true);
  };
  const handleOnSave = (value) => {
    if (value !== props.value) {
      props.onSave(value);
    }
    setEditing(false);
  };

  return (
    <Container>
      {!editing && <Label onClick={enableEditing}>{props.value || props.placeholderText}</Label>}
      {editing && (
        <StyledInlineEdit
          type="text"
          hideIcons
          value={props.value}
          inputProps={{
            placeholder: props.placeholderText,
          }}
          onSave={handleOnSave}
          onCancel={handleOnCancel}
          editOnViewClick
          submitOnEnter
          cancelOnEscape
          submitOnUnfocus
          startEditingOnFocus
          editing={editing}
        />
      )}
    </Container>
  );
};

export default InlineEditField;
