import React from 'react';

import { useMatomo } from '@datapunt/matomo-tracker-react';

import { helperEvent, helperPageView } from './Matomo/functions';

export default function withMedTracker(Component) {
  return (props) => {
    const { trackEvent, trackPageView } = useMatomo();

    const TrackingPageView = (pageTitle, pageUrl) => {
      helperPageView(trackPageView, pageTitle, pageUrl);
    };

    const TrackingEvent = (category, action, name, value) => {
      helperEvent(trackEvent, category, action, name, value);
    };

    return (
      <Component {...props} MedTrackEvent={TrackingEvent} MedTrackPageView={TrackingPageView} />
    );
  };
}
