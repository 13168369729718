import I18n from 'i18n';
import PropTypes from 'prop-types';
import React from 'react';
import {
  ContentColoredText,
  ContentText,
  ContentTitle,
  DialogAction,
  IgnoreBtn,
  WarningContainer,
} from './style';

const MedModal = ({ onCancel, open }) => {
  return (
    <WarningContainer
      aria-describedby="alert-dialog-description"
      aria-labelledby="alert-dialog-title"
      open={open}
    >
      <ContentTitle>{I18n.t('components.OldBrowsersPopup.title')}</ContentTitle>
      <ContentText>{I18n.t('components.OldBrowsersPopup.text')}</ContentText>
      <ContentColoredText>{I18n.t('components.OldBrowsersPopup.coloredText')}</ContentColoredText>
      <DialogAction>
        <IgnoreBtn onClick={onCancel} variant="contained">
          {I18n.t('components.OldBrowsersPopup.ignore')}
        </IgnoreBtn>
      </DialogAction>
    </WarningContainer>
  );
};

MedModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default MedModal;
