import { GET_SIDEEFFECTS_FAIL, GET_SIDEEFFECTS_PENDING, GET_SIDEEFFECTS_SUCCESS } from 'actions';

const initialState = {
  sideEffects: [],
  sideEffectsLoaded: false,
  errors: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_SIDEEFFECTS_PENDING:
      return {
        ...state,
        errors: {},
        sideEffectsLoaded: false,
      };
    case GET_SIDEEFFECTS_SUCCESS: {
      const { sideEffects } = action.payload;
      return {
        ...state,
        sideEffects,
        sideEffectsLoaded: true,
      };
    }
    case GET_SIDEEFFECTS_FAIL: {
      const { errors } = action.payload;
      return {
        ...state,
        errors: errors,
      };
    }
    default: {
      return state;
    }
  }
};
