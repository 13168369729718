import I18n from 'i18n';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Trans, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { requestProByMail } from '../../api/RegisterSvc';
import RegisterConfirm from '../RegisterConfirm';

import { getUserToken } from 'selectors/user';
import { ModalTitle, ProDialog, ProInfo, ProSignup, StyledBtn } from './style';

class ProTesting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showConfirmationPage: false,
    };
  }

  handleRequestProTrial = () => {
    this.setState({ showConfirmationPage: true });
    requestProByMail(this.props.userToken);
  };

  handleOnClose = () => {
    this.setState({ showTestProPage: false, showConfirmationPage: false });
    this.props.onCancel();
  };

  render() {
    return (
      <ProDialog
        confirmPage={this.state.showConfirmationPage}
        aria-describedby="alert-dialog-description"
        aria-labelledby="alert-dialog-title"
        open={this.props.open}
        onBackdropClick={this.handleOnClose}
      >
        {(this.state.showConfirmationPage && <RegisterConfirm registeredFromBasic />) ||
          (this.props.open && (
            <ProSignup>
              <ModalTitle>
                <Trans
                  i18nKey="components.RegisterModal.title"
                  values={{
                    break: '<br />',
                  }}
                >
                  <strong />
                </Trans>
              </ModalTitle>
              <br /> <br />
              <ProInfo>
                <Trans
                  i18nKey="components.RegisterModal.proInfo"
                  values={{
                    break: '<br />',
                  }}
                />
              </ProInfo>
              <br />
              <br />
              <StyledBtn variant="contained" onClick={this.handleRequestProTrial}>
                {I18n.t('components.RegisterModal.testPro')}
              </StyledBtn>
            </ProSignup>
          ))}
      </ProDialog>
    );
  }
}

ProTesting.propTypes = {
  onCancel: PropTypes.func,
  registeredFromBasic: PropTypes.bool.isRequired,
};

function mapStateToProps(state, props) {
  return { userToken: getUserToken(state) };
}

export default connect(mapStateToProps)(withTranslation()(ProTesting));
