import axios from 'axios';

// TODO :: Improve on this by having better handling of api middleware
const eventstoreInstanceAxios = (() => {
  const eventstoreInstance = axios.create({
    baseURL: process.env.REACT_APP_API_EVENTSTORE_PRIVATE + '/pharma',
  });

  eventstoreInstance.interceptors.request.use((config) => {
    if (typeof config.data === 'undefined') {
      // https://github.com/axios/axios/issues/86
      config.data = {};
    }
    config.headers['X-API-Version'] = '1.0.0';
    config.headers['Content-Type'] = 'application/json';
    config.headers['X-Event-App-Created'] = 'med-app-pharma';
    config.headers['X-Event-Role'] = 'pharma';
    config.headers['Cache-Control'] = 'no-cache';
    config.headers['Pragma'] = 'no-cache';
    config.headers['Expires'] = '0';
    return config;
  });

  eventstoreInstance.interceptors.response.use((response) => {
    return response;
  });

  return eventstoreInstance;
})();

const identityInstanceAxios = (() => {
  const identityInstance = axios.create({
    baseURL: process.env.REACT_APP_API_IDENTITY,
  });

  identityInstance.interceptors.request.use((config) => {
    if (typeof config.data === 'undefined') {
      // https://github.com/axios/axios/issues/86
      config.data = {};
    }
    config.headers['X-API-Version'] = '1.0.0';
    config.headers['Content-Type'] = 'application/json';
    config.headers['X-Event-App-Created'] = 'med-app-pharma';
    config.headers['X-Event-Role'] = 'pharma';

    return config;
  });

  identityInstance.interceptors.response.use((response) => {
    return response;
  });

  return identityInstance;
})();

// need to make them IIFE in order to export the instance
export { eventstoreInstanceAxios, identityInstanceAxios };
