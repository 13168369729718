import { Dialog, TextField, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import {
  ButtonSpinner,
  CheckBoxContainer,
  CheckBoxLabel,
  Header,
  LoadingSpinner,
  NoBtn,
  PremiumAd,
  PressEnter,
  QuestionCheckBox,
  SendStyled,
  StyledInfoIcon,
  StyledParagraph,
  StyledTitle,
  TestProBtn,
  UserDefined,
  YesBtn,
} from './style';

import Input from '@material-ui/core/Input';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { GET_FOLLOWUPS, GET_FOLLOWUPS_PENDING } from 'actions';
import Content from 'lib/elements/Content';
import DlgActions from 'lib/elements/DlgActions';
import _ from 'lodash';
import { Trans, withTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import { getUserToken } from 'selectors/user';
import * as palette from 'styles/palette';

const StyledDialog = withStyles({
  paper: {
    minWidth: '500px',
  },
})(Dialog);

const StyledAutcomplete = withStyles({
  root: {
    width: '100%',
    marginTop: '10px',
  },
  groupLabel: {
    fontSize: '13px',
  },
  option: {
    fontSize: '13px',
  },
})(Autocomplete);

const StyledTextField = withStyles({
  root: {
    '& .MuiInput-root:before': {
      borderBottomColor: palette.primaryLightGreen,
    },
    '& .MuiInput-underline:hover:before': {
      borderBottomColor: palette.primaryLightGreen,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: palette.primaryLightGreen,
    },
    '& .MuiInput-input': {
      fontSize: '13px',
    },
  },
})(TextField);

class QuestionsModal extends Component {
  static propTypes = {
    report: PropTypes.object.isRequired,
    orderedQuestions: PropTypes.array.isRequired,
    isOpen: PropTypes.bool.isRequired,
    sendQuestion: PropTypes.func.isRequired,
    interactWithPatient: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    onShowTestProModal: PropTypes.func.isRequired,
    onShowRegisterModal: PropTypes.func.isRequired,
  };

  state = {
    isLoading: true,
    isSubmitting: false,
    checkedItems: [],
    customQuestion: {},
    customQuestionInputValue: '',
    followUpTemplateQuestions: [], // Set at the pharma company level
    followupDropdown: [],
    autocompleteInputValue: '',
    openFollowupQuestionsDropdown: false,
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.report !== prevProps.report) {
      this.setCheckedItems();
    }
  }

  async componentDidMount() {
    const followUpQuestions = await this.props.getFollowups(this.props.userToken);
    this.setState({ followUpTemplateQuestions: followUpQuestions.payload }, () => {
      this.setCheckedItems();
    });
  }

  handleCheckboxes = (e) => {
    const id = e.target.name;
    const isChecked = e.target.checked;

    this.setState((prevState) => {
      const checkedItemsList = prevState.checkedItems;
      checkedItemsList[id].checked = isChecked;
      return {
        checkedItemsList,
      };
    });
  };

  handleSubmit = async () => {
    this.setState({ isSubmitting: true });
    const { checkedItems } = this.state;

    const newQuestions = checkedItems
      .filter((question) => question.checked)
      .filter((question) => !question._id || question.answer);

    const resultNewQuestions = [];

    newQuestions.forEach((question) => {
      if (question.type === 'custom') {
        resultNewQuestions.push({
          type: 'custom',
          format: 'free-text',
          text: question.text,
        });
      } else if (question.type === 'template') {
        resultNewQuestions.push({
          title: question.text,
          templateId: question.templateId,
          type: 'template',
          format: question.format,
          allowedOptions: question.followupOptions,
          text: question.textToSubmit,
          ...(question.followupFile &&
            question.followupFile.id &&
            question.followupFile.id !== '' && {
              fileId: question.followupFile.id,
            }),
        });
      } else {
        resultNewQuestions.push({
          type: question.type,
        });
      }
    });

    const questionsToBeReminded = checkedItems
      .filter((question) => question.checked && question._id && !question.answer)
      .reduce((acc, question) => {
        acc.push({ id: question._id });
        return acc;
      }, []);

    const dataForInteractingWithPatient = [].concat(questionsToBeReminded, resultNewQuestions);
    if (dataForInteractingWithPatient.length) {
      await this.props.interactWithPatient(dataForInteractingWithPatient);
    }

    this.setState({ isSubmitting: false });
    this.props.onClose();
  };

  handleOnCancel = () => {
    this.props.onClose();
    this.setState({
      isSubmitting: false,
      openFollowupQuestionsDropdown: false,
    });
  };

  setCheckedItems() {
    const { t } = this.props;
    const { orderedQuestions } = this.props;

    // incoming questions (only unanswered) from the report metadata (sorted byTime)
    const existingQuestions = orderedQuestions.filter((question) => !question.answer);

    // define default questions manually
    const defaultQuestionsObject = {
      'question-drug-indication': t(
        'components.ReportDetail.QuestionAnswerContainer.QuestionsModal.titles.questionDrugIndication',
      ),
      'question-effect-status': t(
        'components.ReportDetail.QuestionAnswerContainer.QuestionsModal.titles.questionEffectStatus',
      ),
      'question-drug-batch-number': t(
        'components.ReportDetail.QuestionAnswerContainer.QuestionsModal.titles.questionDrugBatchNumber',
      ),
      'question-drug-action': t(
        'components.ReportDetail.QuestionAnswerContainer.QuestionsModal.titles.questionDrugAction',
      ),
      'question-drug-dosage': t(
        'components.ReportDetail.QuestionAnswerContainer.QuestionsModal.titles.questionDrugDosage',
      ),
      'question-contact-details': t(
        'components.ReportDetail.QuestionAnswerContainer.QuestionsModal.titles.questionContactDetails',
      ),
    };

    // this will hold all the questions that we have to show in the modal
    let allQuestions = [];

    // filter all incoming default questions
    const onlyDefault = existingQuestions.filter(
      (question) => question.type !== 'custom' && question.type !== 'template',
    );
    // iterate through the super set of default questions and check if any exists
    // in the incoming. If yes, mark it checked
    for (const [key, value, format] of Object.entries(defaultQuestionsObject)) {
      const found = onlyDefault.find((q) => q.type === key);

      allQuestions.push({
        ...found,
        type: key,
        text: value,
        format: format,
        checked: !!found,
        disabled: !!found,
      });
    }

    // filter all incoming template questions
    const onlyTemplate = existingQuestions.filter((question) => question.type === 'template');

    const allNotAskedQuestions = [];

    // iterate through the super set of template questions and check if any exists
    // in the incoming. If yes, mark it checked
    _.get(this, 'state.followUpTemplateQuestions', []).forEach((customFollowUp) => {
      const found = onlyTemplate.find((q) => q.templateId === customFollowUp._id);

      // find questions in the report and use that (old) title (WORKAROUND)
      const questionReminded = orderedQuestions.filter(
        (question) => question.templateId === customFollowUp._id,
      );

      const builtQuestion = {
        ...found,
        type: 'template',
        text: found ? questionReminded[0].title : customFollowUp.followupTitle,
        textToSubmit: customFollowUp.followupText,
        format: customFollowUp.followupType,
        checked: !!found,
        disabled: !!found,
        templateId: found ? found.templateId : customFollowUp._id,
        followupOptions: customFollowUp.followupOptions,
        ...(customFollowUp.followupFile &&
          customFollowUp.followupFile.id &&
          customFollowUp.followupFile.id !== '' && {
            followupFile: customFollowUp.followupFile,
          }),
      };

      allQuestions.push(builtQuestion);
      !found && allNotAskedQuestions.push(builtQuestion);
    });

    // filter all incoming custom questions
    const onlyCustom = existingQuestions.filter((question) => question.type === 'custom');

    // mark all incoming checked
    onlyCustom.forEach((custom) => {
      allQuestions.push({
        ...custom,
        checked: true,
        disabled: true,
      });
    });

    // sort questions by category for the dropdown: Label, Titel and Fragetext
    const followupForDropdown = this.createFollowupsListForDropdown(allNotAskedQuestions);

    followupForDropdown.sort((a, b) => {
      if (a.category === 'Label') {
        return -1;
      }
      if (a.category === 'Titel' && b.category !== 'Label') {
        return -1;
      } else {
        return 1;
      }
    });

    allQuestions = allQuestions.filter(
      (question) =>
        question.checked || (question.type !== 'template' && question.type !== 'custom'),
    );
    this.setState({
      checkedItems: allQuestions,
      followupDropdown: followupForDropdown,
      isLoading: false,
    });
  }

  createFollowupsListForDropdown(allNotAskedQuestions) {
    const followupsForDropdown = [];
    allNotAskedQuestions.reduce((acc, question) => {
      if (question.type === 'template') {
        acc.push(
          {
            category: 'Titel',
            textToDisplay: question.text,
            textToSubmit: question.textToSubmit,
            type: 'template',
            format: question.format,
          },
          {
            category: 'Fragetext',
            textToDisplay: question.textToSubmit,
            textToSubmit: question.textToSubmit,
            type: 'template',
            format: question.format,
          },
        );
        return acc;
      } else if (question.type === 'custom') {
        acc.push({
          category: 'Fragetext',
          textToDisplay: question.text,
          type: question.type,
          format: question.format,
        });
        return acc;
      }
      return acc;
    }, followupsForDropdown);

    const labelsForDropdown = this.followupLabelsForDropdown(this.state.followUpTemplateQuestions);
    return [...followupsForDropdown, ...labelsForDropdown];
  }

  followupLabelsForDropdown(followups) {
    let labelsDropdown = [];
    const probablyDuplicatedLabels = followups.map((followup) => followup.followupLabels);

    const allLabels = new Set(_.flatten(probablyDuplicatedLabels));
    labelsDropdown = [...allLabels];

    labelsDropdown = _.compact(labelsDropdown).map((label) => ({
      category: 'Label',
      textToDisplay: label,
    }));

    return labelsDropdown;
  }

  getFollowupsByLabel(followups, label) {
    return followups.reduce((accu, followup) => {
      if (followup.followupLabels.length && followup.followupLabels.includes(label)) {
        accu.push(followup);
      }
      return accu;
    }, []);
  }

  createCustomQuestionObject = (event, value) => {
    this.setState(
      {
        autocompleteInputValue: '',
        customQuestionInputValue: '',
        customQuestion: {
          category: 'Freitext',
          textToDisplay: value,
          textToSubmit: value,
          type: 'custom',
          format: 'free-text',
        },
      },
      async () => {
        if (document.activeElement !== null) {
          document.activeElement.blur();
        }

        await this.handleEnterPresOnCustomQuestion(event);
      },
    );
  };

  handleEnterKeyPress = (event, value) => {
    if (event.key === 'Enter') {
      this.createCustomQuestionObject(event, event.target.value);
    }
  };

  handleHinzufugenClick = (event, value) => {
    this.createCustomQuestionObject(event, this.state.customQuestionInputValue);
  };

  handleCustomQuestions = (event) => {
    if (event.target.value.length < 250) {
      this.setState({ customQuestionInputValue: event.target.value });
    }
  };

  handleNoOptionFoundClick = (event, value) => {
    if (event.key === 'Enter' || event.type === 'click') {
      this.setState(
        {
          autocompleteInputValue: '',
          openFollowupQuestionsDropdown: false,
          customQuestion: {
            category: 'Freitext',
            textToDisplay: this.state.autocompleteInputValue,
            textToSubmit: this.state.autocompleteInputValue,
            type: 'custom',
            format: 'free-text',
          },
        },
        async () => {
          if (document.activeElement !== null) {
            document.activeElement.blur();
          }

          await this.handleEnterPresOnCustomQuestion(event);
        },
      );
    }
  };

  handleAutocompleteInputChange = (event, value) => {
    this.setState({ autocompleteInputValue: value });
  };

  handleSelectedCustomQuestions = (event, value) => {
    this.setState(
      {
        autocompleteInputValue: '',
        customQuestion: value,
        openFollowupQuestionsDropdown: false,
      },
      async () => {
        await this.handleEnterPresOnCustomQuestion(event);
      },
    );
  };

  getFollowupByCategory = (category, text) => {
    const allFollowups = this.state.followUpTemplateQuestions;

    if (category === 'Freitext') {
      // this is free text question called custom question (not a template and not a standard one)
      const customFollowupQuestion = this.state.customQuestion;
      return [
        {
          followupTitle: customFollowupQuestion.textToDisplay,
          followupText: customFollowupQuestion.textToSubmit,
          type: customFollowupQuestion.type,
          followupType: customFollowupQuestion.format,
        },
      ];
    } else {
      return allFollowups.reduce((accu, followup) => {
        if (category === 'Label') {
          if (followup.followupLabels.length && followup.followupLabels.includes(text)) {
            accu.push(followup);
          }
        } else if (category === 'Titel') {
          if (followup.followupTitle === text) {
            accu.push(followup);
          }
        } else if (category === 'Fragetext') {
          if (followup.followupText === text) {
            accu.push(followup);
          }
        }
        return accu;
      }, []);
    }
  };

  handleEnterPresOnCustomQuestion = async (event) => {
    const customQuestionToSend = [];
    if (this.state.customQuestion.textToDisplay.trim()) {
      event.preventDefault && event.preventDefault();
      // scroll at the end of div in case there are a lot of question
      const node = ReactDOM.findDOMNode(this);
      node.querySelector('.MuiDialog-container').scrollTop = 1000;

      // make deep copy of checked items so we can manipulate it without affecting the state
      const checkedQuestions = JSON.parse(JSON.stringify(this.state.checkedItems));

      // find all the questions with this category
      const followupsByCategory = this.getFollowupByCategory(
        this.state.customQuestion.category,
        this.state.customQuestion.textToDisplay,
      );

      followupsByCategory.forEach((followup) => {
        // check and add only the questions that dont exist on the list
        const checkedQuestionFound = checkedQuestions.find(
          (question) => question.text === followup.followupText,
        );

        if (!checkedQuestionFound) {
          customQuestionToSend.push({
            text: followup.followupText,
            type: followup.type || 'template',
            textToSubmit: followup.followupText,
            format: followup.followupType,
            templateId: followup._id,
            followupOptions: followup.followupOptions,
            ...(followup.followupFile &&
              followup.followupFile.id &&
              followup.followupFile.id !== '' && {
                followupFile: followup.followupFile,
              }),
            checked: true,
          });
        } else if (checkedQuestionFound && checkedQuestionFound.checked === false) {
          // if the question is found but is not checked, then check it
          checkedQuestionFound.checked = true;
        }
      });

      this.setState({
        checkedItems: [...checkedQuestions, ...customQuestionToSend],
        customQuestion: '',
      });
    }
  };

  render() {
    const { onShowTestProModal, onShowRegisterModal, isOpen, t, isPro, isPlus } = this.props;

    const {
      isLoading,
      isSubmitting,
      checkedItems,
      autocompleteInputValue,
      customQuestionInputValue,
      openFollowupQuestionsDropdown,
    } = this.state;

    const isProOrPlus = isPro || isPlus;
    return (
      <StyledDialog
        aria-describedby="alert-dialog-description"
        aria-labelledby="alert-dialog-title"
        onClose={this.handleOnCancel}
        open={isOpen}
        scroll="body"
      >
        <Header>
          <h1>{t('components.ReportDetail.QuestionAnswerContainer.QuestionsModal.title')}</h1>
          <span>
            {t('components.ReportDetail.QuestionAnswerContainer.QuestionsModal.subtitle')}
          </span>
        </Header>
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <Content
            style={{
              paddingBottom: '10px',
            }}
          >
            {_.map(checkedItems, (question, index) => {
              return (
                <CheckBoxContainer key={index}>
                  <CheckBoxLabel
                    onChange={(e) => this.handleCheckboxes(e)}
                    name={index}
                    control={<QuestionCheckBox />}
                    label={question.text}
                    disabled={question.disabled}
                    checked={question.checked}
                  />
                  <ReactTooltip id="disabled_checkbox" type="dark" effect="solid">
                    <span>
                      {t('components.ReportDetail.QuestionAnswerContainer.QuestionsModal.reminder')}
                    </span>
                  </ReactTooltip>
                </CheckBoxContainer>
              );
            })}
          </Content>
        )}
        <Content style={{ padding: '0 2.4rem 2.3rem' }}>
          <UserDefined isProOrPlus={isProOrPlus}>
            {(isProOrPlus && (
              <StyledTitle isProOrPlus={isProOrPlus}>
                {t('components.ReportDetail.QuestionAnswerContainer.QuestionsModal.userDefinedPro')}
              </StyledTitle>
            )) || (
              <>
                <StyledTitle isProOrPlus={isProOrPlus}>
                  {t(
                    'components.ReportDetail.QuestionAnswerContainer.QuestionsModal.userDefinedBasic',
                  )}
                </StyledTitle>
                <StyledInfoIcon data-tip="" data-for="followup_questions" />
                <ReactTooltip type="dark" effect="solid" place="right" id="followup_questions">
                  <StyledParagraph>
                    <Trans
                      i18nKey="components.ReportDetail.QuestionAnswerContainer.QuestionsModal.styledParagraph"
                      values={{
                        break: '<br />',
                      }}
                    >
                      <strong />
                    </Trans>
                  </StyledParagraph>
                </ReactTooltip>
              </>
            )}
            <CheckBoxContainer>
              {isPro && (
                <StyledAutcomplete
                  id="grouped-followups"
                  options={this.state.followupDropdown}
                  groupBy={(option) => option.category}
                  getOptionLabel={(option) => option.textToDisplay}
                  value=""
                  inputValue={autocompleteInputValue}
                  onInputChange={this.handleAutocompleteInputChange}
                  onChange={this.handleSelectedCustomQuestions}
                  onKeyPress={this.handleNoOptionFoundClick}
                  disableClearable
                  blurOnSelect
                  debug
                  disableOpenOnFocus={false}
                  open={openFollowupQuestionsDropdown}
                  onOpen={(event, val) => {
                    this.setState({ openFollowupQuestionsDropdown: true });
                  }}
                  onClose={() => this.setState({ openFollowupQuestionsDropdown: false })}
                  noOptionsText={
                    <div style={{ cursor: 'pointer' }} onClick={this.handleNoOptionFoundClick}>
                      {autocompleteInputValue + ' (Neue Rückfrage mit Enter hinzufügen)'}
                    </div>
                  }
                  renderInput={(params) => (
                    <StyledTextField
                      {...params}
                      placeholder={t(
                        'components.ReportDetail.QuestionAnswerContainer.QuestionsModal.userDefinedPlaceholder',
                      )}
                      variant="standard"
                      fullWidth
                    />
                  )}
                />
              )}
              {!isPro && (
                <>
                  {/* show this on basic plus and on basic (disabled) */}
                  <Input
                    type="text"
                    disabled={!isPro && !isPlus}
                    autoFocus
                    style={{ fontSize: '14px', width: '83%' }}
                    disableUnderline
                    fullWidth
                    autoComplete="off"
                    placeholder={
                      isPlus
                        ? t(
                            'components.ReportDetail.QuestionAnswerContainer.QuestionsModal.userDefinedBasicPlusPlaceholder',
                          )
                        : t(
                            'components.ReportDetail.QuestionAnswerContainer.QuestionsModal.userDefinedPlaceholder',
                          )
                    }
                    value={customQuestionInputValue}
                    onChange={this.handleCustomQuestions}
                    onKeyPress={this.handleEnterKeyPress}
                  />
                  {customQuestionInputValue.length > 0 && (
                    <PressEnter onClick={this.handleHinzufugenClick}>
                      Hinzufügen
                      <SendStyled />
                    </PressEnter>
                  )}
                </>
              )}
            </CheckBoxContainer>
          </UserDefined>
          <PremiumAd>
            {!isPro && !isPlus && (
              <YesBtn onClick={onShowRegisterModal}>
                {t('components.ReportDetail.QuestionAnswerContainer.QuestionsModal.testBasicPlus')}
              </YesBtn>
            )}
          </PremiumAd>
          <br />
          <br />
          {isPlus && (
            <div>
              <StyledTitle isProOrPlus={isProOrPlus} style={{ lineHeight: '22px', float: 'left' }}>
                <Trans
                  i18nKey="components.ReportDetail.QuestionAnswerContainer.QuestionsModal.proTeaserText"
                  values={{
                    break: '<br />',
                  }}
                />
              </StyledTitle>
              <TestProBtn onClick={onShowTestProModal}>
                {t('components.ReportDetail.QuestionAnswerContainer.QuestionsModal.testPro')}
              </TestProBtn>
            </div>
          )}
        </Content>
        <DlgActions>
          <NoBtn onClick={this.handleOnCancel}>
            {t('components.ReportDetail.QuestionAnswerContainer.QuestionsModal.cancel')}
          </NoBtn>
          <YesBtn onClick={this.handleSubmit} disabled={isSubmitting}>
            {isSubmitting && <ButtonSpinner size={16} />}
            {t('components.ReportDetail.QuestionAnswerContainer.QuestionsModal.send')}
          </YesBtn>
        </DlgActions>
      </StyledDialog>
    );
  }
}

function mapStateToProps(state, props) {
  const { proOffer, isPro, isPlus } = state.proOffer;
  return { proOffer, isPro, isPlus, userToken: getUserToken(state) };
}

function mapDispatchToProps(dispatch) {
  return {
    getFollowups: async (token) => {
      dispatch({ type: GET_FOLLOWUPS_PENDING });
      const followups = await GET_FOLLOWUPS(token);
      return dispatch(followups);
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(QuestionsModal));
