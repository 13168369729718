import MedButton from 'lib/elements/MedButton';
import styled from 'styled-components';
import * as palette from 'styles/palette';

export const ContentText = styled.div`
  line-height: 1.8rem;
  font-size: 12px;
  color: ${palette.dlgFontColor};
  margin: auto;
`;

export const YesBtn = styled(MedButton)`
  &&& {
    background-color: ${palette.primaryDarkGreen};
    &:hover {
      background-color: ${palette.primaryLightGreen};
    }
  }
`;

export const NoBtn = styled(MedButton)`
  &&& {
    background-color: ${palette.primaryDarkRed};
    &:hover {
      background-color: ${palette.primaryLightRed};
    }
  }
`;

export const ContentHeader = styled.p`
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 1.8rem;
  color: ${palette.primaryDarkRed};
  margin: 0;
  margin-bottom: 1.3rem;
`;
