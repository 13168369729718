import TextField from '@material-ui/core/TextField';
import React from 'react';
import styled from 'styled-components';
import * as palette from 'styles/palette';

export const StyledTextField = styled((props) => <TextField {...props} />)`
  &&& {
    background: ${palette.white} !important;
    width: 100%;
    box-sizing: border-box;
    border: 1px solid ${palette.primaryLightGreen};
    border-radius: 4px;
    & > div {
      padding-left: 15px;

      & > input {
        font-size: 1.4rem;
        padding: 6px 0;
      }
    }
  }
`;
