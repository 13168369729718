import { ACCOUNT_CREATION_FAIL, ACCOUNT_CREATION_PENDING, ACCOUNT_CREATION_SUCCESS } from 'actions';

const initialState = {
  step: 1,
  isRegistering: false,
  registered: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACCOUNT_CREATION_PENDING: {
      return {
        ...state,
        isRegistering: true,
        registered: false,
      };
    }
    case ACCOUNT_CREATION_SUCCESS: {
      const parent = action.payload;
      return {
        ...state,
        isRegistering: false,
        registered: true,
        errors: {},
        step: 2,
        parent,
      };
    }
    case ACCOUNT_CREATION_FAIL: {
      const errors = action.payload;
      return {
        ...state,
        registered: false,
        isRegistering: false,
        errors: { errors },
      };
    }
    default: {
      return state;
    }
  }
};
