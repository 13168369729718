import { identityInstanceAxios as identity } from '../config';

const PASSWORD_RESET_URL = '/pharma/reset';

/**
 * Password reset request using AJAX call
 *
 * @returns {Promise} - Result of ajax call
 */
export async function passwordResetAPI(data) {
  return identity.post(PASSWORD_RESET_URL, {
    email: data.email,
    callbackURL: data.callbackURL,
  });
}

// TODO use this functions in later versions to allow users to change password
/**
 * Password change request using AJAX call
 *
 * @returns {Promise} - Result of ajax call
 */
// export async function passwordChangeAPI (data) {
//   return axios.put(API_URL, data)
// }
