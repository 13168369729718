import I18n from 'i18n';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import layersConfig from '../../styles/layersConfig';
import ProTestingModal from '../RegisterModal/pro';

import { TeaserSentence, TestProBtn } from './style';

class TeaserOverlay extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showTestProModal: false,
    };
    this.teaserOverlayRef = React.createRef();
  }

  componentDidMount() {
    this.calculateTeaserSize();
    window.addEventListener('resize', this.calculateTeaserSize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.calculateTeaserSize);
  }

  calculateTeaserSize = () => {
    if (this.teaserOverlayRef.current) {
      const teaserOverlay = this.teaserOverlayRef.current;
      const parentDimensions = teaserOverlay.parentNode.getBoundingClientRect();

      // calculate height and width taking into consideration any delta there might be
      let width = parentDimensions.width;
      let height = parentDimensions.height;
      if (this.props.delta) {
        if (this.props.delta.width) {
          width -= this.props.delta.width;
        }
        if (this.props.delta.height) {
          height -= this.props.delta.height;
        }
      }
      teaserOverlay.style.width = width + 'px';
      teaserOverlay.style.height = height + 'px';
    }
  };

  handleTestProClick = () => {
    this.setState({ showTestProModal: true });
  };

  render() {
    const { textToDisplay, style } = this.props;
    return (
      <div
        style={{
          position: 'absolute',
          textAlign: 'center',
          zIndex: layersConfig.levelOne,
          ...style,
        }}
        ref={this.teaserOverlayRef}
      >
        <TeaserSentence>{textToDisplay}</TeaserSentence>
        <TestProBtn onClick={this.handleTestProClick}>
          {I18n.t('components.TeaserOverlay.testPro')}
        </TestProBtn>
        <ProTestingModal
          open={this.state.showTestProModal}
          onCancel={() => this.setState({ showTestProModal: false })}
        />
      </div>
    );
  }
}

function mapStateToProps(state, props) {
  const { isPlus } = state.proOffer;
  return { isPlus };
}

export default connect(mapStateToProps, null)(TeaserOverlay);
