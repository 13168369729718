import Tooltip from '@material-ui/core/Tooltip';
import {
  ArrowDropDownCircle,
  Check,
  CheckBox,
  Clear,
  DateRange,
  Delete,
  FirstPage,
  InsertDriveFile,
  LastPage,
  RadioButtonChecked,
  SkipNext,
  SkipPrevious,
  Subject,
} from '@material-ui/icons';
import {
  DELETE_FOLLOWUP,
  DELETE_FOLLOWUP_PENDING,
  GET_FOLLOWUPS,
  GET_FOLLOWUPS_PENDING,
} from 'actions';
import _ from 'lodash';
import React, { Component, forwardRef } from 'react';
import { Trans, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { isCompanyBasicPlus, isCompanyPro } from 'selectors/company';
import { getUserToken } from 'selectors/user';
import * as palette from '../../styles/palette';
import MenuItemComponent from '../LoopDropdownMenu';
import TeaserOverlay from '../TeaserOverlay';
import AddFollowupModal from './AddFollowup';

import { withMedTracker } from 'services/tracking';

import {
  EditStyled,
  FollowupsContainer,
  FollowupsTitle,
  LoadingSpinner,
  MaterialTableStyled,
  NewFollowupBtn,
  SpinnerContainer,
} from './style';

import { ChipStyled } from './AddFollowup/style';

class Followups extends Component {
  state = {
    followupQuestions: [],
    showAddFollowupModal: false,
    openEditFollowupModal: true,
    questionToEdit: {},
    followupTemplateToDelete: '',
    allLabels: [],
    isLoading: false,
    rowsPerPage: 30,
    initialPage: 0,
  };

  async componentDidMount() {
    await this.showFollowupList();
    this.props.MedTrackPageView('Templates Listing Page');
  }

  setDefaultFollowupQuestions() {
    const defaultQuestionsArr = [];
    const standardQuestions = this.props.t('components.Followups.standardQuestions', {
      returnObjects: true,
    });
    for (const prop in standardQuestions) {
      defaultQuestionsArr.push({
        followupTitle: standardQuestions[prop].title,
        followupText: standardQuestions[prop].text,
        followupLabels: [],
        followupType: prop,
        followupOptions: [],
      });
    }

    return defaultQuestionsArr;
  }

  getAllFollowupLabels(followups) {
    const probablyDuplicatedLabels = followups.flatMap((followup) => followup.followupLabels);
    const allLabels = new Set(probablyDuplicatedLabels);
    return allLabels;
  }

  showFollowupList = async () => {
    this.setState({ isLoading: true });
    const followupCustomQuestions = await this.props.getFollowups(this.props.userToken);
    const allFollowupQuestions = _.concat(
      this.setDefaultFollowupQuestions(),
      followupCustomQuestions.payload,
    );
    this.setState({
      followupQuestions: allFollowupQuestions || [],
      allLabels: this.getAllFollowupLabels(followupCustomQuestions.payload),
    });
    if (followupCustomQuestions) {
      this.setState({ isLoading: false });
    }
  };

  handleAddFollowup = () => {
    this.setState({ showAddFollowupModal: true, questionToEdit: {} });
  };

  addFollowupModalClosed = () => {
    this.setState({ showAddFollowupModal: false, questionToEdit: {} }, async () => {
      await this.showFollowupList();
    });
  };

  handleDeleteFollowup = async (event, closeDropdown, followupTemplateToDelete) => {
    await this.props.deleteFollowup(followupTemplateToDelete, this.props.userToken);
    closeDropdown();
    await this.showFollowupList();
  };

  handleEditFollowup = async (rowData) => {
    rowData.followupType = rowData.followupType || 'free-text';
    rowData.followupOptions = rowData.followupOptions || [];
    this.setState({ questionToEdit: rowData }, () => {
      this.setState({ showAddFollowupModal: true });
    });
  };

  render() {
    const { isLoading } = this.state;
    const { isPro, isPlus, t } = this.props;
    return (
      <>
        <FollowupsContainer>
          <FollowupsTitle>{t('components.Followups.title')}</FollowupsTitle>
          {isPro && (
            <NewFollowupBtn onClick={this.handleAddFollowup}>
              {t('components.Followups.newFollowup')}
            </NewFollowupBtn>
          )}
          <AddFollowupModal
            allLabels={this.state.allLabels}
            modalOpened={this.state.showAddFollowupModal}
            closeModal={this.addFollowupModalClosed}
            questionToEdit={this.state.questionToEdit}
            allFollowups={this.state.followupQuestions}
          />
          <br /> <br /> <br />
          {isPlus && (
            <TeaserOverlay
              textToDisplay={
                <Trans
                  i18nKey="components.Followups.followupTeaserText"
                  values={{
                    break: '<br />',
                  }}
                />
              }
              style={{
                paddingTop: '135px',
                backgroundColor: palette.unknownBGColorAlpha,
                maxWidth: '1266px',
              }}
              delta={{
                height: 240,
                width: 64,
              }}
            />
          )}
          {!isLoading ? (
            <MaterialTableStyled
              columns={[
                {
                  title: t('components.Followups.tableHeaders.followupTitle'),
                  field: 'followupTitle',
                  cellStyle: {
                    color: palette.tableBodyColor,
                    fontSize: '1.3rem',
                  },
                  headerStyle: {
                    fontWeight: 600,
                  },
                },
                {
                  title: t('components.Followups.tableHeaders.followupText'),
                  field: 'followupText',
                  cellStyle: {
                    color: palette.tableBodyColor,
                    fontSize: '1.3rem',
                  },
                  headerStyle: {
                    fontWeight: 600,
                  },
                },
                {
                  title: t('components.Followups.tableHeaders.followupType'),
                  field: 'followupType',
                  cellStyle: {
                    color: palette.tableBodyColor,
                    fontSize: '1.3rem',
                  },
                  headerStyle: {
                    fontWeight: 600,
                  },
                  render: (rowData) => {
                    if (rowData.followupType === 'date') {
                      return <DateRange />;
                    } else if (
                      rowData.followupType === 'questionDrugAction' ||
                      rowData.followupType === 'questionEffectStatus'
                    ) {
                      return <ArrowDropDownCircle />;
                    } else if (rowData.followupType === 'single-choice') {
                      return <RadioButtonChecked />;
                    } else if (rowData.followupType === 'multiple-choice') {
                      return <CheckBox />;
                    } else if (rowData.followupType === 'file') {
                      return <InsertDriveFile />;
                    } else {
                      return <Subject />;
                    }
                  },
                },
                {
                  title: t('components.Followups.tableHeaders.followupLabels'),
                  field: 'followupLabels',
                  editable: 'never',
                  cellStyle: {
                    color: palette.tableBodyColor,
                    whiteSpace: 'nowrap',
                    width: '1%',
                  },
                  headerStyle: {
                    fontWeight: 600,
                  },
                  render: (rowData) => {
                    return (
                      <div
                        style={{
                          marginTop: '-10px',
                          marginBottom: '-15px',
                          width: 'fit-content',
                        }}
                      >
                        {(rowData.followupLabels || []).map((label, index) => {
                          return (
                            <>
                              <ChipStyled
                                color="primary"
                                size="small"
                                label={label}
                                style={{ height: '20px' }}
                              />
                              {index === 4 && <br />}
                            </>
                          );
                        })}
                      </div>
                    );
                  },
                },
                {
                  title: t('components.Followups.tableHeaders.actions'),
                  field: 'actions',
                  cellStyle: {
                    color: palette.tableBodyColor,
                    fontSize: '1.3rem',
                  },
                  headerStyle: {
                    fontWeight: 600,
                  },
                  render: (rowData) => {
                    if (rowData._id) {
                      return (
                        <>
                          <Tooltip title={<span>{t('components.Followups.editTooltip')}</span>}>
                            <EditStyled onClick={() => this.handleEditFollowup(rowData)} />
                          </Tooltip>
                          <MenuItemComponent
                            showTooltip
                            dataId={rowData._id}
                            text={t('components.Followups.deleteTooltip')}
                            menuId="delete-menu"
                            showIconOnDropdown
                            handleMenuItemClick={(e, closeDropdown) =>
                              this.handleDeleteFollowup(e, closeDropdown, rowData._id)
                            }
                            Icon={({ onClick }) => (
                              <Delete style={{ color: palette.primaryDarkRed }} onClick={onClick} />
                            )}
                          />
                        </>
                      );
                    }
                  },
                },
              ]}
              data={this.state.followupQuestions}
              onChangePage={(pageToShow) => {
                this.setState({ initialPage: pageToShow });
              }}
              onChangeRowsPerPage={(rowsPerPage) => {
                this.setState({ rowsPerPage: rowsPerPage });
              }}
              icons={{
                FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
                LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
                PreviousPage: forwardRef((props, ref) => <SkipPrevious {...props} ref={ref} />),
                NextPage: forwardRef((props, ref) => <SkipNext {...props} ref={ref} />),
                Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
                Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
              }}
              localization={{
                pagination: {
                  labelRowsSelect: t('components.Followups.pagination.labelRowsSelect'),
                  labelDisplayedRows:
                    ' {from}-{to} ' +
                    t('components.Followups.pagination.labelDisplayedRows') +
                    ' {count}',
                  firstTooltip: <span>{t('components.Followups.pagination.tooltips.first')}</span>,
                  previousTooltip: (
                    <span>{t('components.Followups.pagination.tooltips.previous')}</span>
                  ),
                  nextTooltip: <span>{t('components.Followups.pagination.tooltips.next')}</span>,
                  lastTooltip: <span>{t('components.Followups.pagination.tooltips.last')}</span>,
                },
              }}
              options={{
                actionsColumnIndex: -1,
                search: false,
                showTitle: false,
                toolbar: false,
                sorting: false,
                grouping: false,
                draggable: false,
                pageSize: this.state.rowsPerPage,
                initialPage: this.state.initialPage,
                pageSizeOptions: [30, 60, 90],
                headerStyle: {
                  color: palette.primaryLightGreen,
                  fontSize: '1.3rem',
                },
              }}
            />
          ) : (
            <>
              <br /> <br /> <br />
              <SpinnerContainer>
                <LoadingSpinner />
              </SpinnerContainer>
            </>
          )}
        </FollowupsContainer>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    userToken: getUserToken(state),
    isPro: isCompanyPro(state),
    isPlus: isCompanyBasicPlus(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getFollowups: async (token) => {
      dispatch({ type: GET_FOLLOWUPS_PENDING });
      const followups = await GET_FOLLOWUPS(token);
      return dispatch(followups);
    },
    deleteFollowup: async (_id, token) => {
      dispatch({ type: DELETE_FOLLOWUP_PENDING });
      const deletedFollowup = await DELETE_FOLLOWUP(_id, token);
      return dispatch(deletedFollowup);
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(withMedTracker(Followups)));
