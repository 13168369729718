import PropTypes from 'prop-types';
import React, { Component } from 'react';
import LeftDetailView from './LeftDetailView';
import RightDetailView from './RightDetailView';
import DiseasesModal from './RightDetailView/DiseasesModal';
import HabitsModal from './RightDetailView/HabitsModal';
import PatientDataModal from './RightDetailView/PatientDataModal';
import { DetailViewBody, DetalViewContainer } from './style';

class DetailContainer extends Component {
  static propTypes = {
    report: PropTypes.object.isRequired,
    step: PropTypes.string,
    onSetStep: PropTypes.func,
    onSetStatus: PropTypes.func,
    isNR: PropTypes.bool,
    isQuestion: PropTypes.bool,
    isPN: PropTypes.bool,
  };

  state = {
    isOpenPatientDataModal: false,
    isOpenDiseasesModal: false,
    isOpenHabitsModal: false,
  };

  handleOpenPatientDataModal = () => {
    this.setState({ isOpenPatientDataModal: true });
  };

  handleClosePatientDataModal = () => {
    this.setState({ isOpenPatientDataModal: false });
  };

  handleOpenDiseasesModal = () => {
    this.setState({ isOpenDiseasesModal: true });
  };

  handleCloseDiseasesModal = () => {
    this.setState({ isOpenDiseasesModal: false });
  };

  handleOpenHabitsModal = () => {
    this.setState({ isOpenHabitsModal: true });
  };

  handleCloseHabitsModal = () => {
    this.setState({ isOpenHabitsModal: false });
  };

  render() {
    const { report, status, step, onSetStep, onSetStatus, isNR, isQuestion, isPN, onSwitchReport } =
      this.props;

    const { isOpenPatientDataModal, isOpenDiseasesModal, isOpenHabitsModal } = this.state;

    return (
      <DetalViewContainer>
        <PatientDataModal
          isOpenModal={isOpenPatientDataModal}
          onClose={this.handleClosePatientDataModal}
        />
        <DiseasesModal isOpenModal={isOpenDiseasesModal} onClose={this.handleCloseDiseasesModal} />
        <HabitsModal isOpenModal={isOpenHabitsModal} onClose={this.handleCloseHabitsModal} />
        <DetailViewBody>
          <LeftDetailView
            isNR={isNR}
            isPN={isPN}
            isQuestion={isQuestion}
            onSetStatus={onSetStatus}
            onSetStep={onSetStep}
            status={status}
            step={step}
            report={report}
            onSwitchReport={onSwitchReport}
          />
          <RightDetailView
            onOpenDiseasesModal={this.handleOpenDiseasesModal}
            onOpenHabitsModal={this.handleOpenHabitsModal}
            onOpenPatientDataModal={this.handleOpenPatientDataModal}
            report={report}
          />
        </DetailViewBody>
      </DetalViewContainer>
    );
  }
}

export default DetailContainer;
