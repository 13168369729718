import {
  GET_TEAM_FAIL,
  GET_TEAM_PENDING,
  GET_TEAM_SUCCESS,
  INVITE_COLLEAGUE_FAIL,
  INVITE_COLLEAGUE_PENDING,
  INVITE_COLLEAGUE_SUCCESS,
} from 'actions';

const initialState = {
  teamMembers: [],
  teamLoaded: false,
  teamMemberInvited: false,
  errors: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_TEAM_PENDING:
      return {
        ...state,
        errors: {},
        teamLoaded: false,
      };
    case GET_TEAM_SUCCESS: {
      const teamMembers = action.payload;
      return {
        ...state,
        teamMembers,
        teamLoaded: true,
      };
    }
    case GET_TEAM_FAIL: {
      const { errors } = action.payload;
      return {
        ...state,
        errors: errors,
      };
    }
    case INVITE_COLLEAGUE_PENDING:
      return {
        ...state,
        errors: {},
        teamMemberInvited: false,
      };
    case INVITE_COLLEAGUE_SUCCESS: {
      // const response = action.payload
      return {
        ...state,
        teamMemberInvited: true,
      };
    }
    case INVITE_COLLEAGUE_FAIL: {
      const { errors } = action.payload;
      return {
        ...state,
        errors: errors,
      };
    }
    default: {
      return state;
    }
  }
};
