import React from 'react';
import Header from '../../components/Header';
import Register from '../../components/Register';
import { BackgroundImageWrapper } from './style';

const RegisterContainer = (props) => {
  return (
    <>
      <Header />
      <BackgroundImageWrapper>
        <Register />
      </BackgroundImageWrapper>
    </>
  );
};

export default RegisterContainer;
