import axios from 'axios';

const URL = {
  pdf: '/export/report.pdf',
  xml: '/export/report.xml',
};

// This needs to post to identity, but without headers
const instance = axios.create({
  baseURL: process.env.REACT_APP_API_EXPORT,
});

instance.interceptors.request.use((config) => {
  if (typeof config.data === 'undefined') {
    // https://github.com/axios/axios/issues/86
    config.data = {};
  }
  config.headers['Content-Type'] = 'application/json';
  return config;
});

/**
 *
 * @returns {Promise} - Result of ajax call
 */
export async function exportFile(report, fileType) {
  return instance.post(
    URL[fileType],
    { report },
    {
      responseType: 'blob',
      timeout: 30000,
    },
  );
}
