import { LOGIN_AUTH_SUCCESS, SET_PROOFFER } from 'actions';

import moment from 'moment';
import { CARD_TYPE } from '../../constants';

const initialState = {
  proOffer: CARD_TYPE.Free,
  isPro: false,
  isPlus: false,
  persistExpiresAt: null,
};
// TODO: Refactor this reducer,
// 1. Rename it to SET_SUBSCRIPTION_TYPE
// 2. Only 1 state variable to hold subscription type
// 3. Get rid of multiple boolean flags
// 4. Add flag to track transition of subscription type

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_PROOFFER: {
      const proOffer = action.payload;
      const isPro = proOffer === CARD_TYPE.Pro;
      const isPlus = proOffer === CARD_TYPE.Plus;
      return {
        ...state,
        proOffer,
        isPro,
        isPlus,
      };
    }
    // When login is success, set the persist expiration time
    case LOGIN_AUTH_SUCCESS: {
      const { user } = action.payload;
      return {
        ...state,
        persistExpiresAt: moment.unix(user.expiresAt).format(),
      };
    }

    default: {
      return state;
    }
  }
};
