import PropTypes from 'prop-types';
import React from 'react';
import {
  AlertHeader,
  Content,
  DlgActions,
  MedModalDlg,
  NoBtn,
  ReportHeader,
  YesBtn,
} from './style';

const MedModal = ({
  hasYes,
  hasNo,
  onYes,
  onNo,
  modalContent,
  isOpen,
  title,
  yesTxt,
  noTxt,
  isAlert,
}) => {
  return (
    <MedModalDlg
      aria-describedby="alert-dialog-description"
      aria-labelledby="alert-dialog-title"
      onClose={onNo}
      open={isOpen}
    >
      <Content>
        {isAlert ? <AlertHeader>{title}</AlertHeader> : <ReportHeader>{title}</ReportHeader>}
        {modalContent}
      </Content>
      <DlgActions>
        {hasYes && (
          <YesBtn onClick={onYes} variant="contained">
            {yesTxt}
          </YesBtn>
        )}
        {hasNo && (
          <NoBtn onClick={onNo} variant="contained">
            {noTxt}
          </NoBtn>
        )}
      </DlgActions>
    </MedModalDlg>
  );
};

MedModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onNo: PropTypes.func,
  onYes: PropTypes.func,
  hasYes: PropTypes.bool,
  hasNo: PropTypes.bool,
  modalContent: PropTypes.object,
  title: PropTypes.string,
  yesTxt: PropTypes.string,
  noTxt: PropTypes.string,
  isAlert: PropTypes.bool,
};

export default MedModal;
