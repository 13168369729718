import { CardMedia, InputAdornment, Tabs } from '@material-ui/core';
import {
  AddBox,
  AddCircle,
  CheckBox,
  CheckBoxOutlineBlank,
  CloudUpload,
  DateRange,
  Info,
  InsertDriveFile,
  RadioButtonChecked,
  RadioButtonUnchecked,
  Subject,
} from '@material-ui/icons';
import ErrorIcon from '@material-ui/icons/Error';
import Autocomplete from '@material-ui/lab/Autocomplete';
import fileUploadPreviewImg from 'assets/img/file_upload_preview.png';
import dateTypeFollowupImg from 'assets/img/followup_date_preview.jpg';
import textTypeFollowupImg from 'assets/img/followup_text_preview.jpg';
import I18n from 'i18n';
import lodash from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RIEInput } from 'riek';
import * as palette from 'styles/palette';
import LinearProgress from '../../LinearProgress';

import { getUserToken } from 'selectors/user';
import { withMedTracker } from 'services/tracking';

import {
  ADD_FOLLOWUP,
  ADD_FOLLOWUP_PENDING,
  DELETE_FILE,
  EDIT_FOLLOWUP,
  EDIT_FOLLOWUP_PENDING,
  UPLOAD_FILE,
} from 'actions';

import {
  AddQuestionForm,
  ChipStyled,
  CloseIconStyled,
  DeleteChipIcon,
  DeleteIcon,
  DlgActionsStyled,
  EditDataHeader,
  ErrorMsg,
  FileUploadContainer,
  FileUploadErr,
  FileUploadInfo,
  FollowupOptionInput,
  FollowupTitle,
  InfoIconContainer,
  InlineEditContainer,
  InputContainerStyled,
  InputStyled,
  LabelStyled,
  MedModalDlg,
  MenuItem,
  NoBtn,
  PreviewContainerStyled,
  ProgressBarContainer,
  QuestionPreview,
  TextFieldStyled,
  UpdateDataBtn,
  UploadFileBtn,
} from './style';

function scrollOptionsBoxToBottom() {
  var followupOptionsBox = document.getElementById('optionsBox') || {};
  followupOptionsBox.scrollTop = 300;
}

const FOLLOWUP_OPTION_CHARLIMIT = 70;
const FOLLOWUP_LABEL_CHARLIMIT = 20;
const FOLLOWUP_TITLE_CHARLIMIT = 75;
const FOLLOWUP_TEXT_CHARLIMIT = 480;
const NR_OF_LABELS_ALLOWED = 10;
const FILE_TYPES_ALLOWED = ['application/pdf', 'image/jpeg', 'image/png', 'image/jpg'];
const MAX_FILE_SIZE_ALLOWED = 3e7;

class AddFollowupModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      followupData: {
        followupTitle: '',
        followupText: '',
        followupType: 'free-text',
        followupOptions: [],
        followupLabels: [],
        followupFile: {
          id: '',
          name: '',
        },
      },
      isInvalid: {
        followupTitle: false,
        followupText: false,
        followupFile: false,
        followupChoice: false,
      },
      followupLabel: '',
      followupLabelInvalid: false,
      isLoading: false,
      allLablesOptions: [],
      activeMenu: 'free-text',
      newOption: '',
      allFollowupTitles: [],
      allFollowupTexts: [],
      isEditing: false,
      openFollowupLabelsDropdown: false,
      fileUploadMsg: I18n.t('components.Followups.AddFollowup.fileFollowups.allowedFileTypesMsg'),
      fileUploadInProgress: false,
      fileUploadDone: false,
      fileUploadProgress: 0,
      formDirty: false,
    };
  }

  componentDidUpdate() {
    scrollOptionsBoxToBottom();
  }

  async componentWillReceiveProps() {
    await this.initializeFollowupData();
    if (this.props.allLabels) {
      this.setState({
        ...this.state,
        allLablesOptions: [...this.props.allLabels],
      });
    }
    if (this.props.questionToEdit.followupTitle) {
      this.setState({
        ...this.state,
        followupData: lodash.cloneDeep(this.props.questionToEdit),
        isEditing: true,
        fileUploadDone: !!this.props.questionToEdit.followupFile.id,
      });
    }
    if (this.props.allFollowups) {
      this.createTitleAndTextArrays();
    }
  }

  createTitleAndTextArrays() {
    // create two arrays: one with all the titles and the other with all the texts
    // remove current question (question that is being edited) from the arrays
    const followupTitles = [];
    const followupTexts = [];

    this.props.allFollowups.forEach((followup) => {
      if (this.props.questionToEdit.followupTitle !== followup.followupTitle) {
        followupTitles.push(followup.followupTitle);
      }
      if (this.props.questionToEdit.followupText !== followup.followupText) {
        followupTexts.push(followup.followupText);
      }
    });

    this.setState({
      allFollowupTitles: followupTitles,
      allFollowupTexts: followupTexts,
    });
  }

  checkIfFollowupIsDuplicated(title, text) {
    return {
      title: this.state.allFollowupTitles.includes(title),
      text: this.state.allFollowupTexts.includes(text),
    };
  }

  handleAddFollowup = async () => {
    const { followupTitle, followupText, followupType, followupOptions } = this.state.followupData;
    this.setState({ isLoading: true });
    const isFollowupDuplicated = this.checkIfFollowupIsDuplicated(followupTitle, followupText);

    let isError = false;
    let followupTitleError = '';
    let followupTextError = '';
    let followupChoiceError = '';

    if (
      !followupTitle ||
      !followupText ||
      isFollowupDuplicated.title ||
      isFollowupDuplicated.text
    ) {
      if (!followupTitle || !followupTitle.length) {
        followupTitleError = I18n.t('components.Followups.AddFollowup.errors.emptyTitle');
      } else if (isFollowupDuplicated.title) {
        followupTitleError = I18n.t('components.Followups.AddFollowup.errors.titleExists');
      }

      if (!followupText || !followupText.length) {
        followupTextError = I18n.t('components.Followups.AddFollowup.errors.emptyText');
      } else if (isFollowupDuplicated.text) {
        followupTextError = I18n.t('components.Followups.AddFollowup.errors.textExists');
      }

      isError = true;
    }

    if (
      (followupType === 'single-choice' || followupType === 'multiple-choice') &&
      !followupOptions.length
    ) {
      followupChoiceError = I18n.t('components.Followups.AddFollowup.errors.noChoiceOption');
      isError = true;
    }

    this.setState({
      isInvalid: {
        ...this.state.isInvalid,
        followupTitle: followupTitleError,
        followupText: followupTextError,
        followupChoice: followupChoiceError,
      },
    });

    if (isError) {
      return;
    }

    if (Object.entries(this.props.questionToEdit).length) {
      await this.props.editFollowup(this.state.followupData, this.props.userToken);
    } else {
      await this.props.addFollowup(this.state.followupData, this.props.userToken);

      // TODO:: Understand the tracking requirement in details
      // clear requirements on event-category, event-action, event-name
      this.props.MedTrackEvent(
        'FollowupTemplates',
        'create',
        this.state.followupData.followupType,
        1,
      );
    }
    this.initializeAllData();
  };

  initializeFollowupData = async (initialized) => {
    await this.setState({
      followupData: {
        followupTitle: '',
        followupType: 'free-text',
        followupText: '',
        followupOptions: [],
        followupLabels: [],
        followupFile: {
          id: '',
          name: '',
        },
      },
      isLoading: false,
      followupLabelInvalid: false,
      followupLabel: '',
      newOption: '',
      formDirty: false,
      isInvalid: {
        followupTitle: '',
        followupText: '',
        followupFile: false,
        followupChoice: '',
      },
    });
  };

  initializeFileUploadData = async () => {
    await this.setState({
      fileUploadMsg: I18n.t('components.Followups.AddFollowup.fileFollowups.allowedFileTypesMsg'),
      fileUploadDone: false,
      fileUploadInProgress: false,
      followupData: {
        ...this.state.followupData,
        followupFile: {
          id: '',
          name: '',
        },
      },
      isInvalid: {
        ...this.state.isInvalid,
        followupFile: false,
      },
    });
  };

  initializeAllData = async () => {
    await this.initializeFollowupData();
    await this.initializeFileUploadData();
    this.props.closeModal();
  };

  handleOnCancel = async () => {
    if (!this.state.isEditing && this.state.followupData.followupFile.id) {
      this.handleFileDelete();
    }
    this.initializeAllData();
  };

  isFormDirty = () => {
    this.setState({
      formDirty: !lodash.isEqual(this.props.questionToEdit, this.state.followupData),
    });
  };

  handleNoOptionFoundClick = (event) => {
    if (
      (event.key === 'Enter' || event.type === 'click') &&
      this.state.followupLabel.trim().length
    ) {
      const labelsSelected = this.state.followupData.followupLabels || [];

      // if new label already exists, remove it, else add it
      const newLabelExists = labelsSelected.indexOf(this.state.followupLabel);
      if (newLabelExists > -1) {
        labelsSelected.splice(newLabelExists, 1);
      } else {
        labelsSelected.push(this.state.followupLabel);
      }
      this.setState(
        {
          followupData: {
            ...this.state.followupData,
            followupLabels: labelsSelected,
          },
          followupLabel: '',
        },
        () => {
          this.isFormDirty();
        },
      );
    }
  };

  handleFollowupLabel = (event, value) => {
    if (value.length > FOLLOWUP_LABEL_CHARLIMIT) {
      // followup Label is invalid if it's longer then FOLLOWUP_LABEL_CHARLIMIT and if user has start typing but is typing just empty spaces (regex)
      this.setState({ followupLabelInvalid: true });
      return;
    }
    this.setState(
      {
        followupLabel: value,
        followupLabelInvalid: false,
      },
      () => {
        this.isFormDirty();
      },
    );
  };

  handleEnterPresOnLabel = (event, value) => {
    if (value.length > NR_OF_LABELS_ALLOWED) {
      this.setState({ followupLabelInvalid: true, followupLabel: '' });
      return;
    }

    this.setState(
      (state) => {
        // if the label already exists in dropdown, dont add it again (fixes bug on MED-827)
        let updatedLabelsOptions;
        if (value[value.length - 1]) {
          if (state.allLablesOptions.includes(value[value.length - 1])) {
            updatedLabelsOptions = state.allLablesOptions;
          } else {
            updatedLabelsOptions = state.allLablesOptions.concat(value[value.length - 1]);
          }
        } else {
          updatedLabelsOptions = state.allLablesOptions;
        }
        return {
          allLablesOptions: updatedLabelsOptions,
          followupData: {
            ...state.followupData,
            followupLabels: value,
          },
          followupLabel: '',
          followupLabelInvalid: false,
        };
      },
      () => {
        this.isFormDirty();
      },
    );
  };

  handleChipDelete = (label) => {
    const customLabels = this.state.followupData.followupLabels;
    customLabels.splice(customLabels.indexOf(label), 1);
    this.setState(
      {
        followupData: {
          ...this.state.followupData,
          followupLabels: customLabels,
        },
      },
      () => {
        this.isFormDirty();
      },
    );
  };

  onChangeData(element, value) {
    this.setState(
      {
        followupData: { ...this.state.followupData, [element]: value },
      },
      () => {
        this.isFormDirty();
      },
    );
  }

  handleFollowupTypeTabChange = (event, newValue) => {
    this.onChangeData('followupType', newValue);
  };

  editOption = (oldOption, newValue) => {
    const allOptions = this.state.followupData.followupOptions;
    allOptions[allOptions.indexOf(oldOption)] = newValue;
    this.setState(
      {
        followupData: {
          ...this.state.followupData,
          followupOptions: allOptions,
        },
      },
      () => {
        this.isFormDirty();
      },
    );
  };

  addOption = (event, value) => {
    if (event.key === 'Enter' && this.state.followupData.followupOptions.length < 10) {
      const updatedOptions = this.state.followupData.followupOptions;
      updatedOptions.push(value);
      this.setState(
        {
          newOption: '',
          followupData: {
            ...this.state.followupData,
            followupOptions: updatedOptions,
          },
        },
        () => {
          this.isFormDirty();
        },
      );
    }
  };

  removeOption = (event, valueToRemove) => {
    const allOptions = this.state.followupData.followupOptions;
    allOptions.splice(allOptions.indexOf(valueToRemove), 1);
    this.setState(
      {
        followupData: {
          ...this.state.followupData,
          followupOptions: allOptions,
        },
      },
      () => {
        this.isFormDirty();
      },
    );
  };

  setFileUploadProgress = (percentage) => {
    this.setState({
      fileUploadProgress: percentage,
    });
  };

  getInlineEditOption = (type, followupOptions, isInvalid, props) => {
    return (
      <>
        <PreviewContainerStyled id="optionsBox">
          <QuestionPreview>{props.questionText}</QuestionPreview>
          <br />
          <br />
          {followupOptions.map((option, index) => {
            if (option.length) {
              return (
                <InlineEditContainer>
                  {type === 'single' ? (
                    <RadioButtonUnchecked style={{ marginRight: '5px', fontSize: '17px' }} />
                  ) : (
                    <CheckBoxOutlineBlank style={{ marginRight: '5px', fontSize: '17px' }} />
                  )}
                  <RIEInput
                    key={option}
                    value={option}
                    change={(e) => this.editOption(option, e.newOption)}
                    propName="newOption"
                    editProps={{
                      maxLength: FOLLOWUP_OPTION_CHARLIMIT,
                      style: { width: '100%' },
                    }}
                  />
                  <DeleteChipIcon
                    style={{ cursor: 'pointer', marginLeft: 'auto' }}
                    onClick={(e) => this.removeOption(e, option)}
                  />
                </InlineEditContainer>
              );
            }
          })}
        </PreviewContainerStyled>
        {isInvalid.followupChoice && (
          <>
            <ErrorMsg>
              <ErrorIcon style={{ verticalAlign: 'top', marginRight: '5px' }} />
              {isInvalid.followupChoice}
            </ErrorMsg>
            <br />
            <br />
          </>
        )}
        <FollowupOptionInput
          addNewOptionField
          placeholder="Option hinzufügen"
          fullWidth
          onChange={(event) => {
            return this.setState({
              newOption: event.target.value.slice(0, FOLLOWUP_OPTION_CHARLIMIT),
            });
          }}
          onKeyPress={(e) => this.addOption(e, this.state.newOption)}
          value={this.state.newOption}
          InputProps={{
            disableUnderline: false,
            startAdornment: (
              <InputAdornment position="start">
                {type === 'single' ? (
                  <AddCircle
                    style={{
                      color: palette.primaryDarkGreen,
                      fontSize: '17px',
                    }}
                  />
                ) : (
                  <AddBox
                    style={{
                      color: palette.primaryDarkGreen,
                      fontSize: '17px',
                    }}
                  />
                )}
              </InputAdornment>
            ),
          }}
        />
      </>
    );
  };

  TabPanel = (props) => {
    const { isInvalid } = this.state;
    const followupOptions = lodash.get(props, 'options', '');
    switch (props.value) {
      case 'free-text':
      case '':
        return (
          <>
            <QuestionPreview>{props.questionText}</QuestionPreview>
            <br />
            <br />
            <CardMedia image={textTypeFollowupImg} style={{ height: '92px', width: '290px' }} />
          </>
        );
      case 'date':
        return (
          <>
            <QuestionPreview>{props.questionText}</QuestionPreview>
            <br />
            <br />
            <CardMedia image={dateTypeFollowupImg} style={{ height: '177px', width: '204px' }} />
          </>
        );
      case 'single-choice':
        return this.getInlineEditOption('single', followupOptions, isInvalid, props);
      case 'multiple-choice':
        return this.getInlineEditOption('multiple', followupOptions, isInvalid, props);
      case 'file':
        return (
          <>
            <QuestionPreview>{props.questionText}</QuestionPreview>
            <br />
            <br />
            <CardMedia image={fileUploadPreviewImg} style={{ height: '92px', width: '310px' }} />
          </>
        );
    }
  };

  handleUploadFile = async (event) => {
    const fileToUpload = event.target.files[0];
    if (fileToUpload) {
      await this.initializeFileUploadData();

      // if file size is > then 30mb or file is of type not supported, reject it
      if (
        fileToUpload.size > MAX_FILE_SIZE_ALLOWED ||
        !FILE_TYPES_ALLOWED.includes(fileToUpload.type)
      ) {
        await this.setFileUploadInvalid(
          I18n.t('components.Followups.AddFollowup.fileFollowups.errorInvalid'),
        );
        return;
      }

      this.setState({
        fileUploadInProgress: true,
        fileUploadMsg: fileToUpload.name,
      });
      const data = new window.FormData();
      data.append('followup_questions', fileToUpload);
      const response = await this.props.uploadFile(
        data,
        this.props.userToken,
        this.setFileUploadProgress,
      );
      this.setFileUploadProgress(100);
      if (response.type === 'UPLOAD_FILE_FAIL') {
        await this.setFileUploadInvalid(
          I18n.t('components.Followups.AddFollowup.fileFollowups.errorUnexpected'),
        );
        return;
      }

      this.setState(
        {
          fileUploadInProgress: false,
          fileUploadDone: true,
          followupData: {
            ...this.state.followupData,
            followupFile: {
              id: response.payload._id,
              name: fileToUpload.name,
            },
          },
        },
        () => {
          this.isFormDirty();
        },
      );
    }
  };

  handleFileDelete = async () => {
    // if file has already been saved (followup is in edit mode), dont delete file from DB and S3 but just remove reference to it
    if (!this.state.isEditing) {
      const response = await this.props.deleteFile(
        this.state.followupData.followupFile.id,
        this.props.userToken,
      );
      if (response.type === 'DELETE_FILE_SUCCESS') {
        await this.initializeFileUploadData();
      }
      if (response.type === 'DELETE_FILE_FAIL') {
        await this.setFileUploadInvalid(
          I18n.t('components.Followups.AddFollowup.fileFollowups.errorUnexpected'),
        );
      }
    } else {
      this.setState(
        {
          followupData: {
            ...this.state.followupData,
            followupFile: {},
          },
        },
        async () => {
          await this.props.editFollowup(this.state.followupData, this.props.userToken);

          this.isFormDirty();
        },
      );
    }
  };

  setFileUploadInvalid = async (errorMessage) => {
    await this.setState({
      fileUploadInProgress: false,
      fileUploadDone: true,
      isInvalid: {
        ...this.state.isInvalid,
        followupFile: true,
      },
      fileUploadMsg: errorMessage,
    });
  };

  render() {
    const {
      followupData,
      followupLabel,
      followupLabelInvalid,
      allLablesOptions,
      isInvalid,
      openFollowupLabelsDropdown,
      formDirty,
    } = this.state;
    const {
      followupTitle,
      followupText,
      followupLabels,
      followupType,
      followupOptions,
      followupFile,
    } = followupData;

    return (
      <MedModalDlg
        aria-describedby="alert-dialog-description"
        aria-labelledby="alert-dialog-title"
        open={this.props.modalOpened}
        onBackdropClick={this.handleOnCancel}
        scroll="body"
      >
        <EditDataHeader>
          <FollowupTitle>{I18n.t('components.Followups.AddFollowup.title')}</FollowupTitle>
          <CloseIconStyled onClick={this.handleOnCancel} />
        </EditDataHeader>
        <AddQuestionForm>
          <InputContainerStyled>
            <LabelStyled htmlFor="title" isInvalid={isInvalid.followupTitle}>
              Titel
            </LabelStyled>
            <InputStyled
              id="title"
              name="title"
              value={followupTitle}
              onChange={(event) => {
                this.onChangeData(
                  'followupTitle',
                  event.currentTarget.value.slice(0, FOLLOWUP_TITLE_CHARLIMIT),
                );
              }}
              type="text"
              isInvalid={isInvalid.followupTitle}
              required
            />
            {isInvalid.followupTitle && (
              <>
                <ErrorMsg>
                  <ErrorIcon style={{ verticalAlign: 'top', marginRight: '5px' }} />
                  {isInvalid.followupTitle}
                </ErrorMsg>
                <br /> <br />
              </>
            )}
          </InputContainerStyled>
          <InputContainerStyled>
            <LabelStyled htmlFor="text" isInvalid={isInvalid.followupText}>
              {I18n.t('components.Followups.AddFollowup.followupText')}
            </LabelStyled>
            <InputStyled
              id="text"
              name="text"
              onChange={(event) => {
                this.onChangeData(
                  'followupText',
                  event.currentTarget.value.slice(0, FOLLOWUP_TEXT_CHARLIMIT),
                );
              }}
              value={followupText}
              type="text"
              isInvalid={isInvalid.followupText}
              required
            />
            {isInvalid.followupText && (
              <>
                <ErrorMsg>
                  <ErrorIcon style={{ verticalAlign: 'top', marginRight: '5px' }} />
                  {isInvalid.followupText}
                </ErrorMsg>
                <br />
                <br />
              </>
            )}
          </InputContainerStyled>
          <InputContainerStyled>
            <LabelStyled htmlFor="labels">
              {I18n.t('components.Followups.AddFollowup.followupLabel')}
            </LabelStyled>
            <Autocomplete
              multiple
              id="tags-standard"
              options={allLablesOptions}
              getOptionLabel={(option) => option}
              disableClearable
              onInputChange={this.handleFollowupLabel}
              onChange={this.handleEnterPresOnLabel}
              onKeyPress={this.handleNoOptionFoundClick}
              inputValue={followupLabel}
              value={followupLabels}
              // debug is needed to keep dropdown open till onClick event is fired
              // (without debug, the dropdown is closed as soon as the input looses focus and this.handleNoOptionFoundClick is not called)
              debug
              open={openFollowupLabelsDropdown}
              onOpen={(event, val) => {
                this.setState({ openFollowupLabelsDropdown: true });
              }}
              onClose={() => this.setState({ openFollowupLabelsDropdown: false })}
              noOptionsText={
                <div style={{ cursor: 'pointer' }} onClick={this.handleNoOptionFoundClick}>
                  {this.state.followupLabel + ' (Neues Label mit Enter hinzufügen)'}
                </div>
              }
              renderTags={(value, getTagProps) => {
                return (value || []).map((label, index) => (
                  <ChipStyled
                    color="primary"
                    key={label}
                    size="small"
                    label={label}
                    {...getTagProps({ index })}
                    onDelete={() => this.handleChipDelete(label)}
                    deleteIcon={<DeleteChipIcon />}
                  />
                ));
              }}
              renderOption={(option) => <span>{option}</span>}
              renderInput={(params) => (
                <TextFieldStyled
                  followupLabelInvalid={followupLabelInvalid}
                  {...params}
                  variant="standard"
                  fullWidth
                />
              )}
              closeIcon={<DeleteChipIcon />}
            />
          </InputContainerStyled>
          <InputContainerStyled>
            <LabelStyled htmlFor="fileUpload" isInvalid={isInvalid.followupFile}>
              {I18n.t('components.Followups.AddFollowup.fileFollowups.type')}:
            </LabelStyled>
            <br />
            <FileUploadContainer>
              <input
                style={{ display: 'none' }}
                id="followup__file_upload_btn"
                type="file"
                onChange={this.handleUploadFile}
                accept={FILE_TYPES_ALLOWED}
              />
              <UploadFileBtn
                htmlFor="followup__file_upload_btn"
                component="label"
                variant="contained"
                color="default"
                startIcon={<CloudUpload />}
                disabled={
                  this.state.fileUploadInProgress ||
                  (this.state.fileUploadDone && !this.state.isInvalid.followupFile)
                }
              >
                {I18n.t('components.Followups.AddFollowup.fileFollowups.uploadFile')}
              </UploadFileBtn>
              {!this.state.isInvalid.followupFile && !this.state.fileUploadInProgress && (
                <FileUploadInfo>{followupFile.name || this.state.fileUploadMsg}</FileUploadInfo>
              )}
              {this.state.fileUploadDone && !this.state.isInvalid.followupFile && (
                <DeleteIcon onClick={this.handleFileDelete} />
              )}
              {this.state.fileUploadInProgress && (
                <ProgressBarContainer>
                  <LinearProgress progress={this.state.fileUploadProgress} />
                </ProgressBarContainer>
              )}
              {this.state.isInvalid.followupFile && (
                <FileUploadErr>
                  <InfoIconContainer>
                    <Info />
                  </InfoIconContainer>
                  {this.state.fileUploadMsg}
                </FileUploadErr>
              )}
            </FileUploadContainer>
          </InputContainerStyled>
          <InputContainerStyled>
            <LabelStyled htmlFor="type">
              {I18n.t('components.Followups.AddFollowup.followupType')}
            </LabelStyled>
            <br />
            <Tabs
              onChange={this.handleFollowupTypeTabChange}
              value={followupType}
              TabIndicatorProps={{
                style: { backgroundColor: palette.primaryDarkGreen },
              }}
            >
              <MenuItem
                label={
                  <span>
                    <Subject style={{ marginBottom: '-3px' }} />{' '}
                    {I18n.t('components.Followups.AddFollowup.followupTypes.freeText')}
                  </span>
                }
                value="free-text"
              />
              <MenuItem
                label={
                  <span>
                    <DateRange style={{ marginBottom: '-3px' }} />{' '}
                    {I18n.t('components.Followups.AddFollowup.followupTypes.date')}
                  </span>
                }
                value="date"
              />
              <MenuItem
                isInvalid={isInvalid.followupChoice && followupType === 'single-choice'}
                label={
                  <span>
                    <RadioButtonChecked style={{ marginBottom: '-3px' }} />{' '}
                    {I18n.t('components.Followups.AddFollowup.followupTypes.singleChoice')}
                  </span>
                }
                value="single-choice"
              />
              <MenuItem
                isInvalid={isInvalid.followupChoice && followupType === 'multiple-choice'}
                label={
                  <span>
                    <CheckBox style={{ marginBottom: '-3px' }} />{' '}
                    {I18n.t('components.Followups.AddFollowup.followupTypes.multipleChoice')}
                  </span>
                }
                value="multiple-choice"
              />
              <MenuItem
                label={
                  <span>
                    <InsertDriveFile style={{ marginBottom: '-3px' }} />{' '}
                    {I18n.t('components.Followups.AddFollowup.followupTypes.file')}
                  </span>
                }
                value="file"
              />
            </Tabs>
            <br />
            <this.TabPanel
              value={followupType}
              options={followupOptions}
              questionText={followupText}
            />
          </InputContainerStyled>
        </AddQuestionForm>
        <DlgActionsStyled style={{ justifyContent: 'space-between', padding: 0 }}>
          <NoBtn onClick={this.handleOnCancel}>
            {I18n.t('components.Followups.AddFollowup.cancel')}
          </NoBtn>
          <UpdateDataBtn onClick={this.handleAddFollowup} variant="contained" disabled={!formDirty}>
            {I18n.t('components.Followups.AddFollowup.save')}
          </UpdateDataBtn>
        </DlgActionsStyled>
      </MedModalDlg>
    );
  }
}

AddFollowupModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  modalOpened: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return { userToken: getUserToken(state) };
}

function mapDispatchToProps(dispatch) {
  return {
    addFollowup: async (followup, token) => {
      dispatch({ type: ADD_FOLLOWUP_PENDING });
      const response = await ADD_FOLLOWUP(followup, token);
      return dispatch(response);
    },
    editFollowup: async (followup, token) => {
      dispatch({ type: EDIT_FOLLOWUP_PENDING });
      const response = await EDIT_FOLLOWUP(followup, token);
      return dispatch(response);
    },
    uploadFile: async (file, token, cb) => {
      const response = await UPLOAD_FILE(file, token, cb);
      return dispatch(response);
    },
    deleteFile: async (fileId, token) => {
      const response = await DELETE_FILE(fileId, token);
      return dispatch(response);
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withMedTracker(AddFollowupModal));
