import {
  APPLICATION_INIT,
  LOGIN_AUTH_FAIL,
  LOGIN_AUTH_PENDING,
  LOGIN_AUTH_SUCCESS,
  LOGOUT_AUTH_FAIL,
  LOGOUT_AUTH_SUCCESS,
  SET_URL_TOKEN,
} from 'actions';
import moment from 'moment';

const initialState = {
  isAuthenticated: false,
  isAuthInProgress: false,
  errors: {},
  user: {},
  persistExpiresAt: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case APPLICATION_INIT:
      return { ...initialState, ...state };
    case LOGIN_AUTH_FAIL: {
      const { errors } = action.payload;
      return {
        ...state,
        isAuthenticated: false,
        isAuthInProgress: false,
        errors: errors,
      };
    }
    case LOGIN_AUTH_PENDING: {
      return {
        ...state,
        isAuthenticated: false,
        isAuthInProgress: true,
        errors: {},
      };
    }
    case LOGIN_AUTH_SUCCESS: {
      const { user } = action.payload;
      return {
        ...state,
        user,
        isAuthInProgress: false,
        isAuthenticated: true,
        errors: {},
        persistExpiresAt: moment.unix(user.expiresAt).format(),
      };
    }
    case LOGOUT_AUTH_FAIL: {
      const errors = action.payload;
      return {
        ...state,
        errors: errors,
      };
    }
    case LOGOUT_AUTH_SUCCESS: {
      return initialState;
    }
    case SET_URL_TOKEN: {
      const user = {
        token: action.payload,
      };
      return {
        ...state,
        user,
      };
    }
    default: {
      return state;
    }
  }
};
