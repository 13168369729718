import React from 'react';
import EdiText from 'react-editext';
import styled from 'styled-components';
import * as palette from 'styles/palette';

export const Container = styled.div`
  display: flex;
  align-items: center;
  max-width: 270px;
  min-width: 170px;
`;
export const Label = styled.div`
  width: 100%;
  padding: 4px 0.5em;
  border: 1px solid transparent;
  border-radius: 5px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: ${palette.menuBorder};
    border-color: ${palette.menuBorder};
  }
`;

export const StyledInlineEdit = styled((props) => <EdiText {...props} />)`
  &&& {
    transition: all 0.3s ease-in-out;

    input {
      width: calc(270px - 1em);
      border: 1px solid ${palette.menuBorder};
      border-radius: 5px;
      padding: 4px 0.5em;
    }
    button {
      display: none;
    }
  }
`;
