import CircularProgress from '@material-ui/core/CircularProgress';
import passwordMeter from 'passwordmeter';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';
import validator from 'validator';

import { SET_INVALID_TOKEN_ERROR } from 'actions';
import { NEW_PASSWORD_SETUP, NEW_PASSWORD_SETUP_PENDING } from 'actions/passwordRecovery/index';
import ErrorLabel from 'components/ErrorLabel';
import { LoginButton, LoginForm, LoginLabel, LoginTitle } from 'components/Login/style';
import PwdValidationLabel from 'components/PwdValidationLabel';
import I18n from 'i18n';
import Input from 'lib/elements/Input';
import InputContainer from 'lib/elements/InputContainer';
import { getUserToken } from 'selectors/user';
import { ButtonsContainer, ResetPasswordContainer } from './style';

class LoginNewPassword extends Component {
  state = {
    newPasswordError: false,
    password: '',
    confirmPassword: '',
    goForward: false,
    isLoading: false,

    // validations
    isValid: {
      password: true,
      confirmPassword: true,
    },
    isFocusOnPwd: false,
    strength: 0,
  };

  isValidation = () => {
    const { isValid, password, confirmPassword } = this.state;

    if (
      !password.toString().trim().length ||
      Math.floor(passwordMeter.checkPass(password, 8) / 25) < 1
    ) {
      isValid.password = false;
    }

    if (!confirmPassword.toString().trim().length || password !== confirmPassword) {
      isValid.confirmPassword = false;
    }

    const formValid = isValid.password && isValid.confirmPassword;
    this.setState({ isValid });
    return formValid;
  };

  handlePwdFocus = () => {
    this.setState({ isFocusOnPwd: true });
  };

  handlePwdBlur = () => {
    this.setState({ isFocusOnPwd: false });
  };

  handleChangePassword = (event) => {
    const { isValid } = this.state;
    this.setState(
      {
        password: event.currentTarget.value,
      },
      () => {
        if (
          validator.isEmpty(this.state.password) ||
          Math.floor(passwordMeter.checkPass(this.state.password, 8) / 25) < 1
        ) {
          isValid.password = false;
        } else {
          isValid.password = true;
        }
        this.setState({
          isValid,
          strength: passwordMeter.checkPass(this.state.password, 8),
        });
      },
    );
  };

  handleChangeConfirmPassword = (event) => {
    const { isValid } = this.state;
    this.setState(
      {
        confirmPassword: event.currentTarget.value,
      },
      () => {
        if (
          validator.isEmpty(this.state.confirmPassword) ||
          this.state.password !== this.state.confirmPassword
        ) {
          isValid.confirmPassword = false;
        } else {
          isValid.confirmPassword = true;
        }
        this.setState({ isValid });
      },
    );
  };

  handleSetNewPassword = async () => {
    this.setState({
      isLoading: true,
    });
    if (!this.isValidation()) {
      this.setState({ isLoading: false });
      return;
    }

    try {
      const request = {
        newPassword: this.state.password,
      };
      const response = await this.props.newPasswordSetup(request, this.props.userToken);
      if (response.type === 'NEW_PASSWORD_SETUP_SUCCESS') {
        this.setState({
          goForward: true,
        });
      } else {
        this.setState({
          isLoading: false,
          newPasswordError: true,
        });
        this.props.setInvalidTokenError();
        this.props.history.push('/login/reset');
      }
    } catch (error) {
      this.setState({
        newPasswordError: true,
      });
    }
  };

  handleSubmit = (event) => {
    event.preventDefault();
  };

  handleEnter = (event) => {
    if (event.key === 'Enter') {
      this.handleSetNewPassword();
    }
  };

  render() {
    const { goForward, isValid, isLoading, strength, isFocusOnPwd, password } = this.state;

    if (goForward) {
      return <Redirect to="/login" />;
    }

    return (
      <ResetPasswordContainer>
        <LoginForm onSubmit={this.handleSubmit}>
          <LoginTitle>
            <span>{I18n.t('components.LoginNewPassword.title')}</span>
          </LoginTitle>
          {!isLoading ? (
            <>
              <LoginLabel>{I18n.t('components.LoginNewPassword.label')}</LoginLabel>
              <InputContainer>
                <InputContainer>
                  <Input
                    haserror={!isValid.password}
                    name="password"
                    onBlur={this.handlePwdBlur}
                    onChange={this.handleChangePassword}
                    onFocus={this.handlePwdFocus}
                    placeholder={I18n.t('components.LoginNewPassword.placeholders.password')}
                    type="password"
                  />
                  <ErrorLabel
                    errText={I18n.t('components.LoginNewPassword.errors.invalidPassword')}
                    hasError={!isValid.password}
                  />
                </InputContainer>
                <InputContainer>
                  <Input
                    haserror={!isValid.confirmPassword}
                    name="confirmPassword"
                    onChange={this.handleChangeConfirmPassword}
                    onKeyPress={this.handleEnter}
                    placeholder={I18n.t('components.LoginNewPassword.placeholders.confirmPassword')}
                    type="password"
                  />
                  <ErrorLabel
                    errText={I18n.t('components.LoginNewPassword.errors.passwordsNotMatching')}
                    hasError={!isValid.confirmPassword}
                  />
                </InputContainer>
                {!validator.isEmpty(password) && (
                  <PwdValidationLabel isFocus={isFocusOnPwd} strength={strength} />
                )}
              </InputContainer>
              <ButtonsContainer>
                <LoginButton onClick={this.handleSetNewPassword}>
                  {I18n.t('components.LoginNewPassword.loginButton')}
                </LoginButton>
              </ButtonsContainer>
            </>
          ) : (
            <>
              <br /> <br /> <br />
              <CircularProgress />
            </>
          )}
        </LoginForm>
      </ResetPasswordContainer>
    );
  }
}

function mapStateToProps(state) {
  return { userToken: getUserToken(state) };
}

function mapDispatchToProps(dispatch) {
  return {
    newPasswordSetup: async (data, token) => {
      dispatch({ type: NEW_PASSWORD_SETUP_PENDING });
      const res = await NEW_PASSWORD_SETUP(data, token);
      return dispatch(res);
    },
    setInvalidTokenError: () => {
      dispatch({ type: SET_INVALID_TOKEN_ERROR, payload: true });
    },
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LoginNewPassword));
