import React, { Component } from 'react';

import { connect } from 'react-redux';
import ContainerWrapper from '../../components/ContainerWrapper';
import Followups from '../../components/Followups';
import Header from '../../components/Header';

class FollowupsContainer extends Component {
  state = { isNavOpen: true };

  handleSideBarToggle = (isOpen) => {
    this.setState({ isNavOpen: isOpen });
  };

  keepSidebarOpen =
    this.props.history.action === 'PUSH' &&
    this.props.location.state &&
    this.props.location.state.keepSidebarOpen;

  render() {
    return (
      <>
        <Header
          includeNavSideBar
          isPro={this.props.isPro}
          isPlus={this.props.isPlus}
          keepSidebarOpen={this.keepSidebarOpen}
          onSideBarToggle={this.handleSideBarToggle}
        />
        <ContainerWrapper isNavOpen={this.state.isNavOpen}>
          <Followups />
        </ContainerWrapper>
      </>
    );
  }
}

function mapStateToProps(state) {
  const { isPro, isPlus } = state.proOffer;
  return { isPro, isPlus };
}

export default connect(mapStateToProps)(FollowupsContainer);
