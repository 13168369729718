import { isEmpty } from 'lodash';
import { createSelector } from 'reselect';

export const getCompanyDetails = createSelector(
  (state) => state.pharmaCompany.companyData,
  (companyData) => companyData,
);

const getSubscriptionType = (state) => state.pharmaCompany.companyData.subscriptionType;

export const isCompanyBasicPlus = createSelector(
  [getSubscriptionType],
  (subscriptionType) => subscriptionType && subscriptionType.toLowerCase() === 'engage_basic_plus',
);

export const isCompanyPro = createSelector(
  [getSubscriptionType],
  (subscriptionType) => subscriptionType && subscriptionType.toLowerCase() === 'engage_pro',
);

export const getCompanySubscriptionType = createSelector(
  [getSubscriptionType, isCompanyBasicPlus, isCompanyPro],
  (subscriptionType) => {
    if (isEmpty(subscriptionType)) {
      return undefined;
    }

    if (subscriptionType && subscriptionType.toLowerCase() === 'engage_basic_plus') {
      return 'engage_basic_plus';
    }

    if (subscriptionType && subscriptionType.toLowerCase() === 'engage_pro') {
      return 'engage_pro';
    }

    if (subscriptionType && subscriptionType.toLowerCase() === 'engage_basic') {
      return 'engage_basic';
    }
  },
);
