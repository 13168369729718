import Dialog from '@material-ui/core/Dialog';
import MedButton from 'lib/elements/MedButton';
import React from 'react';
import styled from 'styled-components';
import * as palette from 'styles/palette';
import Register from '../Register';

export const MedModalDlg = styled(({ isClicked, ...props }) => <Dialog {...props} />)`
  &&& > div {
    border-radius: 0.7rem;
  }

  &&& > div > div {
    max-width: 90rem;
    height: auto;
    overflow: visible;
  }

  &&& > div > div > div > div {
    max-width: 60rem;
    width: 100%;
  }
`;

export const RegisterStyled = styled(Register)``;

export const BasicPlusInfo = styled.div`
  padding: 4rem 0rem 4rem 4rem;
`;

export const RegisterContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const RegisterWrapper = styled.div``;

export const ModalTitle = styled.div`
  font-size: 18px;
  font-weight: bolder;
  color: ${palette.primaryDarkGreen};
  line-height: 2.5rem;
`;

export const RegisterText = styled.span`
  text-align: left;
  font-size: 16px;
  font-weight: bolder;
  color: ${palette.primaryDarkGreen};
  line-height: 2.5rem;
`;

export const ConfirmationText = styled.span`
  text-align: left;
  font-size: 16px;
  font-weight: bolder;
  color: ${palette.primaryDarkGreen};
  line-height: 2.5rem;
`;

export const ProSignup = styled.div`
  padding: 4rem;
  overflow-y: scroll;
`;

export const ProInfo = styled.div`
  font-size: 1.8rem;
  color: ${palette.primaryDarkGreen};
  line-height: 3rem;
`;

export const ProDialog = styled(MedModalDlg)`
  & > div > div {
    width: ${(props) => (props.confirmPage ? 'unset' : '500px')};
  }
`;

export const StyledBtn = styled(MedButton)`
  background-color: ${palette.primaryDarkGreen} !important;
  margin-left: 120px !important;
`;
