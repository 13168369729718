import CircularProgress from '@material-ui/core/CircularProgress';
import React from 'react';
import styled from 'styled-components';
import * as palette from 'styles/palette';
import { REPORT_STATUS } from '../../constants';

export const ReportsContainer = styled.div`
  background-color: ${palette.primaryBGColor};
  max-width: 139.6rem;
  margin: auto;
  padding-top: 86px;
`;

export const ReportsMainContainer = styled.div`
  display: flex;
  padding: 0 1.4rem;
`;

export const LeftPanel = styled.div`
  height: calc(100vh - 100px);
  overflow-y: ${(props) => (props.isPlus ? 'hidden' : 'scroll')};
  border-radius: 0.7rem;
  background-color: ${palette.white};
  min-width: 49.3rem;
  min-height: ${(props) => (props.status === REPORT_STATUS.New ? '0' : 'calc(100vh - 10rem)')};
  padding: 0.2rem 10px 0px 10px;
  padding-top: inherit;
`;

export const RightPanel = styled.div`
  padding-left: 2.3rem;
  width: calc(100% - 49rem);
`;

export const NOReport = styled.div`
  padding-top: 100px;
  text-align: center;
`;

export const NOReportTxt = styled.p`
  margin: 0;
  font-size: 1.4rem;
  line-height: 1.9rem;
  color: ${palette.primaryDarkGreen};
  padding-bottom: 2.3rem;
`;
export const StyledSpinner = styled((props) => <CircularProgress {...props} />)`
  &&& {
    margin-left: 41.75em;
    padding: 0.625em;
    color: ${palette.safeGreen};
  }
`;
export const FilterContainer = styled.div`
  margin: 10px;
`;
