import styled from 'styled-components';
import * as palette from 'styles/palette';

export const ErrorTextLabel = styled.div`
  background-color: ${palette.errorBGColor};
  border-radius: 0.7rem;
  color: ${palette.white};
  padding: 0.7rem 1.3rem;
  position: absolute;
  bottom: ${(props) => (props.isChecked ? '2rem' : '1px')};
  transition: all ease-in-out 0.6s;
  left: 100%;
  width: ${(props) => (props.hasFile ? '178px' : 'auto')};
  white-space: ${(props) => (props.hasFile ? 'normal' : 'nowrap')};
  margin-left: ${(props) => (props.hasFile ? '3.7rem' : '1.1rem')};
  opacity: 0;
  opacity: ${(props) => (props.hasError ? 1 : 0)};
  box-sizing: border-box;
`;
