import { Chip, Dialog, Tab, TextField } from '@material-ui/core';
import { DeleteOutline } from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';
import Send from '@material-ui/icons/Send';
import DlgActions from 'lib/elements/DlgActions';
import Input from 'lib/elements/Input';
import InputContainer from 'lib/elements/InputContainer';
import Label from 'lib/elements/Label';
import MedButton from 'lib/elements/MedButton';
import React from 'react';
import styled from 'styled-components';
import * as palette from 'styles/palette';

export const MedModalDlg = styled(({ isClicked, ...props }) => <Dialog {...props} />)`
  &&& > div {
    border-radius: 0.7rem;
  }
`;
export const AddQuestionForm = styled.form`
  min-height: 540px;
  padding: 0 2rem 3rem 2rem;
  text-align: center;
  width: 560px;
  position: relative;
  text-align: center;
  @media (max-width: 375px) {
    padding: 0 2rem;
  }
`;

export const NoBtn = styled(MedButton)`
  &&& {
    background-color: ${palette.primaryDarkRed};
    &:hover {
      background-color: ${palette.primaryLightRed};
    }
  }
`;
export const InputContainerStyled = styled(InputContainer)`
  text-align: left;
  margin-top: 1.6rem;
`;
export const InputStyled = styled(Input)`
  margin-top: 0.5rem;
  padding: 0.7rem 1rem !important;
  border: 1px solid
    ${(props) => {
      if (props.disabled) {
        return palette.primarySuperLightGrey;
      } else if (props.isInvalid) {
        return palette.primaryLightRed;
      } else {
        return 'auto';
      }
    }};
`;
export const LabelStyled = styled(Label)`
  color: ${(props) => {
    if (props.disabled) {
      return palette.primarySuperLightGrey;
    } else if (props.isInvalid) {
      return palette.primaryLightRed;
    } else {
      return 'auto';
    }
  }};
`;

export const UpdateDataBtn = styled(MedButton)`
  &&& {
    background-color: ${(props) => !props.disabled && palette.primaryDarkGreen};
    &:hover {
      background-color: ${palette.primaryLightGreen};
    }
  }
`;

export const EditDataHeader = styled.div`
  background-color: ${palette.primaryBackground};
  border-radius: 0.7rem 0.7rem 0 0;
  height: 70px;
  width: 100%;
`;

export const FollowupTitle = styled.span`
  color: ${palette.primaryDarkGreen};
  font-weight: 500;
  font-size: 18px;
  line-height: 65px;
  margin-left: 20px;
`;

export const CloseIconStyled = styled((props) => <CloseIcon {...props} />)`
  color: ${palette.primaryDarkGreen};
  cursor: pointer;
  position: absolute;
  top: 0;
  font-size: 25px !important;
  right: 0;
  padding: 15px;
`;

export const SendStyled = styled((props) => <Send {...props} />)`
  color: ${palette.primaryLightGreen};
  margin-bottom: -5px;
  margin-left: 5px;
  font-size: 2rem !important;
`;

export const PressEnter = styled.span`
  color: ${palette.primaryLightGrey};
  cursor: pointer;
  margin-left: -100px;
`;
export const LabelChipsContainer = styled.div``;

export const ChipStyled = styled((props) => <Chip {...props} />)`
  &&& {
    border-radius: 1px;
    color: ${palette.primaryDarkGreen};
    font-weight: 400;
    margin-right: 3px;
    margin-bottom: 5px;

    & > span {
      padding-right: 3px;
      padding-left: 3px;
    }
  }
`;

export const DeleteChipIcon = styled((props) => <CloseIcon {...props} />)`
  &&& {
    color: ${palette.primaryDarkGreen};
    height: 20px;
    width: 20px;
  }
`;

export const TextFieldStyled = styled((props) => <TextField {...props} />)`
  &&& {
    & > div {
      margin-top: 10px;
      margin-bottom: 10px;
      border: 1px solid
        ${(props) =>
          props.followupLabelInvalid ? palette.primaryDarkRed : palette.primaryDarkGreen};
      border-radius: 0.7rem;
      &::active {
        box-shadow: 0 0 0 0.3rem rgba(17, 121, 130, 0.25);
      }
      &::after {
        border-bottom: 0;
      }
      & > input {
        padding: 0.7rem 1rem !important;
        font-size: 13px;
        height: 16px;
      }
      &::before {
        border-bottom: 0px;
      }
    }
  }
`;

export const MenuItem = styled((props) => <Tab {...props} />)`
  text-transform: capitalize !important;
  color: ${(props) => {
    if (props.isInvalid) {
      return palette.primaryLightRed;
    } else {
      return palette.primaryDarkGreen;
    }
  }} !important;
  font-weight: 600 !important;
  min-width: 5rem !important;
`;

export const FollowupOptionInput = styled((props) => <TextField {...props} />)`
  &&& {
    input {
      font-size: 13px !important;
      color: ${(props) =>
        props.addNewOptionField ? palette.primaryLightGrey : palette.primaryLightGreen};
      cursor: ${(props) => (props.addNewOptionField ? 'auto' : 'pointer')};
    }

    & > div {
      &::before {
        border-bottom: 1px solid ${palette.primaryLightGreen};
      }
      &::after {
        border-bottom: 2px solid ${palette.primaryDarkGreen};
      }
      &:hover {
        &::before {
          border-bottom: 2px solid ${palette.primaryLightGreen};
        }
      }
    }
  }
`;

export const InlineEditContainer = styled.div`
  color: ${palette.primaryDarkGreen};
  display: flex;
  margin-bottom: 20px;
  & > span {
    font-size: 13px !important;
    font-weight: 400;
  }
`;

export const DlgActionsStyled = styled((props) => <DlgActions {...props} />)`
  &&& {
    width: inherit;
    margin: 0px 20px 20px 20px;
  }
`;
export const QuestionPreview = styled.span`
  font-weight: 600;
  font-size: 16px;
  color: #bdbdbd;
`;
export const PreviewContainerStyled = styled.div`
  min-height: 175px;
`;

export const ErrorMsg = styled.span`
  color: ${palette.primaryLightRed};
  float: left;
  font-size: 14px;
`;
export const UploadFileBtn = styled(MedButton)`
  &&& {
    width: 165px;
    background-color: ${palette.white} !important;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    color: ${palette.primaryDarkGreen};
    border: 1px solid ${palette.primaryDarkGreen};
    font-weight: 600;
    z-index: 9;
    color: ${(props) => (props.disabled ? palette.primaryLightGrey : 'auto')};
    &:hover {
      background-color: ${palette.primaryLightGreen};
    }
  }
`;

export const FileUploadContainer = styled.div`
  margin-top: 10px;
  display: flex;
`;

export const FileUploadInfo = styled.span`
  margin-left: 10px;
  color: ${palette.primaryDarkGreen};
  font-weight: bold;
  font-size: 11px;
  align-self: center;
`;

export const FileUploadErr = styled.span`
  margin-left: 10px;
  color: ${palette.primaryLightRed};
  font-weight: 600;
  font-size: 11px;
  align-self: center;
`;

export const InfoIconContainer = styled.span`
  vertical-align: middle;
  margin-right: 5px;
  & > svg {
    font-size: 17px;
  }
`;
export const ProgressBarContainer = styled.div`
  width: 200px;
  align-self: center;
  margin-left: 10px;
`;

export const DeleteIcon = styled((props) => <DeleteOutline {...props} />)`
  &&& {
    align-self: center;
    font-size: 20px;
    color: ${palette.primaryLightGrey};
    margin-left: 10px;
    &:hover {
      cursor: pointer;
    }
  }
`;
