import moment from 'moment';
import {
  AnswerBox,
  AnswerDate,
  AnswerSecondary,
  LeftQuestionContainer,
  LongAnswer,
  Question,
  QuestionBox,
  QuestionDate,
  QuestionTitle,
  QuestionsContainer,
  QuestionsContent,
  Reminder,
  SecondaryAnswerBox,
  SecondaryBtn,
} from './style';

import QuestionAnswerOutlinedIcon from '@material-ui/icons/QuestionAnswerOutlined';
import { interactWithPatientAPI } from 'api';
import I18n from 'i18n';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { CARD_TYPE } from '../../../constants';
import QuestionsModal from './QuestionsModal';

import { DOWNLOAD_FILE } from 'actions';

import FileDownload from 'components/FileDownload';

import { getUserToken } from 'selectors/user';
import { withMedTracker } from 'services/tracking';

class QuestionAnswerContainer extends Component {
  static propTypes = {
    report: PropTypes.object.isRequired,
    onShowTestProModal: PropTypes.func,
    onShowRegisterModal: PropTypes.func,
    onUpdateReport: PropTypes.func.isRequired,
    proOffer: PropTypes.string,
  };

  state = {
    isAskingQuestions: false,
  };

  interactWithPatient = async (questions) => {
    const reportId = this.props.report.metaData.id;

    const res = await interactWithPatientAPI(
      reportId,
      questions,
      'toPatient',
      this.props.userToken,
    );

    questions.forEach((question) => {
      // TODO:: Understand the tracking requirement in details
      // clear requirements on event-category, event-action, event-name
      this.props.MedTrackEvent('Followups', question.id ? 'remind' : 'ask', question.type || '', 1);
    });
    this.props.onUpdateReport(reportId, false, res.data.nextExpectedVersion);
  };

  handleAvailableSoon = () => {
    const { proOffer } = this.props;
    if (proOffer !== CARD_TYPE.Pro) {
      this.setState(() => {
        this.props.onShowTestProModal();
      });
    }
  };

  handleFileDownload = (fileId, templateId) => {
    this.props.MedTrackEvent('Followups', 'answerFile', 'download', 1);

    return this.props.downloadFile(fileId, templateId, this.props.userToken);
  };

  compareBasedOnAnsweredTime = (questionA, questionB) => {
    const lastUpdatedA = _.get(questionA, 'answer.createdAt', questionA.createdAt);
    const lastUpdatedB = _.get(questionB, 'answer.createdAt', questionB.createdAt);
    return (
      moment(lastUpdatedB, 'YYYY-MM-DD HH:mm:ss').toDate() -
      moment(lastUpdatedA, 'YYYY-MM-DD HH:mm:ss').toDate()
    );
  };

  render() {
    const { report, onShowTestProModal, onShowRegisterModal } = this.props;
    const { isAskingQuestions } = this.state;
    const QUESTIONS = {
      'question-drug-dosage': I18n.t(
        'components.ReportDetail.QuestionAnswerContainer.questions.questionDrugDosage',
      ),
      'question-drug-indication': I18n.t(
        'components.ReportDetail.QuestionAnswerContainer.questions.questionDrugIndication',
      ),
      'question-effect-status': I18n.t(
        'components.ReportDetail.QuestionAnswerContainer.questions.questionEffectStatus',
      ),
      'question-drug-batch-number': I18n.t(
        'components.ReportDetail.QuestionAnswerContainer.questions.questionDrugBatchNumber',
      ),
      'question-drug-action': I18n.t(
        'components.ReportDetail.QuestionAnswerContainer.questions.questionDrugAction',
      ),
      'question-contact-details': I18n.t(
        'components.ReportDetail.QuestionAnswerContainer.questions.questionContactDetails',
      ),
    };
    // t('questions.follow_up', { returnObjects: true })
    const reportArchived =
      _.get(this, 'props.report.metaData.pharmaStatus', '') === 'pharma-archived';

    // sort questions based on time asked and time answered
    const unorderedQuestions = _.get(report, 'metaData.questions.fromPharma.toPatient', []);
    const orderedQuestions = unorderedQuestions.sort(this.compareBasedOnAnsweredTime);

    return (
      <QuestionsContainer>
        <QuestionsModal
          report={report}
          isOpen={isAskingQuestions}
          onClose={() => this.setState({ isAskingQuestions: false })}
          interactWithPatient={this.interactWithPatient}
          onShowTestProModal={onShowTestProModal}
          onShowRegisterModal={onShowRegisterModal}
          orderedQuestions={orderedQuestions}
        />
        <LeftQuestionContainer>
          <QuestionTitle>
            {I18n.t('components.ReportDetail.QuestionAnswerContainer.title')}
          </QuestionTitle>
          {unorderedQuestions.length ? (
            <QuestionsContent>
              {_.map(orderedQuestions, (question, key) => {
                return (
                  <div key={key}>
                    <QuestionBox>
                      <QuestionDate>
                        {question.createdAt &&
                          moment(question.createdAt).format('YYYY | DD.MM | HH:mm')}
                      </QuestionDate>
                      <Question>{QUESTIONS[question.type] || question.text}</Question>
                    </QuestionBox>

                    {'reminders' in question &&
                      // TODO: hotfix to hide extra reminders
                      _.map(
                        question.reminders.reduce((acc, val, index, arr) => {
                          const isAutomaticReminder = val.initiator.role === 'system';
                          if (
                            index === 0 ||
                            !(
                              isAutomaticReminder &&
                              new Date(
                                new Date(arr[index - 1].createdAt).getTime() + 1000 * 60 * 61,
                              ) > new Date(val.createdAt)
                            )
                          ) {
                            acc.push(val);
                          }
                          return acc;
                        }, []),
                        (reminder) => {
                          return (
                            <QuestionBox>
                              <QuestionDate>
                                {reminder.createdAt &&
                                  moment(reminder.createdAt).format('YYYY | DD.MM | HH:mm')}
                              </QuestionDate>
                              {reminder.initiator.role === 'pharma' ? (
                                <Reminder>
                                  {I18n.t(
                                    'components.ReportDetail.QuestionAnswerContainer.reminder1',
                                  )}
                                </Reminder>
                              ) : (
                                <Reminder>
                                  {I18n.t(
                                    'components.ReportDetail.QuestionAnswerContainer.reminder2',
                                  )}
                                </Reminder>
                              )}
                            </QuestionBox>
                          );
                        },
                      )}
                    {question.answer ? (
                      <AnswerBox>
                        <QuestionDate />
                        <AnswerSecondary>
                          {I18n.t('components.ReportDetail.QuestionAnswerContainer.answerRecieved')}
                          <span />
                          {!['custom', 'template'].includes(question.type) &&
                            I18n.t('components.ReportDetail.QuestionAnswerContainer.reportUpdated')}
                          <span />
                        </AnswerSecondary>
                        <SecondaryAnswerBox>
                          <AnswerDate>
                            {question.answer.createdAt &&
                              moment(question.answer.createdAt).format('YYYY | DD.MM | HH:mm')}
                          </AnswerDate>
                          <LongAnswer>
                            {question.answer.fileId && question.answer.fileId !== '' ? (
                              <FileDownload
                                fileId={question.answer.fileId}
                                fileName={question.answer.text}
                                templateId={question.templateId}
                                onFileDownload={this.handleFileDownload}
                              />
                            ) : (
                              question.answer.text
                            )}
                          </LongAnswer>
                        </SecondaryAnswerBox>
                      </AnswerBox>
                    ) : (
                      <AnswerBox>
                        <QuestionDate />
                        <AnswerSecondary>
                          {I18n.t('components.ReportDetail.QuestionAnswerContainer.answerPending')}
                        </AnswerSecondary>
                      </AnswerBox>
                    )}
                  </div>
                );
              })}
            </QuestionsContent>
          ) : (
            // {/* Not implmented yet
            // <SecondaryBtn>
            //   <NotificationsIcon fontSize={"small"}/>
            //   {t("questions.send_reminder")}
            // </SecondaryBtn>
            // */}
            <QuestionsContent>
              {I18n.t('components.ReportDetail.QuestionAnswerContainer.none')}
            </QuestionsContent>
          )}
          <SecondaryBtn
            data-tip=""
            data-for="report_archived"
            data-tip-disable={!reportArchived}
            isDisabled={reportArchived}
            onClick={() => !reportArchived && this.setState({ isAskingQuestions: true })}
          >
            <QuestionAnswerOutlinedIcon fontSize="small" />
            {I18n.t('components.ReportDetail.QuestionAnswerContainer.ask')}
          </SecondaryBtn>
          <ReactTooltip type="dark" effect="solid" place="right" id="report_archived">
            {I18n.t('components.ReportDetail.QuestionAnswerContainer.tooltip')}
          </ReactTooltip>
        </LeftQuestionContainer>
        {/* <RightQuestionContainer isDisabled={proOffer !== CARD_TYPE.Pro}>
          <QuestionTitle>{t('questions.header_doc')}</QuestionTitle>
          {
            <div>
              <QuestionsContent>
                {t('questions.not_implemented')}
              </QuestionsContent>
              <span>
                <SecondaryBtn onClick={this.handleAvailableSoon}>
                  <AccessTimeIcon fontSize={'small'} />
                  {t('questions.not_implemented_short')}
                </SecondaryBtn>
              </span>
            </div>
          }
        </RightQuestionContainer> */}
      </QuestionsContainer>
    );
  }
}

function mapStateToProps(state, props) {
  const { proOffer } = state.proOffer;
  return { proOffer, userToken: getUserToken(state) };
}

function mapDispatchToProps(dispatch) {
  return {
    downloadFile: async (fileId, templateId, token) => {
      const response = await DOWNLOAD_FILE(fileId, templateId, token);
      return dispatch(response);
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withMedTracker(QuestionAnswerContainer));
