import { eventstoreInstanceAxios as eventstore } from '../config';

/**
 * Send openReport request, which allows to mark a report as opened
 * @param {*} reportId
 */

export async function openReportAPI(reportId, token) {
  const config = {
    url: `/report/${reportId}/openReport`,
    method: 'post',
    headers: { Authorization: token },
  };

  return eventstore(config);
}
