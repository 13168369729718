import { identityInstanceAxios as identity } from '../config';

const USER_INFO_API_URL = '/pharma/details';
const USER_COMPANY_INFO_API_URL = '/pharmaCompany/info';
const USER_DATA_UPDATE = '/pharma/';

/**
 * Password reset request using AJAX call
 *
 * @returns {Promise} - Result of ajax call
 */
export async function getUserData(authToken) {
  const config = {
    url: USER_INFO_API_URL,
    method: 'get',
    headers: { Authorization: authToken },
  };
  return identity(config);
}

export async function updateUserData(data, token) {
  const config = {
    url: USER_DATA_UPDATE,
    method: 'patch',
    headers: { Authorization: token },
    data: data,
  };

  return identity(config);
}

export async function getUserCompanyData(token) {
  const config = {
    url: USER_COMPANY_INFO_API_URL,
    method: 'get',
    headers: { Authorization: token },
  };

  return identity(config);
}
