import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import './index.scss';
import 'styles/global';
import './i18n';
import * as Sentry from '@sentry/browser';
import config from 'config';
import React from 'react';
import { render } from 'react-dom';
import { history } from 'routing';
import { configureStore } from 'store';
import Root from './components/Root';

Sentry.init({
  dsn: config.sentryDSN,
  environment: process.env.NODE_ENV,
  ...(process.env.NODE_ENV !== 'development' && {
    release: 'med-app-pharma@' + process.env.REACT_APP_CI_COMMIT_REF_NAME,
  }),
});

const { store, persistor } = configureStore();

const root = <Root history={history} store={store} persistor={persistor} />;

render(root, document.getElementById('root'));
