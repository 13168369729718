import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import I18n from 'i18n';
import React, { Component } from 'react';
import DropDownMenu from '../../../../../DropDownMenu';
import {
  Asterisk,
  GenderBtn,
  GenderBtnGroup,
  InputBox,
  InputBoxForOpacity,
  InputLabel,
  MenuBtn,
} from './style';

const menuList = [
  I18n.t(
    'components.ReportDetail.DetailContainer.RightDetailView.PatientDataModal.PatientModalContent.notpregnant',
  ),
  I18n.t(
    'components.ReportDetail.DetailContainer.RightDetailView.PatientDataModal.PatientModalContent.week1',
  ),
  I18n.t(
    'components.ReportDetail.DetailContainer.RightDetailView.PatientDataModal.PatientModalContent.week2',
  ),
  I18n.t(
    'components.ReportDetail.DetailContainer.RightDetailView.PatientDataModal.PatientModalContent.week3',
  ),
  I18n.t(
    'components.ReportDetail.DetailContainer.RightDetailView.PatientDataModal.PatientModalContent.week4',
  ),
  I18n.t(
    'components.ReportDetail.DetailContainer.RightDetailView.PatientDataModal.PatientModalContent.week5',
  ),
  I18n.t(
    'components.ReportDetail.DetailContainer.RightDetailView.PatientDataModal.PatientModalContent.week6',
  ),
  I18n.t(
    'components.ReportDetail.DetailContainer.RightDetailView.PatientDataModal.PatientModalContent.week7',
  ),
  I18n.t(
    'components.ReportDetail.DetailContainer.RightDetailView.PatientDataModal.PatientModalContent.week8',
  ),
];
const Genders = {
  man: I18n.t(
    'components.ReportDetail.DetailContainer.RightDetailView.PatientDataModal.PatientModalContent.man',
  ),
  woman: I18n.t(
    'components.ReportDetail.DetailContainer.RightDetailView.PatientDataModal.PatientModalContent.woman',
  ),
  indifferent: I18n.t(
    'components.ReportDetail.DetailContainer.RightDetailView.PatientDataModal.PatientModalContent.indifferent',
  ),
};
class PatientModalContent extends Component {
  state = {
    anchorEl: null,
    tagName: I18n.t(
      'components.ReportDetail.DetailContainer.RightDetailView.PatientDataModal.PatientModalContent.notpregnant',
    ),
    gender: '',
  };

  handleClose = (item) => {
    this.setState({
      anchorEl: null,
      tagName: item !== 'close' ? item : this.state.tagName,
    });
  };

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleSetGender = (gender) => {
    this.setState({ gender });
  };

  render() {
    const { anchorEl, tagName, gender } = this.state;
    return (
      <div>
        <InputLabel>
          {I18n.t(
            'components.ReportDetail.DetailContainer.RightDetailView.PatientDataModal.PatientModalContent.label1',
          )}
        </InputLabel>
        <InputBoxForOpacity placeholder="Bitte Patientennamen eingeben" />
        <InputLabel>
          {I18n.t(
            'components.ReportDetail.DetailContainer.RightDetailView.PatientDataModal.PatientModalContent.label2',
          )}
          <Asterisk>*</Asterisk>
        </InputLabel>
        <InputBox
          placeholder={I18n.t(
            'components.ReportDetail.DetailContainer.RightDetailView.PatientDataModal.PatientModalContent.placeholder1',
          )}
        />
        <InputLabel>
          {I18n.t(
            'components.ReportDetail.DetailContainer.RightDetailView.PatientDataModal.PatientModalContent.label3',
          )}
        </InputLabel>
        <InputBox
          placeholder={I18n.t(
            'components.ReportDetail.DetailContainer.RightDetailView.PatientDataModal.PatientModalContent.placeholder2',
          )}
        />
        <InputLabel>
          {I18n.t(
            'components.ReportDetail.DetailContainer.RightDetailView.PatientDataModal.PatientModalContent.label4',
          )}
        </InputLabel>
        <InputBox
          placeholder={I18n.t(
            'components.ReportDetail.DetailContainer.RightDetailView.PatientDataModal.PatientModalContent.placeholder3',
          )}
        />
        <InputLabel>
          {I18n.t(
            'components.ReportDetail.DetailContainer.RightDetailView.PatientDataModal.PatientModalContent.label5',
          )}
          <Asterisk>*</Asterisk>
        </InputLabel>
        <GenderBtnGroup>
          <GenderBtn
            isActive={gender === Genders.man}
            onClick={() => this.handleSetGender(Genders.man)}
          >
            {I18n.t(
              'components.ReportDetail.DetailContainer.RightDetailView.PatientDataModal.PatientModalContent.man',
            )}
            <svg
              fill="none"
              height="14"
              isActive={gender === Genders.man}
              viewBox="0 0 13 14"
              width="13"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                fill="black"
                fillOpacity="0"
                height="13"
                transform="translate(0 0.699707)"
                width="13"
              />
              <path
                d="M5.18057 13.6998C3.79678 13.6998 2.49569 13.1716 1.51766 12.2137C0.539078 11.2555 0 9.98157 0 8.62635C0 7.27141 0.539078 5.99717 1.51794 5.03898C2.49624 4.08079 3.79705 3.55322 5.18112 3.55322C6.56463 3.55322 7.86627 4.08079 8.84458 5.03898C9.82344 5.99689 10.3625 7.27114 10.3625 8.62608C10.3625 9.98157 9.82344 11.2555 8.84458 12.2137C7.86572 13.1716 6.56463 13.6998 5.18057 13.6998ZM5.18112 4.55149C4.06922 4.55149 3.02453 4.97506 2.23873 5.74503C1.45238 6.51472 1.01952 7.5379 1.01952 8.62635C1.01952 9.71481 1.45238 10.738 2.23873 11.508C3.02453 12.2776 4.0695 12.7015 5.18084 12.7015C6.29247 12.7015 7.33743 12.2774 8.1235 11.5082C8.90985 10.738 9.34272 9.71454 9.34272 8.62608C9.34272 7.53763 8.90985 6.51445 8.1235 5.74503C7.33715 4.97506 6.29247 4.55149 5.18112 4.55149Z"
                fill="#12A5A9"
              />
              <path
                d="M8.66994 5.68336L11.8148 2.60378L11.9816 5.44151C11.9973 5.70529 12.22 5.91194 12.4899 5.91194C12.4924 5.91194 12.5176 5.91139 12.5195 5.91112C12.8 5.89541 13.0149 5.65898 12.9992 5.38409L12.7674 1.43462C12.7654 1.4024 12.7571 1.37585 12.7502 1.35635L12.7436 1.329C12.7405 1.31329 12.7367 1.29163 12.7256 1.26725C12.7002 1.2093 12.6659 1.15838 12.6235 1.11559C12.6222 1.11369 12.6205 1.11207 12.6188 1.11017C12.6141 1.1053 12.6072 1.10015 12.6003 1.09528C12.5585 1.0579 12.5112 1.02811 12.4609 1.00672C12.4355 0.995884 12.4125 0.99128 12.3956 0.988301L12.3688 0.98153C12.35 0.975572 12.324 0.967176 12.2922 0.96528L8.22158 0.701224C7.93891 0.679558 7.69689 0.892699 7.67836 1.16623C7.66951 1.29948 7.71376 1.42785 7.80365 1.52806C7.89327 1.62854 8.01746 1.68866 8.15327 1.69733L11.1034 1.8888L7.94914 4.97731C7.85289 5.07183 7.79978 5.19695 7.79978 5.3302"
                fill="#12A5A9"
              />
            </svg>
          </GenderBtn>
          <GenderBtn
            isActive={gender === Genders.woman}
            onClick={() => this.handleSetGender(Genders.woman)}
          >
            {I18n.t(
              'components.ReportDetail.DetailContainer.RightDetailView.PatientDataModal.PatientModalContent.woman',
            )}
            <svg
              fill="none"
              height="16"
              isActive={gender === Genders.woman}
              viewBox="0 0 11 16"
              width="11"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.09998 9.81056C6.09998 9.53442 5.87612 9.31056 5.59998 9.31056C5.32383 9.31056 5.09998 9.53442 5.09998 9.81056H6.09998ZM5.09998 14.6997C5.09998 14.9758 5.32383 15.1997 5.59998 15.1997C5.87612 15.1997 6.09998 14.9758 6.09998 14.6997H5.09998ZM3.43176 12.032C3.15562 12.032 2.93176 12.2559 2.93176 12.532C2.93176 12.8082 3.15562 13.032 3.43176 13.032V12.032ZM7.77502 13.032C8.05116 13.032 8.27502 12.8082 8.27502 12.532C8.27502 12.2559 8.05116 12.032 7.77502 12.032V13.032ZM9.59998 5.0942C9.59998 7.23396 7.82036 8.9887 5.59998 8.9887V9.9887C8.35015 9.9887 10.6 7.80848 10.6 5.0942H9.59998ZM5.59998 8.9887C3.37959 8.9887 1.59998 7.23396 1.59998 5.0942H0.599976C0.599976 7.80848 2.8498 9.9887 5.59998 9.9887V8.9887ZM1.59998 5.0942C1.59998 2.95445 3.37959 1.19971 5.59998 1.19971V0.199707C2.8498 0.199707 0.599976 2.37993 0.599976 5.0942H1.59998ZM5.59998 1.19971C7.82036 1.19971 9.59998 2.95445 9.59998 5.0942H10.6C10.6 2.37993 8.35015 0.199707 5.59998 0.199707V1.19971ZM5.09998 9.81056V14.6997H6.09998V9.81056H5.09998ZM3.43176 13.032H7.77502V12.032H3.43176V13.032Z"
                fill="#12A5A9"
              />
            </svg>
          </GenderBtn>
          <GenderBtn
            isActive={gender === Genders.indifferent}
            onClick={() => this.handleSetGender(Genders.indifferent)}
          >
            {I18n.t(
              'components.ReportDetail.DetailContainer.RightDetailView.PatientDataModal.PatientModalContent.indifferent',
            )}
            <svg
              fill="none"
              height="16"
              isActive={gender === Genders.indifferent}
              viewBox="0 0 17 16"
              width="17"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16.3555 0H14.0888C13.8444 0 13.6444 0.2 13.6444 0.444444C13.6444 0.688889 13.8444 0.888889 14.0888 0.888889H15.2666L13.1777 3C12.3999 2.35556 11.4221 1.97778 10.3333 1.97778C9.19993 1.97778 8.1777 2.4 7.39993 3.08889C6.7777 2.75556 6.06659 2.55556 5.28882 2.55556C2.82215 2.55556 0.799927 4.55556 0.799927 7.04444C0.799927 9.37778 2.5777 11.2889 4.84437 11.5111V13.4889H3.86659C3.62215 13.4889 3.42215 13.6889 3.42215 13.9333C3.42215 14.1778 3.62215 14.3778 3.86659 14.3778H4.84437V15.5556C4.84437 15.8 5.04437 16 5.28882 16C5.53326 16 5.73326 15.8 5.73326 15.5556V14.3778H6.71104C6.95548 14.3778 7.15548 14.1778 7.15548 13.9333C7.15548 13.6889 6.95548 13.4889 6.71104 13.4889H5.73326V11.5111C6.68882 11.4222 7.53326 11.0222 8.22215 10.4222C8.84437 10.7556 9.55548 10.9556 10.3333 10.9556C12.7999 10.9556 14.8221 8.93333 14.8221 6.46667C14.8221 5.37778 14.4444 4.4 13.7999 3.62222L15.911 1.51111V2.68889C15.911 2.93333 16.111 3.13333 16.3555 3.13333C16.5999 3.13333 16.7999 2.93333 16.7999 2.68889V0.444444C16.7999 0.2 16.5999 0 16.3555 0ZM5.28882 10.6444C3.31104 10.6444 1.68882 9.02222 1.68882 7.04444C1.68882 5.06667 3.31104 3.44444 5.28882 3.44444C5.82215 3.44444 6.31104 3.55556 6.75548 3.75556C6.1777 4.51111 5.84437 5.44444 5.84437 6.46667C5.84437 7.84444 6.46659 9.08889 7.46659 9.91111C6.86659 10.3778 6.11104 10.6444 5.28882 10.6444ZM8.11104 9.28889C7.26659 8.62222 6.73326 7.6 6.73326 6.46667C6.73326 5.62222 7.02215 4.84444 7.53326 4.22222C8.3777 4.88889 8.91104 5.91111 8.91104 7.04444C8.88882 7.88889 8.59993 8.66667 8.11104 9.28889ZM10.3333 10.0667C9.79993 10.0667 9.31104 9.95556 8.86659 9.75556C9.44437 9 9.77771 8.06667 9.77771 7.04444C9.77771 5.66667 9.15548 4.42222 8.15548 3.6C8.75548 3.13333 9.51104 2.86667 10.3333 2.86667C12.311 2.86667 13.9333 4.48889 13.9333 6.46667C13.9333 8.44444 12.3333 10.0667 10.3333 10.0667Z"
                fill="#12A5A9"
              />
            </svg>
          </GenderBtn>
        </GenderBtnGroup>
        <InputLabel>
          {I18n.t(
            'components.ReportDetail.DetailContainer.RightDetailView.PatientDataModal.PatientModalContent.label6',
          )}
        </InputLabel>
        <InputBoxForOpacity placeholder="Bitte in diesem Format eingeben: beispiel@beispiel.com" />
        <InputLabel>
          {I18n.t(
            'components.ReportDetail.DetailContainer.RightDetailView.PatientDataModal.PatientModalContent.label7',
          )}
        </InputLabel>
        <MenuBtn
          aria-haspopup="true"
          aria-owns={anchorEl ? 'week-menu' : null}
          hasError={false}
          id="week-btn"
          isDefault={
            tagName ===
            I18n.t(
              'components.ReportDetail.DetailContainer.RightDetailView.PatientDataModal.PatientModalContent.notpregnant',
            )
          }
          onClick={this.handleClick}
        >
          <span>{tagName}</span>
          <ArrowDropDownIcon />
        </MenuBtn>
        <DropDownMenu
          anchorEl={anchorEl}
          buttonId="week-btn"
          dropdownList={menuList}
          menuId="week-menu"
          onHandleClose={this.handleClose}
        />
      </div>
    );
  }
}

export default PatientModalContent;
