import background from 'assets/img/medikura-background.png';
import Login from 'components/Login';
import I18n from 'i18n';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Trans, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { isUserLoggedIn } from 'selectors/user';
import TermsAndConditions from '../../components/TermsAndConditions';
import {
  ErrorMessageContainer,
  ErrorMessageTitle,
  LoginFormWrapper,
  LoginHeader,
  LoginWrapper,
} from './style';

class LoginContainer extends Component {
  componentWillMount() {
    const { userLoggedIn } = this.props;

    // user is already logged In hence redirecting to reports, if you want to force login dispatch the logout first
    if (userLoggedIn) {
      this.props.history.push('/reports');
    }
  }

  render() {
    return (
      <>
        <LoginWrapper>
          <LoginHeader isLoginPage />
          <LoginFormWrapper imageUrl={background}>
            {!this.props.tokenExpiredError && !this.props.invalidTokenError && <Login />}
            {this.props.tokenExpiredError && (
              <ErrorMessageContainer>
                <Trans i18nKey="containers.LoginContainer.tokenExpiredError">
                  <br />
                  <strong />
                </Trans>
              </ErrorMessageContainer>
            )}
            {this.props.invalidTokenError && (
              <ErrorMessageContainer>
                <ErrorMessageTitle>
                  {I18n.t('containers.LoginContainer.errorTitle')}
                </ErrorMessageTitle>
                <Trans i18nKey="containers.LoginContainer.invalidTokenError">
                  <strong />
                </Trans>
              </ErrorMessageContainer>
            )}
          </LoginFormWrapper>
          <TermsAndConditions isPro />
        </LoginWrapper>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { tokenExpiredError, invalidTokenError } = state.route;

  return {
    userLoggedIn: isUserLoggedIn(state),
    tokenExpiredError,
    invalidTokenError,
  };
};

LoginContainer.propTypes = {
  userLoggedIn: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(withTranslation()(LoginContainer));
