module.exports = {
  // Primary colors
  navy: '#4B6075',
  teal: '#92C5B8',
  peach: '#FBCCB0',
  apple: '#EE6869',
  charcoal: '#969495',
  blueGrey: '#37404d',
  orange: '#e36b2b',
  yellow: '#F2C94C',
  shinyGreen: '#69FF5C',
  green: '#27AE60',
  coldBlue: '#ACDFE3',
  lightRose: '#e3d2da',
  gray: '#E5E5E5',
  safeGreen: '#a3bab7',
  grey: '#616771',

  // Secondary colors
  plum: '#C3D1EC',
  wood: '#99827A',
  chalk: '#E4E2D9',
  whisper: '#F8F8F8',

  // Specific colors
  primaryBackground: '#E7EEEF',
  PrimaryPaleOrange: '#fff8ea',
  white: '#FFF',
  black: '#000',
  primaryDarkGreen: '#117982',
  primaryLightGreen: '#12A5A9',
  primaryVeryLightGreen: '#e9f6f7',
  primaryLightRed: '#CC0033',
  primaryLightOrange: '#edbc68',
  primaryCarmineRed: '#8e2414',
  primaryDarkRed: '#8E1441',
  primarySuperLightGrey: '#BDBDBD',
  primarySuperLightGreen: '#BBD8DB',
  primaryLightGrey: '#828282',
  primaryDarkGrey: '#4F4F4F',
  menuBorder: '#E0E0E0',
  errorBGColor: '#EB5757',
  pwdBGColor: '#333333',
  lineBGColor: '#F2F2F2',
  dlgFontColor: '#999998',
  rowActiveColor: '#95C3C7',
  tableHeaderColor: '#4A4A4A',
  tableBodyColor: '#4F4F4F',
  unknownBGColor: '#F3F8F9',
  unknownBGColorAlpha: 'rgba(243, 248, 249, 0.8)',
  textAreaColor: '#DADADA',
  freeColor: '#24848C',
  roleColor: '#5D5D5D',
  roleBorderColor: '#D8D8D8',
  errorRedColor: '#FF2C2C',
  errorOrangeColor: '#E67B42',
};
