// import Dialog from '@material-ui/core/Dialog'
import Tabs from '@material-ui/core/Tabs';
import { UPDATE_USER_DATA, UPDATE_USER_DATA_PENDING } from 'actions';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
// import validator from 'validator'
import { connect } from 'react-redux';
import { getUserDetails } from 'selectors/user';
import * as palette from 'styles/palette';
import MyProfile from './MyProfile';

import {
  AvatarWrapper,
  CloseIconStyled,
  DialogStyled,
  EditDataForm,
  EditDataHeader,
  MenuItem,
  ProfileInfo,
  StyledAvatar,
  // ChangeColour,
  UserName,
  UserRole,
  UserTitle,
} from './style';

function TabPanel(props) {
  switch (props.value) {
    case 'myprofile':
      return <MyProfile onClose={props.onClose} />;

    // case 'notifications':
    // return (<Notifications />)

    default:
      return <MyProfile onClose={props.onClose} />;
  }
}

class UserDataModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeMenu: 'myprofile',
    };
  }

  handleSendUserdataBtn = () => {};

  onCancel = () => {};

  render() {
    let { _id, role, position, name } = {
      name: { firstName: '', lastName: '' },
    };
    let { firstName, lastName } = name;

    if (Object.keys(this.props.userData).length) {
      ({ _id, role, position, name } = this.props.userData);
      ({ firstName, lastName } = name);
    }

    return (
      <DialogStyled
        aria-describedby="alert-dialog-description"
        aria-labelledby="alert-dialog-title"
        open={this.props.open}
        onBackdropClick={this.props.onCancel}
      >
        <EditDataForm>
          <EditDataHeader>
            <AvatarWrapper>
              <StyledAvatar userID={_id}>
                <span>
                  {firstName.slice(0, 1)}
                  {lastName.slice(0, 1)}
                </span>
              </StyledAvatar>
            </AvatarWrapper>
            <ProfileInfo>
              <UserName>
                {firstName} {lastName}
              </UserName>
              <CloseIconStyled style={{ fontSize: 31 }} onClick={this.props.onCancel} />
              <UserTitle>{position}</UserTitle>
              {/* <ChangeColour> Ändern </ChangeColour> */}
              <UserRole>{role}</UserRole>
            </ProfileInfo>
          </EditDataHeader>
          <div style={{ clear: 'both' }}>
            <Tabs
              onChange={this.handleSettingsMenuChange}
              value={this.state.activeMenu}
              TabIndicatorProps={{
                style: { backgroundColor: palette.primaryDarkGreen },
              }}
            >
              <MenuItem label="Mein Profil" value="myprofile" />
              <MenuItem label="Notifications" value="notifications" />
            </Tabs>
          </div>
          <TabPanel value={this.state.activeMenu} onClose={this.props.onCancel} />
        </EditDataForm>
      </DialogStyled>
    );
  }
}

UserDataModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return { userData: getUserDetails(state) };
}

function mapDispatchToProps(dispatch) {
  return {
    getUserData: async () => {
      dispatch({ type: UPDATE_USER_DATA_PENDING });
      const UserData = await UPDATE_USER_DATA();
      return dispatch(UserData);
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserDataModal);
