import styled from 'styled-components';
import * as palette from 'styles/palette';

export const CompanyContainer = styled.div`
  background-color: ${palette.primaryBackground};
  padding: 2rem;
  color: ${palette.tableHeaderColor};
  line-height: 2.6em;
  border-radius: 7px;
`;

export const PropertyLabel = styled.span`
  font-weight: bold;
  line-height: 2em;
  font-size: 1.3rem;
  color: ${palette.primaryDarkGreen};
`;

export const PropertyValue = styled.span`
  font-size: 1.3rem;
`;
