import React, { Component } from 'react';
import Confetti from 'react-confetti';

import { withTranslation } from 'react-i18next';
import { Container, Goodspeed, RocketLogo, Timer } from './style';

class Launch extends Component {
  constructor() {
    super();
    this.state = {
      timestamp: 1,
    };
  }

  componentDidMount() {
    setInterval(this.timer, 1000);
  }

  timer = () => {
    if (this.state.timestamp > 0) {
      this.setState({ timestamp: this.state.timestamp - 1 });
    }
  };

  render() {
    return (
      <Container>
        <Confetti opacity={!this.state.timestamp} />
        <Goodspeed>Godspeed!</Goodspeed>
        <br />
        <br />
        <Timer>00:0{this.state.timestamp}</Timer>
        <RocketLogo>🚀</RocketLogo>
      </Container>
    );
  }
}

export default withTranslation()(Launch);
