import { getUserCompanyData, getUserData, updateUserData } from 'api/UserData';
import { SET_PROOFFER } from '../../actions';
import { CARD_TYPE } from '../../constants';

// Define action types
export const GET_USER_DATA_SUCCESS = 'GET_USER_DATA_SUCCESS';
export const GET_USER_DATA_FAIL = 'GET_USER_DATA_FAIL';
export const GET_USER_DATA_PENDING = 'GET_USER_DATA_PENDING';

export const UPDATE_USER_DATA_SUCCESS = 'UPDATE_USER_DATA_SUCCESS';
export const UPDATE_USER_DATA_FAIL = 'UPDATE_USER_DATA_FAIL';
export const UPDATE_USER_DATA_PENDING = 'UPDATE_USER_DATA_PENDING';

export const GET_USER_COMPANY_DATA_SUCCESS = 'GET_USER_COMPANY_DATA_SUCCESS';
export const GET_USER_COMPANY_DATA_FAIL = 'GET_USER_COMPANY_DATA_FAIL';
export const GET_USER_COMPANY_DATA_PENDING = 'GET_USER_COMPANY_DATA_PENDING';

export const GET_USER_RESET = 'GET_USER_RESET';

export const GET_USER_DATA = async (params) => {
  try {
    const res = await getUserData(params);
    if (res && res.data) {
      return {
        type: GET_USER_DATA_SUCCESS,
        payload: res.data,
      };
    } else {
      return {
        type: GET_USER_DATA_PENDING,
        payload: res.data,
      };
    }
  } catch (err) {
    return {
      type: GET_USER_DATA_FAIL,
      payload: err.response && err.response.data,
    };
  }
};

export const UPDATE_USER_DATA = async (userData) => {
  try {
    const res = await updateUserData(userData);
    if (res && res.data) {
      return {
        type: UPDATE_USER_DATA_SUCCESS,
        payload: res.data,
      };
    } else {
      return {
        type: UPDATE_USER_DATA_PENDING,
        payload: res.data,
      };
    }
  } catch (err) {
    return {
      type: UPDATE_USER_DATA_FAIL,
      payload: err.response && err.response.data,
    };
  }
};

export const GET_USER_COMPANY_DATA = async (params) => async (dispatch) => {
  try {
    const res = await getUserCompanyData(params);
    if (res && res.data) {
      const subscriptionType = res.data.subscriptionType;
      let subscriptionToSend = '';
      if (subscriptionType === 'engage_pro') {
        subscriptionToSend = CARD_TYPE.Pro;
      } else if (subscriptionType === 'engage_basic_plus') {
        subscriptionToSend = CARD_TYPE.Plus;
      } else {
        subscriptionToSend = CARD_TYPE.Free;
      }
      await dispatch({ type: SET_PROOFFER, payload: subscriptionToSend });
      await dispatch({ type: GET_USER_COMPANY_DATA_SUCCESS, payload: res.data });
    } else {
      return {
        type: GET_USER_COMPANY_DATA_PENDING,
        payload: res.data,
      };
    }
  } catch (err) {
    return {
      type: GET_USER_COMPANY_DATA_FAIL,
      payload: err.response && err.response.data,
    };
  }
};
