import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { isUserLoggedIn } from 'selectors/user';

/**
 * WithAuth is a HOF that wraps a HOC, used at route level for routes which requires authentication
 * @param {Component} ComponentToRender Component from route config
 * @returns {Component} if user is already logged in ComponentToRender
 * else Redirect to login route
 */

export default function WithAuth(ComponentToRender) {
  class AuthenticatedComponent extends Component {
    render() {
      const { userLoggedIn } = this.props;

      const renderComponent = userLoggedIn ? (
        <ComponentToRender {...this.props} />
      ) : (
        <Redirect
          to={{
            pathname: '/login',
          }}
        />
      );

      return <>{renderComponent}</>;
    }
  }

  const mapStateToProps = (state) => ({
    userLoggedIn: isUserLoggedIn(state),
  });

  AuthenticatedComponent.propTypes = {
    userLoggedIn: PropTypes.bool.isRequired,
  };

  return connect(mapStateToProps)(AuthenticatedComponent);
}
