import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import React from 'react';
import styled from 'styled-components';
import * as palette from 'styles/palette';

export const QuestionItem = styled.div`
  display: flex;
  padding-top: 1.1rem;
`;

export const Question = styled.p`
  margin: 0;
  font-size: 1.1rem;
  line-height: 1.5rem;
  font-weight: 400;
  color: ${palette.primaryDarkGreen};
`;

export const CircleCloseBtn = styled((props) => <CloseIcon {...props} />)`
  background-color: ${palette.primaryDarkRed};
  width: 1.2rem !important;
  height: 1.2rem !important;
  color: white;
  border-radius: 12px;
  margin-left: 0.5rem;
  &:hover {
    cursor: pointer;
    background-color: ${palette.primaryLightRed};
  }
`;

export const EditItem = styled.div`
  position: relative;
  margin-left: 1rem;
`;

export const EditBtn = styled((props) => <EditIcon {...props} />)`
  color: ${palette.primaryDarkRed};
  width: 1.2rem !important;
  height: 1.2rem !important;
  &:hover {
    cursor: pointer;
    color: ${palette.primaryLightRed};
  }
`;

export const ProText = styled.span`
  color: ${palette.primaryDarkRed};
  font-size: 0.4rem;
  line-height: 0.5rem;
  position: absolute;
`;

export const Description = Question.extend`
  font-weight: 300;
`;

export const QuestionItemContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const InputContainer = styled.div`
  width: 46.5rem;
`;

export const QuestionInput = styled.textarea`
  &&& {
    border: 1px solid ${palette.rowActiveColor};
    border-radius: 0.5rem;
    padding: 0.3rem 0.8rem;
    margin-top: 0.5rem;
    width: 46.5rem;
    height: 45px;
    color: ${palette.primaryLightGrey};
    font-size: 0.9rem;
    line-height: 1.2rem;
    outline: none;
    box-sizing: border-box;
  }
`;

export const CheckIcon = styled((props) => <CheckCircleIcon {...props} />)`
  margin-left: 0.9rem;
  width: 23px;
  height: 23px;
  color: ${palette.primaryDarkGreen};
  &:hover {
    cursor: pointer;
  }
`;
