import { LOGIN_AUTH_SUCCESS, LOGOUT_AUTH_SUCCESS, SET_REPORT_FILTERS_SUCCESS } from 'actions';
import moment from 'moment';
import { REPORT_FILTERS } from '../../components/Reports/filters';

const initialState = {
  filters: REPORT_FILTERS.defaultFilters,
  persistExpiresAt: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_REPORT_FILTERS_SUCCESS:
      return {
        ...state,
        filters: action.payload.filters,
        persistExpiresAt:
          (action.payload.userData && moment.unix(action.payload.userData.expiresAt).format()) ||
          null,
      };
    case LOGIN_AUTH_SUCCESS:
    case LOGOUT_AUTH_SUCCESS:
      return {
        ...state,
        filters: initialState.filters,
      };
    default: {
      return state;
    }
  }
};
