// Define action types
export const SET_REPORT_FILTERS_SUCCESS = 'SET_REPORT_FILTERS_SUCCESS';
export const SET_REPORT_FILTERS_FAIL = 'SET_REPORT_FILTERS_FAIL';

export const SET_REPORT_FILTERS = (filters, userData) => {
  try {
    if (filters) {
      return {
        type: SET_REPORT_FILTERS_SUCCESS,
        payload: { filters: filters, userData: userData },
      };
    }
  } catch (err) {
    return { type: SET_REPORT_FILTERS_FAIL, payload: err.response.data };
  }
};
