import I18n from 'i18n';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import MedModal from '../../../../MedModal';
import PatientModalContent from './PatientModalContent';

const hasYes = true;
const hasNo = true;

class PatientDataModal extends Component {
  static propTypes = {
    isOpenModal: PropTypes.bool,
    onClose: PropTypes.func,
  };

  state = {
    name: '',
    birthYear: '',
    size: '',
    weight: '',
    gender: 0,
    email: '',
    week: '',
  };

  handleCancel = () => {
    this.props.onClose();
  };

  handleSave = () => {
    this.props.onClose();
  };

  render() {
    const { isOpenModal } = this.props;
    return (
      <MedModal
        hasNo={hasNo}
        hasYes={hasYes}
        isAlert={false}
        isOpen={isOpenModal}
        modalContent={<PatientModalContent />}
        noTxt={I18n.t(
          'components.ReportDetail.DetailContainer.RightDetailView.PatientDataModal.cancel',
        )}
        onNo={this.handleCancel}
        onYes={this.handleSave}
        title={I18n.t(
          'components.ReportDetail.DetailContainer.RightDetailView.PatientDataModal.title',
        )}
        yesTxt={I18n.t(
          'components.ReportDetail.DetailContainer.RightDetailView.PatientDataModal.save',
        )}
      />
    );
  }
}

export default PatientDataModal;
