import TableBody from '@material-ui/core/TableBody';
import NotificationImportantOutlinedIcon from '@material-ui/icons/NotificationImportantOutlined';
import MenuListComposition from 'components/common/MenuListComposition';
import { formatAssignedAt, formatGender, formatLongString } from 'lib/helper/formatters';
// import MedCheck from 'lib/elements/Checkbox'
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { getActiveTeamMembers } from 'selectors/team';
import { getUserToken } from 'selectors/user';
import { withMedTracker } from 'services/tracking';
import {
  CHANGE_REPORT_ASSIGNEE,
  CHANGE_REPORT_STATUS,
  GET_TEAM,
  GET_TEAM_PENDING,
} from '../../../actions';
import { REPORT_STATUS } from '../../../constants';
import layersConfig from '../../../styles/layersConfig';
import * as palette from '../../../styles/palette';
import MenuList from './MenuListAssignee';
// MedCheckStyled is the component of the checkbox commented out temporarily
// import { ReportTableRow, TableCellItem, StatusBtn, EmptyReportTableRow, DisabledStatusBtn, MedCheckStyled } from './style'
import {
  AvatarStyled,
  DisabledStatusBtn,
  EmptyReportTableRow,
  ReactTooltipStyled,
  ReportTableRow,
  TableCellItem,
} from './style';

class TableBodySection extends Component {
  static propTypes = {
    data: PropTypes.array.isRequired,
    onSelectReport: PropTypes.func,
    status: PropTypes.string,
    isNR: PropTypes.bool,
    itemsChecked: PropTypes.object,
    handleCheckbox: PropTypes.func,
  };

  state = {
    currentPatient: '',
    reports: [],
    assigneeAnchorEl: '',
    teamMembersToShow: [],
  };

  componentDidMount() {
    this.props.getTeamMembers(this.props.user.token);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      this.setState({
        reports: this.props.data,
      });
    }
  }

  getDrugName = (item) => {
    let formatedDrugName = 'Keine';
    const drugToShow = _.get(item, 'primaryDrug', _.get(item, 'drugs[0].name', ''));
    if (drugToShow.length) {
      formatedDrugName = formatLongString(drugToShow, 12);
      formatedDrugName =
        formatedDrugName.length > 17 ? formatedDrugName.slice(0, 17) + '...' : formatedDrugName;
    }
    return formatedDrugName;
  };

  handleSetReport = (report) => {
    this.props.onSelectReport(report.id || report);
  };

  changeReportPharmaStatus = async (event, toStatus, reportId) => {
    const response = await this.props.changeReportStatus(reportId, toStatus, this.props.userToken);
    if (response.success) {
      // if report status is changed, change status manually
      // TODO: Immediate fetch of report list doesn't reflect status change. Find a way to fix & fetch list to refresh
      const reports = this.state.reports.map((report) => {
        if (report.id === reportId) {
          report.status = toStatus;
        }
        return report;
      });

      this.setState({ reports });

      this.props.MedTrackEvent('PatientCase', 'changeStatus', toStatus, 1);
    }
  };

  getAssignedUserInitials = (id) => {
    const reportAssignedTo = this.props.teamMembers.find((teamMember) => teamMember._id === id);
    if (reportAssignedTo) {
      return (
        (reportAssignedTo.name.firstName.length && reportAssignedTo.name.firstName.charAt(0)) +
          ('' + reportAssignedTo.name.lastName.length &&
            reportAssignedTo.name.lastName.charAt(0)) || ''
      );
    }
  };

  onAssigneeChange = async (newAssigneeId) => {
    await this.props.changeReportAssignee(
      this.props.selectedReportId,
      newAssigneeId,
      this.props.user.token,
    );
    this.props.MedTrackEvent(
      'PatientCase',
      'changeAssignee',
      newAssigneeId.length ? 'user' : 'unassigned',
      1,
    );
    this.props.onUpdateReport(this.props.selectedReportId);
    this.setState({ assigneeAnchorEl: '' });
  };

  handleAssigneeMenuOpen = (event, assignedTo) => {
    const teamMembersToDisplay = this.props.teamMembers
      .filter((teamMember) => teamMember._id !== assignedTo)
      .map((teamMember) => {
        return {
          name: `${teamMember.name.firstName} ${teamMember.name.lastName}`,
          _id: teamMember._id,
          initials: this.getAssignedUserInitials(assignedTo),
        };
      });

    if (assignedTo) {
      teamMembersToDisplay.unshift({
        name: 'nicht zugewiesen',
        _id: '',
        initials: '',
      });
    }
    this.setState({
      assigneeAnchorEl: event.currentTarget,
      teamMembersToShow: teamMembersToDisplay,
    });
  };

  onAssigneeMenuClose = () => {
    this.setState({ assigneeAnchorEl: null });
  };

  getUserInfo = (userId) => {
    const userToShowOnTooltip = this.props.teamMembers.filter(
      (teamMember) => teamMember._id === userId,
    )[0];

    if (userToShowOnTooltip) {
      return `${userToShowOnTooltip.name.firstName} ${
        userToShowOnTooltip.name.lastName
      } <br /> ${userToShowOnTooltip.role || ''}`;
    } else {
      return '';
    }
  };

  render() {
    const { t, isPro, isPlus } = this.props;
    const { reports } = this.state;
    let fillerData = [];
    if (!isPro && !isPlus) {
      fillerData = _.range(20);
    }

    return (
      <TableBody>
        <MenuList
          anchorEl={this.state.assigneeAnchorEl}
          handleClose={this.onAssigneeMenuClose}
          teamMembersToShow={this.state.teamMembersToShow}
          changeAssignee={this.onAssigneeChange}
        />
        {reports.map((item, index) => {
          const assignedPharmaEmployeeId = item.assignedPharmaEmployeeId;
          const reportStatus = item.status;
          const userInfo = this.getUserInfo(assignedPharmaEmployeeId);
          return (
            <ReportTableRow
              isClicked={this.props.selectedReportId === item.id}
              onClick={(ev) => this.handleSetReport(item, ev)}
              hover
              key={index}
            >
              {/* <TableCellItem>
                <MedCheckStyled
                  name={item.id}
                  checked={checkedItems[item.id]}
                  disabled={!isPro}
                  onChange={(e) => this.props.handleCheckbox(e)}
                />
              </TableCellItem> */}
              {(isPro || isPlus) && (
                <TableCellItem>
                  <ReactTooltipStyled place="bottom" effect="solid" multiline />
                  <AvatarStyled
                    data-tip={userInfo}
                    userId={assignedPharmaEmployeeId}
                    onClick={(e) => this.handleAssigneeMenuOpen(e, assignedPharmaEmployeeId)}
                    aria-controls="customized-menu"
                    aria-haspopup="true"
                    variant="contained"
                    color="primary"
                  >
                    {this.getAssignedUserInitials(assignedPharmaEmployeeId)}
                  </AvatarStyled>
                </TableCellItem>
              )}
              <TableCellItem>{formatAssignedAt(item, 'DD.MM.YYYY')}</TableCellItem>
              {!(isPro || isPlus) && (
                <TableCellItem>
                  {formatGender(item, true) + ', ' + (item.patient.dateOfBirthYear || 'K. A.')}
                </TableCellItem>
              )}
              <TableCellItem>{this.getDrugName(item)}</TableCellItem>
              <TableCellItem status={reportStatus}>
                <MenuListComposition
                  text={t('components.ReportTable.TableBodySection.status.' + reportStatus)}
                  popperStyle={{ zIndex: layersConfig.levelOne }}
                  menuDisabled={reportStatus === REPORT_STATUS.Archived}
                  menuList={[
                    {
                      text: t(
                        'components.ReportTable.TableBodySection.statusChange.' +
                          REPORT_STATUS.inProgress,
                      ),
                      onItemClick: (e) =>
                        this.changeReportPharmaStatus(e, REPORT_STATUS.inProgress, item.id),
                      isItemDisabled: reportStatus === REPORT_STATUS.inProgress,
                    },
                    {
                      text: t(
                        'components.ReportTable.TableBodySection.statusChange.' +
                          REPORT_STATUS.Closed,
                      ),
                      onItemClick: (e) =>
                        this.changeReportPharmaStatus(e, REPORT_STATUS.Closed, item.id),
                      isItemDisabled: reportStatus === REPORT_STATUS.Closed,
                    },
                  ]}
                  status={reportStatus}
                />
              </TableCellItem>
              <TableCellItem>
                {item.reportSeverityStatus === 'serious' ? (
                  <>
                    <NotificationImportantOutlinedIcon
                      style={{ color: palette.primaryDarkGreen }}
                      fontSize="large"
                      data-tip
                      data-for="registerTip"
                    />
                    <ReactTooltip id="registerTip" place="bottom" effect="solid">
                      {t('components.ReportTable.TableBodySection.seriousTooltip')}
                    </ReactTooltip>
                  </>
                ) : (
                  ''
                )}
              </TableCellItem>
            </ReportTableRow>
          );
        })}
        {fillerData.map((item, index) => {
          const reportStatus = isPro ? item.status : REPORT_STATUS.pharmaOpened;
          return (
            <EmptyReportTableRow key={index} isPlus={isPlus}>
              {/* <TableCellItem>
                <MedCheck checked={false} disabled/>
              </TableCellItem> */}
              <TableCellItem>.......</TableCellItem>
              <TableCellItem>................</TableCellItem>
              <TableCellItem>.........................</TableCellItem>
              <TableCellItem>
                <DisabledStatusBtn status={reportStatus} isPlus={isPlus}>
                  .............
                </DisabledStatusBtn>
                {isPlus && <span>..................</span>}
              </TableCellItem>
            </EmptyReportTableRow>
          );
        })}
      </TableBody>
    );
  }
}

function mapStateToProps(state, props) {
  const { isPro, isPlus } = state.proOffer;
  const { user } = state.auth;
  return {
    isPro,
    isPlus,
    teamMembers: getActiveTeamMembers(state),
    user,
    userToken: getUserToken(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    changeReportStatus: async (reportId, toStatus, token) => {
      const response = await dispatch(await CHANGE_REPORT_STATUS(reportId, toStatus, token));
      return response.payload;
    },
    getTeamMembers: async (token) => {
      dispatch({ type: GET_TEAM_PENDING });
      const teamMembers = await GET_TEAM(token);
      return dispatch(teamMembers);
    },
    changeReportAssignee: async (reportId, newAssigneeId, token) => {
      const response = await dispatch(await CHANGE_REPORT_ASSIGNEE(reportId, newAssigneeId, token));
      return response.payload;
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(withMedTracker(TableBodySection)));
