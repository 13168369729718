import { eventstoreInstanceAxios as eventstore } from '../config';

// TODO use this const somewhere if necessary
// const QUESTIONS_URL = '/questions'
const GET_QUESTIONS_URL = '/get_questions';

/**
 * Send questions and remind patients of questions already asked but not answered
 *
 * @returns {Promise} - Result of ajax call
 */
export async function interactWithPatientAPI(reportId, questions, to, token) {
  const config = {
    url: `report/${reportId}/questions/${to}`,
    method: 'post',
    data: questions,
    headers: { Authorization: token },
  };

  return eventstore(config);
}

/**
 * Get questions request
 *
 * @returns {Promise} - Result of ajax call
 */
// TODO : Not being used
export async function getQuestionsAPI(params) {
  return eventstore.get(GET_QUESTIONS_URL, {
    params: params,
  });
}
