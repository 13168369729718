import Button from '@material-ui/core/Button';
import React from 'react';
import styled from 'styled-components';
import * as palette from 'styles/palette';

export const QuestionsContainer = styled.div`
  position: relative;
  margin-top: 15px;
  padding: 15px 20px;
  background: ${palette.unknownBGColor};
  border-radius: 7px;
`;

export const LeftQuestionContainer = styled.div`
  display: inline-block;
  overflow: hidden;
  vertical-align: top;
  width: 100%;
  // border-right: 1px solid ${palette.rowActiveColor};
`;

export const RightQuestionContainer = styled.div`
  overflow: hidden;
  padding-left: 15px;
  vertical-align: top;
  display: inline-block;
  width: 50%;
  opacity: ${(props) => (props.isDisabled === true ? 0.3 : 1)};
`;

export const QuestionTitle = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 20px;
  color: ${palette.primaryDarkGreen};
`;

export const QuestionsContent = styled.div`
  padding-top: 30px;
  height: 150px;
  overflow-y: scroll;
`;

export const QuestionBox = styled.div``;

export const SecondaryAnswerBox = styled.div``;

export const Question = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 1.3rem;
  line-height: 16px;
  color: ${palette.tableHeaderColor};

  display: inline-block;
  width: 75%;
`;

export const Reminder = styled.span`
  font-style: italic;
  font-weight: normal;
  font-size: 1.3rem;
  line-height: 16px;
  display: inline-flex;
  width: 75%;
  color: ${palette.primaryDarkGreen};
  white-space: pre;
`;

export const QuestionDate = styled.span`
  font-style: italic;
  font-weight: normal;
  font-size: 1.1rem;
  line-height: 12px;
  color: ${palette.tableHeaderColor};

  display: inline-block;
  width: 15%;
`;

export const AnswerBox = styled.div`
  padding-bottom: 10px;
`;

export const AnswerSecondary = styled.span`
  font-style: italic !important;
  opacity: 0.6;
  font-style: normal;
  font-weight: normal;
  font-size: 1.2rem;
  line-height: 15px;
  color: ${palette.primaryDarkGreen};

  display: inline-block;
  width: 80%;
  white-space: pre-wrap;
`;

export const Answer = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 1.3rem;
  line-height: 16px;
  display: inline-flex;
  width: 75%;
  color: ${palette.primaryDarkGreen};
  white-space: pre;
`;

export const LongAnswer = styled(Answer).attrs({
  className: 'longAnswer',
})`
  &.longAnswer {
    white-space: pre-line;
  }
`;

export const AnswerDate = styled.span`
  font-style: italic;
  font-weight: normal;
  font-size: 1.1rem;
  line-height: 12px;
  color: ${palette.primaryDarkGreen};

  display: inline-block;
  width: 15%;
`;

export const SecondaryBtn = styled((props) => <Button {...props} />)`
  position: absolute !important;
  right: 15px;
  bottom: 14px;
  background-color: ${palette.primaryDarkRed} !important;
  border-radius: 7px !important;
  float: right;
  margin-right: 25px !important;
  > span {
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 15px;
    text-align: center;
    color: ${(props) =>
      props.isDisabled === true ? `${palette.lightRose}` : `${palette.white}`} !important;
  }
  svg {
    padding-right: 5px;
  }
  text-transform: none !important;
`;
