import jwtDecoder from 'jwt-decode';
import moment from 'moment';
import queryString from 'query-string';

const isParamExists = (param, options) => {
  const parsed = queryString.parse(window.location.search, options);
  return parsed[param] ? parsed[param] : false;
};

export const getToken = () => {
  const token = isParamExists('token');
  return token ? `Bearer ${token}` : null;
};

export const isTokenExpired = (token) => {
  const decodedToken = jwtDecoder(token);
  const tokenExpiryDateTime = moment.unix(decodedToken.exp);
  return moment().isAfter(tokenExpiryDateTime);
};

export const getStringParamFromURL = (url, queryParameter, fallback = '') => {
  const parsed = queryString.parse(url);
  return parsed[queryParameter] ? parsed[queryParameter] : fallback;
};
