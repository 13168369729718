import { routerReducer } from 'react-router-redux';
import { combineReducers } from 'redux';

import auth from './auth';
import pharmaCompany from './pharmaCompany';
import proOffer from './proOffer';
import register from './register';
import reports from './reports';
import reset from './reset';
import route from './route';
import sideEffects from './sideEffects';
import team from './team';
import userData from './userData';
import userSettings from './userSettings';

// Create root reducer
export default combineReducers({
  auth: auth,
  register: register,
  reset: reset,
  reports: reports,
  sideEffects: sideEffects,
  proOffer: proOffer,
  team: team,
  routing: routerReducer,
  userData: userData,
  pharmaCompany: pharmaCompany,
  route: route,
  userSettings: userSettings,
});
