import { Avatar, TableCell, TableRow } from '@material-ui/core';
import MedCheck from 'lib/elements/Checkbox';
import MedButton from 'lib/elements/MedButton';
import React from 'react';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';
import * as layersConfig from 'styles/layersConfig';
import * as palette from 'styles/palette';
import { REPORT_STATUS } from '../../../constants';
import { getProfileColor } from '../../../utilities/generalFunctions';

const getColorForStatusBtn = (status, applyTo) => {
  switch (status) {
    case REPORT_STATUS.Closed:
    case REPORT_STATUS.Archived:
      if (applyTo === 'background') {
        return palette.primaryLightGrey;
      } else if (applyTo === 'text') {
        return palette.white;
      } else if (applyTo === 'border') {
        return palette.primaryLightGrey;
      }
      break;

    case REPORT_STATUS.QuestionsAnswered:
    case REPORT_STATUS.New:
      if (applyTo === 'background') {
        return palette.primaryLightGreen;
      } else if (applyTo === 'text') {
        return palette.white;
      } else if (applyTo === 'border') {
        return palette.primaryLightGreen;
      }
      break;

    case REPORT_STATUS.QuestionsAsked:
      if (applyTo === 'background') {
        return palette.primaryDarkRed;
      } else if (applyTo === 'text') {
        return palette.white;
      } else if (applyTo === 'border') {
        return palette.primaryDarkRed;
      }
      break;

    case REPORT_STATUS.ReminderSent:
      if (applyTo === 'background') {
        return palette.primaryCarmineRed;
      } else if (applyTo === 'text') {
        return palette.white;
      } else if (applyTo === 'border') {
        return palette.primaryDarkRed;
      }
      break;

    case REPORT_STATUS.pharmaOpened:
      if (applyTo === 'background') {
        return palette.white;
      } else if (applyTo === 'text') {
        return palette.black;
      } else if (applyTo === 'border') {
        return palette.primaryDarkGrey;
      }
      break;
  }
};

export const ReportTableRow = styled(({ isClicked, ...props }) => <TableRow {...props} />)`
  &&& {
    height: 50px;
    background-color: ${(props) =>
      props.isClicked ? palette.primarySuperLightGreen : palette.white};

    &:hover {
      cursor: ${(props) => (props.isClicked ? 'default' : 'pointer')};
    }
  }
`;

export const EmptyReportTableRow = styled(({ isClicked, ...props }) => <TableRow {...props} />)`
  height: 50px;
  td,
  span,
  svg {
    color: ${(props) => (props.isPlus ? palette.gray : palette.primaryLightGrey)} !important;
    opacity: 0.8;
  }
`;

export const TableCellItem = styled(({ isChecked, ...props }) => <TableCell {...props} />)`
  &&& {
    padding: ${(props) => (props.isChecked ? '1.6rem 0.2rem' : '0.5rem 1rem 0.5rem 0rem')};
    box-sizing: border-box;
    font-size: 1.3rem;
    line-height: 1.4rem;
    color: ${palette.primaryDarkGrey};
    text-align: center;
    & > span > span > svg {
      color: ${palette.primaryDarkGrey};
      width: 1.4rem;
      height: 1.4rem;
    }
  }
`;

export const StatusTableBtn = styled(MedButton)`
  &&& {
    background-color: ${(props) => getColorForStatusBtn(props.status, 'background')};
    color: ${(props) => getColorForStatusBtn(props.status, 'text')};
    border: 1px solid ${(props) => getColorForStatusBtn(props.status, 'border')};
    padding: 0px 9px 0px 7px;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
      0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    min-height: 2rem;
    width: 120px;
    & > span {
      font-size: 11px;
      white-space: nowrap;
      padding: 0.5px;
    }
  }
`;

export const DisabledStatusBtn = styled(StatusTableBtn)`
  &&& {
    width: 120px;
    background-color: transparent;
    border: 1px solid ${palette.primaryLightGrey};
    color: ${palette.primaryLightGrey};
    display: ${(props) => (props.isPlus ? 'none' : 'auto')};
  }
`;
export const MedCheckStyled = styled(MedCheck)`
  &&& {
    span,
    svg {
      color: ${(props) =>
        props.disabled === true ? `${palette.primarySuperLightGrey} !important` : ''};
    }
  }
`;
export const AvatarStyled = styled((props) => <Avatar {...props} />)`
  &&& {
    margin: auto;
    width: 30px;
    height: 30px;
    background-color: ${(props) => getProfileColor(props.userId)};
    cursor: pointer;
  }
`;
export const ReactTooltipStyled = styled((props) => <ReactTooltip {...props} />)`
  &&& {
    z-index: ${layersConfig.levelFour};
  }
`;
