import EditIcon from '@material-ui/icons/Edit';
import React from 'react';
import styled from 'styled-components';
import * as palette from 'styles/palette.js';

export const Container = styled.div`
  min-width: 26.5rem;
  max-width: 30rem;
  padding: 3.9rem 1.7rem;
  border-left: 1px solid ${palette.unknownBGColor};
  word-wrap: break-word;
`;

export const SubContainer = styled.div`
  padding: 2.4rem 0;
  padding-bottom: 0;
`;

export const DetailContainer = styled.div`
  padding: 0.8rem 0;
  padding-bottom: 0;
`;

export const SubtitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const SubTitle = styled.span`
  color: ${palette.tableHeaderColor};
  font-weight: 600;
  padding-right: 1rem;
  font-size: 1.4rem;
`;

export const EditBtn = styled((props) => <EditIcon {...props} />)`
  &&& {
    color: ${palette.tableHeaderColor};
    width: 1.2rem;
    height: 1.2rem;
    margin-right: 1.9rem;
    &:hover {
      color: ${palette.tableBodyColor};
      cursor: pointer;
    }
  }
`;

export const DetailTitleColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
`;

export const DetailTitleRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const DetailText = styled.span`
  display: block;
  font-size: 1.3rem;
  line-height: 1.4rem;
  color: ${palette.tableBodyColor};
  background-color: ${(props) => (props.isPN ? `${palette.coldBlue}` : '')};
`;

export const Asterisk = styled.span`
  color: ${palette.primaryDarkRed};
  font-size: 1rem;
`;

export const ColumnDetailText = DetailText.extend`
  margin-right: 20%;
`;

export const RowDetailText = DetailText.extend`
  word-wrap: break-word;
`;

export const DetailTitle = DetailText.extend`
  font-weight: 400;
  background-color: ${(props) => (props.isPN ? `${palette.coldBlue}` : '')};
`;

export const DetailTitleCol = styled.div`
  padding: 0.9rem 0;
`;

export const DurationText = styled.span`
  font-size: 1rem;
  line-height: 1.4rem;
  font-weight: 400;
`;
