import PropTypes from 'prop-types';
import React from 'react';
import { ErrorTextLabel } from './style';

const ErrorLabel = ({ errText, hasError, isChecked, hasFile }) => {
  return (
    <ErrorTextLabel hasError={hasError} hasFile={hasFile} isChecked={isChecked}>
      {errText}
    </ErrorTextLabel>
  );
};

ErrorLabel.propTypes = {
  errText: PropTypes.string.isRequired,
  hasError: PropTypes.bool.isRequired,
  isChecked: PropTypes.bool,
  hasFile: PropTypes.bool,
};

export default ErrorLabel;
