import { deleteTeamMember, getTeamAPI, inviteTeamMember, reInviteTeamMember } from 'api';

// Define action types
export const GET_TEAM_SUCCESS = 'GET_TEAM_SUCCESS';
export const GET_TEAM_FAIL = 'GET_TEAM_FAIL';
export const GET_TEAM_PENDING = 'GET_TEAM_PENDING';
export const INVITE_COLLEAGUE_SUCCESS = 'INVITE_COLLEAGUE_SUCCESS';
export const INVITE_COLLEAGUE_FAIL = 'INVITE_COLLEAGUE_FAIL';
export const INVITE_COLLEAGUE_PENDING = 'INVITE_COLLEAGUE_PENDING';
export const DELETE_TEAM_MEMBER_SUCCESS = 'DELETE_TEAM_MEMBER_SUCCESS';
export const DELETE_TEAM_MEMBER_PENDING = 'DELETE_TEAM_MEMBER_PENDING';
export const DELETE_TEAM_MEMBER_FAIL = 'DELETE_TEAM_MEMBER_FAIL';
export const REINVITE_COLLEAGUE_SUCCESS = 'REINVITE_COLLEAGUE_SUCCESS';
export const REINVITE_COLLEAGUE_PENDING = 'REINVITE_COLLEAGUE_PENDING';
export const REINVITE_COLLEAGUE_FAIL = 'REINVITE_COLLEAGUE_FAIL';

export const GET_TEAM = async (token) => {
  try {
    const res = await getTeamAPI(token);
    if (res.data) {
      return { type: GET_TEAM_SUCCESS, payload: res.data };
    } else {
      return { type: GET_TEAM_PENDING, payload: { reports: res.data } };
    }
  } catch (err) {
    return { type: GET_TEAM_FAIL, payload: err.response.data };
  }
};

export const INVITE_TEAM_MEMBER = async (userData, token) => {
  try {
    const res = await inviteTeamMember(userData, token);
    if (res.data) {
      return { type: INVITE_COLLEAGUE_SUCCESS, payload: { report: res.data } };
    } else {
      return { type: INVITE_COLLEAGUE_PENDING, payload: { reports: res.data } };
    }
  } catch (err) {
    return {
      type: INVITE_COLLEAGUE_FAIL,
      payload: err.response && err.response.data,
    };
  }
};

export const REINVITE_TEAM_MEMBER = async (userData, token) => {
  try {
    const res = await reInviteTeamMember(userData, token);
    if (res.data) {
      return { type: REINVITE_COLLEAGUE_SUCCESS, payload: { report: res.data } };
    } else {
      return {
        type: REINVITE_COLLEAGUE_PENDING,
        payload: { reports: res.data },
      };
    }
  } catch (err) {
    return {
      type: REINVITE_COLLEAGUE_FAIL,
      payload: err.response && err.response.data,
    };
  }
};

export const DELETE_TEAM_MEMBER = async (memberId, token) => {
  try {
    const res = await deleteTeamMember(memberId, token);
    if (res.data) {
      return { type: DELETE_TEAM_MEMBER_SUCCESS, payload: res.data };
    } else {
      return {
        type: DELETE_TEAM_MEMBER_PENDING,
        payload: { reports: res.data },
      };
    }
  } catch (err) {
    return { type: DELETE_TEAM_MEMBER_FAIL, payload: err.response.data };
  }
};
