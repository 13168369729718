import styled from 'styled-components';
import * as palette from 'styles/palette';

export const PwdValidationContainer = styled.div`
  background-color: ${palette.pwdBGColor};
  border-radius: 0.7rem;
  color: ${palette.white};
  padding: 0.7rem 1.2rem;
  position: absolute;
  transition: all ease-in-out 0.6s;
  left: 100%;
  opacity: 1;
  top: 1.5rem;
  margin-left: 1.1rem;
  opacity: ${(props) => (props.isFocus ? 1 : 0)};
  box-sizing: border-box;
`;

export const StengthBox = styled.div`
  display: flex;
  padding-bottom: 3px;
`;

export const WeightText = styled.span`
  color: ${(props) => props.color};
  margin-left: 3px;
`;

export const Line = styled.div`
  width: 4.8rem;
  height: 0.6rem;
  border-radius: 0.7rem;
  margin-right: 4px;
  background-color: ${(props) => props.color};
  &:last-child {
    margin-right: 0;
  }
`;

export const LineBox = styled.div`
  display: flex;
  padding-bottom: 4px;
`;

export const ShouldContain = styled.div`
  font-size: 1rem;
  line-height: 1.4rem;
  text-align: left;
  white-space: nowrap;
`;
