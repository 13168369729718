import { CircularProgress, FormControlLabel } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import Send from '@material-ui/icons/Send';
import MedCheckBox from 'lib/elements/Checkbox';
import MedButton from 'lib/elements/MedButton';
import React from 'react';
import styled from 'styled-components';
import * as layersConfig from 'styles/layersConfig';
import * as palette from 'styles/palette';

export const Header = styled.div`
  padding: 15px;
  background: ${palette.primaryDarkRed};
  > h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 18px;
    color: ${palette.white};

    margin: 0 0 10px 0;
  }
  > span {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: ${palette.white};
  }
`;

export const YesBtn = styled(MedButton)`
  &&& {
    background-color: ${palette.primaryLightGreen};
    &:hover {
      background-color: ${palette.primaryLightGreen};
    }
  }
`;

export const NoBtn = styled(MedButton)`
  &&& {
    background-color: ${palette.primaryDarkRed};
    &:hover {
      background-color: ${palette.primaryLightRed};
    }
  }
`;

export const CheckBoxContainer = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${palette.gray};
  & > div > input {
    width: 600px;
  }
`;

export const CheckBoxLabel = styled(({ color, ...other }) => (
  <FormControlLabel classes={{ label: 'label', control: 'control' }} {...other} />
))`
  padding: 0 1.5rem;
  & .label {
    font-style: normal;
    font-weight: 200;
    font-size: 14px;

    color: ${palette.tableHeaderColor};
    line-height: 3.5rem;
  }
`;

export const QuestionCheckBox = styled(MedCheckBox)`
  &&& {
    width: 0.5rem;
    height: 1rem;
  }
`;

// Disabled for now.
export const UserDefined = styled.div`
  width: ${(props) => (!props.isProOrPlus ? '53%' : 'auto')};
  float: ${(props) => (!props.isProOrPlus ? 'left' : 'unset')};
  line-height: 3em;
  padding-top: 10px;
`;

export const StyledTitle = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 14px;
  color: ${palette.primaryDarkGreen};
  opacity: ${(props) => (!props.isProOrPlus ? '0.5' : '1')};
`;

export const StyledInfoIcon = styled((props) => <InfoIcon {...props} />)`
  margin-left: 5px;
  vertical-align: text-bottom;
  font-size: 20px !important;
  color: ${palette.primaryDarkGreen};
`;

export const PremiumAd = styled.div`
  margin-top: 46px;
  float: right;
  > span {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: ${palette.dlgFontColor};
  }
`;
export const PressEnter = styled.span`
  white-space: pre;
  color: ${palette.primaryLightGrey};
  cursor: pointer;
  z-index: ${layersConfig.levelTwo};
`;

export const SendStyled = styled((props) => <Send {...props} />)`
  color: ${palette.primaryDarkGreen};
  margin-bottom: -7px;
  margin-left: 5px;
  font-size: 2.5rem !important;
`;

export const LoadingSpinner = styled((props) => <CircularProgress {...props} />)`
  &&& {
    color: ${palette.primaryLightGreen};
    align-self: center;
  }
`;

export const ButtonSpinner = styled((props) => <CircularProgress {...props} />)`
  margin-right: 10px;
  svg {
    color: ${palette.white};
  }
`;

export const StyledParagraph = styled.p`
  width: 650px;
  line-height: 1.5em;
`;
export const TestProBtn = styled(MedButton)`
  &&& {
    background-color: ${palette.white};
    color: ${palette.primaryDarkGreen};
    border: 1px solid ${palette.primaryLightGrey};
    float: right;
    font-weight: 600;
    &:hover {
      background-color: ${palette.primaryLightGreen};
    }
  }
`;
