import I18n from 'i18n';
import moment from 'moment';
import { REPORT_STATUS } from '../../constants';

export const GET_NESTED_REPORT_FILTERS = (isBasicPlus) => [
  {
    id: 'assigned-to-me',
    name: I18n.t('components.Reports.filters.assignedToMe'),
  },
  {
    id: 'due-this-week',
    name: I18n.t('components.Reports.filters.dueThisWeek'),
  },
  {
    id: 'due-this-month',
    name: I18n.t('components.Reports.filters.dueThisMonth'),
  },
  {
    id: 'is-serious-report',
    name: I18n.t('components.Reports.filters.isSeriousReport'),
  },
  {
    id: 'root1',
    name: I18n.t('components.Reports.filters.groupByStatus'),
    children: [
      {
        id: 'active-reports',
        name: I18n.t('components.Reports.filters.activeReports'),
      },
      {
        id: 'all-reports',
        name: I18n.t('components.Reports.filters.allReports'),
      },
      {
        id: REPORT_STATUS.New,
        name: I18n.t('components.Reports.filters.status.' + REPORT_STATUS.New),
      },
      {
        id: REPORT_STATUS.pharmaOpened,
        name: I18n.t('components.Reports.filters.status.' + REPORT_STATUS.pharmaOpened),
      },
      {
        id: REPORT_STATUS.inProgress,
        name: I18n.t('components.Reports.filters.status.' + REPORT_STATUS.inProgress),
      },
      {
        id: REPORT_STATUS.QuestionsAsked,
        name: I18n.t('components.Reports.filters.status.' + REPORT_STATUS.QuestionsAsked),
      },
      {
        id: REPORT_STATUS.ReminderSent,
        name: I18n.t('components.Reports.filters.status.' + REPORT_STATUS.ReminderSent),
      },
      {
        id: REPORT_STATUS.QuestionsAnswered,
        name: I18n.t('components.Reports.filters.status.' + REPORT_STATUS.QuestionsAnswered),
      },
      {
        id: REPORT_STATUS.Closed,
        name: I18n.t('components.Reports.filters.status.' + REPORT_STATUS.Closed),
      },
      {
        id: REPORT_STATUS.Archived,
        name: I18n.t('components.Reports.filters.status.' + REPORT_STATUS.Archived),
        disabled: isBasicPlus,
        showPremiumTeaser: isBasicPlus,
        premiumTeaserText: I18n.t('components.Reports.filters.premiumTeaserText'),
      },
    ],
  },
];

export const REPORT_FILTERS = {
  defaultFilters: {
    'assigned-to-me': false,
    'active-reports': true,
    'all-reports': false,
    'due-this-week': false,
    'due-this-month': false,
    'is-serious-report': false,
    [REPORT_STATUS.New]: true,
    [REPORT_STATUS.pharmaOpened]: true,
    [REPORT_STATUS.inProgress]: true,
    [REPORT_STATUS.QuestionsAsked]: true,
    [REPORT_STATUS.ReminderSent]: true,
    [REPORT_STATUS.QuestionsAnswered]: true,
    [REPORT_STATUS.Closed]: false,
    [REPORT_STATUS.Archived]: false,
  },
  'assigned-to-me': {
    filterMethod: (options) => (report) =>
      report.assignedPharmaEmployeeId === options.loggedInUserId,
    filterType: 'assignee',
  },
  'active-reports': {
    checkUncheck: {
      'all-reports': false,
      [REPORT_STATUS.New]: true,
      [REPORT_STATUS.pharmaOpened]: true,
      [REPORT_STATUS.inProgress]: true,
      [REPORT_STATUS.QuestionsAsked]: true,
      [REPORT_STATUS.ReminderSent]: true,
      [REPORT_STATUS.QuestionsAnswered]: true,
      [REPORT_STATUS.Closed]: false,
      [REPORT_STATUS.Archived]: false,
    },
    filterType: 'status',
  },
  'all-reports': {
    checkUncheck: {
      'active-reports': false,
      [REPORT_STATUS.New]: true,
      [REPORT_STATUS.pharmaOpened]: true,
      [REPORT_STATUS.inProgress]: true,
      [REPORT_STATUS.QuestionsAsked]: true,
      [REPORT_STATUS.ReminderSent]: true,
      [REPORT_STATUS.QuestionsAnswered]: true,
      [REPORT_STATUS.Closed]: true,
      [REPORT_STATUS.Archived]: true,
    },
    filterType: 'status',
  },
  'due-this-week': {
    filterMethod: (options) => (report) =>
      moment(report.assigned).add(90, 'day') <= moment().endOf('isoweek').toDate(),
    filterType: 'range',
  },
  'due-this-month': {
    filterMethod: (options) => (report) =>
      moment(report.assigned).add(90, 'day') <= moment().endOf('month').toDate(),
    filterType: 'range',
  },
  'is-serious-report': {
    filterMethod: (options) => (report) => report.reportSeverityStatus === 'serious',
    filterType: 'severity',
  },
  [REPORT_STATUS.New]: {
    checkUncheck: { 'all-reports': false, 'active-reports': false },
    filterMethod: (report) => report.status === REPORT_STATUS.New,
    filterType: 'status',
  },
  [REPORT_STATUS.pharmaOpened]: {
    checkUncheck: { 'all-reports': false, 'active-reports': false },
    filterMethod: (report) => report.status === REPORT_STATUS.pharmaOpened,
    filterType: 'status',
  },
  [REPORT_STATUS.inProgress]: {
    checkUncheck: { 'all-reports': false, 'active-reports': false },
    filterMethod: (report) => report.status === REPORT_STATUS.inProgress,
    filterType: 'status',
  },
  [REPORT_STATUS.QuestionsAsked]: {
    checkUncheck: { 'all-reports': false, 'active-reports': false },
    filterMethod: (report) => report.status === REPORT_STATUS.QuestionsAsked,
    filterType: 'status',
  },
  [REPORT_STATUS.ReminderSent]: {
    checkUncheck: { 'all-reports': false, 'active-reports': false },
    filterMethod: (report) => report.status === REPORT_STATUS.ReminderSent,
    filterType: 'status',
  },
  [REPORT_STATUS.QuestionsAnswered]: {
    checkUncheck: { 'all-reports': false, 'active-reports': false },
    filterMethod: (report) => report.status === REPORT_STATUS.QuestionsAnswered,
    filterType: 'status',
  },
  [REPORT_STATUS.Closed]: {
    checkUncheck: { 'all-reports': false },
    filterMethod: (report) => report.status === REPORT_STATUS.Closed,
    filterType: 'status',
  },
  [REPORT_STATUS.Archived]: {
    checkUncheck: { 'all-reports': false },
    filterMethod: (report) => report.status === REPORT_STATUS.Archived,
    filterType: 'status',
  },
};
