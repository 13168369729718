import React, { Component } from 'react';
import { connect } from 'react-redux';

import I18n from 'i18n';
import { getCompanyDetails } from 'selectors/company';
import { withMedTracker } from 'services/tracking';
import { COUNTRIES, PHARMA_PLANS } from '../../../constants';

import { CompanyContainer, PropertyLabel, PropertyValue } from './style';

class Firma extends Component {
  componentDidMount() {
    this.props.MedTrackPageView('Company Settings Page');
  }

  render() {
    const { _id, companyName, country, subscriptionType, pvEmail } =
      this.props.companyDetails || {};

    const countryLong = COUNTRIES[country];
    const pharmaPlan = PHARMA_PLANS[subscriptionType];

    return (
      <>
        <CompanyContainer>
          <PropertyLabel>{I18n.t('components.Settings.Company.id')}</PropertyLabel> <br />
          <span />
          <PropertyValue>{_id}</PropertyValue>
          <hr />
          <PropertyLabel>{I18n.t('components.Settings.Company.companyName')}</PropertyLabel> <br />
          <span />
          <PropertyValue>{companyName}</PropertyValue>
          <hr />
          <PropertyLabel>{I18n.t('components.Settings.Company.pvEmail')}</PropertyLabel> <br />
          <span />
          <PropertyValue>{pvEmail}</PropertyValue>
          <hr />
          <PropertyLabel>{I18n.t('components.Settings.Company.pharmaPlan')}</PropertyLabel> <br />
          <span />
          <PropertyValue>{pharmaPlan}</PropertyValue>
          <hr />
          <PropertyLabel>{I18n.t('components.Settings.Company.countryLong')}</PropertyLabel> <br />
          <span />
          <PropertyValue>{countryLong}</PropertyValue>
        </CompanyContainer>
      </>
    );
  }
}

function mapStateToProps(state) {
  const companyDetails = getCompanyDetails(state);

  return { companyDetails };
}

export default withMedTracker(connect(mapStateToProps, null)(Firma));
