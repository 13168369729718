import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import { AccountCircleOutlined } from '@material-ui/icons';
import React from 'react';
import { AvatarStyled } from '../style';

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={3}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
      minWidth: '40px',
    },
  },
}))(MenuItem);

const StyledIcon = withStyles((theme) => ({
  root: {
    marginLeft: '-2px',
    fontSize: '35px',
  },
}))(AccountCircleOutlined);

export default function CustomizedMenus(props) {
  return (
    <div>
      <ClickAwayListener onClickAway={props.handleClose}>
        <StyledMenu
          id="customized-menu"
          anchorEl={props.anchorEl}
          keepMounted
          open={Boolean(props.anchorEl)}
          onClose={props.handleClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        >
          {props.teamMembersToShow.map((teamMember) => {
            return (
              <StyledMenuItem
                key={teamMember._id}
                onClick={() => props.changeAssignee(teamMember._id)}
              >
                <ListItemIcon>
                  {(teamMember._id && (
                    <AvatarStyled
                      style={{
                        margin: '0',
                      }}
                      userId={teamMember._id}
                    >
                      {teamMember.initials}
                    </AvatarStyled>
                  )) || <StyledIcon />}
                </ListItemIcon>
                {/* <ListItemText primary={teamMember.name} /> */}
                <span>{teamMember.name}</span>
              </StyledMenuItem>
            );
          })}
        </StyledMenu>
      </ClickAwayListener>
    </div>
  );
}
