import { identityInstanceAxios as identity } from '../config';

const FOLLOWUPS_URL = '/pharmaCompany/followups';
const FILE_URL = '/pharmaCompany/file';

/**
 * get followup questions template
 *
 * @returns {Promise} - Result of ajax call
 */
export async function getFollowupsApi(token) {
  // TODO: IE serves cached data for GET, alternate fix is to add a timestamp as query param to url
  // Find out the appropriate way to bust the cache at server for IE.
  // Refs: https://github.com/axios/axios/issues/297#issuecomment-473229366
  // https://stackoverflow.com/questions/45912500/reactjs-ie11-not-making-new-http-request-using-cached-data

  const t = Date.now();
  const URL = `${FOLLOWUPS_URL}?c=${t}`;
  const config = {
    url: URL,
    method: 'get',
    headers: { Authorization: token },
  };

  return identity(config);
}

/**
 * add followup question template
 *
 * @returns {Promise} - Result of ajax call
 */
export async function addFollowupApi(followups, token) {
  const config = {
    url: FOLLOWUPS_URL,
    method: 'post',
    data: { data: followups },
    headers: { Authorization: token },
  };

  return identity(config);
}

/**
 * delete followup question template
 *
 * @returns {Promise} - Result of ajax call
 */
export async function deleteFollowupApi(_id, token) {
  const config = {
    url: FOLLOWUPS_URL,
    method: 'delete',
    params: { _id },
    headers: { Authorization: token },
  };

  return identity(config);
}

/**
 * edit followup question template
 *
 * @returns {Promise} - Result of ajax call
 */
export async function editFollowupApi(followups, token) {
  const config = {
    url: FOLLOWUPS_URL,
    method: 'put',
    data: { data: followups },
    headers: { Authorization: token },
  };

  return identity(config);
}

/**
 * upload followup question file
 *
 * @returns {Promise} - Result of ajax call
 */
export async function uploadFileApi(file, token, uploadProgressCb) {
  const config = {
    url: FILE_URL,
    method: 'post',
    data: file,
    headers: { Authorization: token, 'Content-Type': 'multipart/form-data' },
    onUploadProgress: (progressEvent) => {
      const percentCompleted = Math.round((progressEvent.loaded * 95) / progressEvent.total);
      uploadProgressCb(percentCompleted);
    },
  };

  return identity(config);
}

/**
 * Download followup answer file
 *
 * @returns {Promise} - Result of ajax call
 */
export async function downloadFileApi(fileId, templateId, token) {
  const config = {
    url: `${FILE_URL}/${fileId}`,
    method: 'get',
    responseType: 'blob',
    headers: { Authorization: token },
    params: { templateId },
  };

  return identity(config);
}

/**
 * delete followup question file uploaded previously
 *
 * @returns {Promise} - Result of ajax call
 */
export async function deleteFileApi(fileId, token) {
  const config = {
    url: `${FILE_URL}/${fileId}`,
    method: 'delete',
    headers: { Authorization: token },
  };

  return identity(config);
}
