import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { withMedTracker } from 'services/tracking';
import DetailContainer from './DetailContainer';
import HeaderReport from './HeaderReport';
import QuestionAnswerContainer from './QuestionAnswerContainer';

const ReportDetail = ({
  onSetStep,
  report,
  reportOn,
  step,
  onSetStatus,
  isNR,
  isQuestion,
  onOpenReportModal,
  onSetPatientNotification,
  isPN,
  onQuestionsModal,
  onShowTestProModal,
  onShowRegisterModal,
  onSwitchReport,
  onUpdateReport,
  MedTrackPageView,
}) => {
  useEffect(() => {
    MedTrackPageView('Report Details Page');
  }, []);

  return (
    <div>
      <HeaderReport
        report={report}
        isQuestion={isQuestion}
        onOpenReportModal={onOpenReportModal}
        onQuestionsModal={onQuestionsModal}
        onSetPatientNotification={onSetPatientNotification}
      />
      <QuestionAnswerContainer
        report={report}
        onUpdateReport={onUpdateReport}
        onShowTestProModal={onShowTestProModal}
        onShowRegisterModal={onShowRegisterModal}
      />
      <DetailContainer
        report={report}
        isNR={isNR}
        isPN={isPN}
        isQuestion={isQuestion}
        onSetStatus={onSetStatus}
        onSetStep={onSetStep}
        onSwitchReport={onSwitchReport}
        step={step}
      />
    </div>
  );
};

ReportDetail.propTypes = {
  reportOn: PropTypes.string,
  report: PropTypes.object.isRequired,
  step: PropTypes.string,
  onSetStep: PropTypes.func,
  onSetStatus: PropTypes.func,
  onOpenReportModal: PropTypes.func,
  onSetPatientNotification: PropTypes.func,
  onQuestionsModal: PropTypes.func,
  onShowTestProModal: PropTypes.func.isRequired,
  onShowRegisterModal: PropTypes.func.isRequired,
  onUpdateReport: PropTypes.func.isRequired,
  isNR: PropTypes.bool,
  isQuestion: PropTypes.bool,
  isPN: PropTypes.bool,
  onSwitchReport: PropTypes.func,
  MedTrackPageView: PropTypes.func,
};

export default withMedTracker(ReportDetail);
