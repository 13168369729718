import Header from 'components/Header';
import styled from 'styled-components';
import * as palette from 'styles/palette';

export const Wrapper = styled.div`
  background-color: ${palette.primaryBackground};
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

export const PageHeader = styled(Header)`
  &&& {
    width: 100vw;
  }
`;

export const Content = styled.div`
  width: 100vw;
  height: 100vh;
  background-image: url("${(props) => props.imageUrl}"); 
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ErrorMessageContainer = styled.div`
  text-align: center;
  max-width: 38vw;
  border: 1px solid ${palette.errorRedColor};
  border-radius: 4px;
  line-height: 2em;
  padding: 12px;
  font-size: 1.4rem;
  background-color: ${palette.white};
  font-weight: 400;
`;
export const ErrorMessageTitle = styled.p`
  font-size: 1.4rem;
  font-weight: bold;
  color: ${palette.errorRedColor};
`;
