import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import React from 'react';
import styled from 'styled-components';
import * as palette from 'styles/palette';

export const CustomMenu = styled((props) => <Menu {...props} />)`
  &&& {
    border-radius: 0.7rem;
    margin-top: ${(props) => (props.inStatuses ? '2.3rem' : '4rem')};
    & > div > ul {
      padding-top: ${(props) => (props.inStatuses ? '0' : 'auto')};
      padding-bottom: ${(props) => (props.inStatuses ? '0' : 'auto')};
    }
  }
`;

export const MenuLineItem = styled((props) => <MenuItem {...props} />)`
  &&& {
    display: flex;
    justify-content: space-between;
    padding: 0 15px !important;
    width: 120px;
    border-bottom: 0.5px solid ${palette.lineBGColor};
    &:last-child {
      border-bottom: none;
    }
  }
`;

export const MenuItemTxt = styled.span`
  color: ${palette.primaryDarkGrey};
  opacity: ${(props) => (props.isDisabled ? '0.3' : '1')};
  line-height: 3rem;
  display: inline-flex;
  padding: 5px;
`;

export const StyledButtonContainer = styled.span`
  &:hover {
    opacity: 0.2;
    cursor: pointer;
  }
`;
