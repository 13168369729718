import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import Input from 'lib/elements/Input';
import InputContainer from 'lib/elements/InputContainer';
import MedButton from 'lib/elements/MedButton';
import React from 'react';
import styled from 'styled-components';
import * as palette from 'styles/palette';

export const LabelTxt = styled.div`
  line-height: 1.8rem;
  font-size: 12px;
  color: ${palette.dlgFontColor};
  margin-bottom: 3px;
`;

export const SaveBtn = styled(MedButton)`
  &&& {
    background-color: ${palette.primaryDarkGreen};
    &:hover {
      background-color: ${palette.primaryLightGreen};
    }
  }
`;

export const CancelBtn = styled(MedButton)`
  &&& {
    background-color: ${palette.primaryDarkRed};
    &:hover {
      background-color: ${palette.primaryLightRed};
    }
  }
`;

export const FileUploadContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  .dropzone-container {
    width: 8.5rem !important;
    height: 8.5rem !important;
    border: 0.7rem;
    border-style: none !important;
  }
`;
export const PreviewContainer = styled.div`
  position: absolute;
`;

export const PreviewImg = styled.img`
  width: 8.5rem;
  height: 8.5rem;
  border-radius: 0.7rem;
`;

export const Nothingtext = styled.p`
  color: ${palette.primaryLightGrey};
  text-align: left;
  margin: 0;
  max-width: 164px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const FileUploadComment = styled.div`
  font-size: 0.8rem;
  line-height: 1.1rem;
  color: ${palette.primarySuperLightGrey};
`;

export const UploadInputBox = styled.input`
  position: absolute;
  width: 8.5rem;
  height: 8.5rem;
  opacity: 0;
`;

export const DescriptionContainer = styled.div`
  margin-left: 1rem;
`;

export const FileNameText = styled.div`
  font-size: 0.8rem;
  line-height: 1.1rem;
  color: ${palette.primarySuperLightGrey};
  position: relative;
  padding-right: 1.2rem;
`;

export const FileUploadBtn = styled.div`
  &&& {
    width: 8.5rem;
    height: 8.5rem;
    border-radius: 0.7rem;
    border: 1px solid ${palette.primaryDarkGreen};
    padding: 5px;
    box-sizing: border-box;
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: ${(props) => (props.dotted ? `1px solid ${palette.primaryDarkGreen}` : '0px')};
  border-style: dotted;
  border-radius: 0.7rem;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  position: relative;
`;

export const Description = styled.p`
  font-size: 0.9rem;
  line-height: 1.2rem;
  color: ${palette.dlgFontColor};
  margin: 0.5rem;
  margin-left: 0;
`;

export const ProfileImg = styled.img`
  width: 2.8rem;
  height: 2.2rem;
  position: absolute;
  bottom: 0px;
  right: 4px;
`;

export const FileUploadInnerBtn = styled((props) => <AddIcon {...props} />)`
  border: 3px solid ${palette.primaryDarkGreen};
  color: ${palette.primaryDarkGreen};
  font-size: 2.8rem !important;
  border-radius: 1rem;
`;

export const CircleCloseBtn = styled((props) => <CloseIcon {...props} />)`
  background-color: ${palette.primaryDarkRed};
  width: 1.2rem !important;
  height: 1.2rem !important;
  color: white;
  border-radius: 12px;
  margin-left: 0.5rem;
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  &:hover {
    cursor: pointer;
    background-color: ${palette.primaryLightRed};
  }
`;

export const InputBox = Input.extend`
  margin-top: 0.5rem;
  &::placeholder {
    color: ${palette.textAreaColor} !important;
  }
`;

export const ProfileInputContainer = InputContainer.extend`
  padding-top: 1.5rem;
`;

export const CompanyText = styled.p`
  margin: 0;
  color: ${palette.primaryDarkGrey};
  padding-top: 0.8rem;
`;
