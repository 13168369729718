import { ListItem, ListItemIcon } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import * as palette from 'styles/palette';

export const ListContainer = styled.div`
  box-sizing: border-box;
  width: 8rem;
`;
export const ListIcon = styled((props) => <ListItemIcon {...props} />)`
  &&& {
    margin: 0;
    svg {
      font-size: 2.5rem;
    }
  }
`;

export const ListItems = styled((props) => <ListItem {...props} />)`
  &&& {
    flex-direction: column;
    padding-left: 0;
    padding-right: 0;
    color: ${(props) =>
      props['data-ispageselected'] ? palette.primaryDarkGreen : palette.primaryDarkGrey};
    background-color: transparent;
    ${ListIcon} {
      color: ${(props) =>
        props['data-ispageselected'] ? palette.primaryLightGreen : palette.primaryDarkGrey};
    }
  }

  &&&:hover {
    background-color: transparent;
    color: ${palette.primaryDarkGreen};
    cursor: ${(props) => (!props['data-ispageselected'] ? 'pointer' : 'initial')};

    ${ListIcon} {
      color: ${palette.primaryLightGreen};
    }
  }

  &&& > div {
    min-width: auto !important;
    background-color: transparent;
  }
`;

export const ListText = styled.span`
  display: block;
  overflow-wrap: break-word;
  width: 100%;
  word-wrap: break-word;
  font-size: 1.2rem;
  text-align: center;
  font-weight: 600;
  margin: 1em 0;
`;

export const SidebarLinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto 0px 100px;
`;

export const SidebarLink = styled.div`
  margin: 5px auto;
  a {
    text-decoration: none;
    font-size: 12px;
    &:hover {
      text-decoration: underline;
    }
  }
`;
