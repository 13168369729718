import TableCell from '@material-ui/core/TableCell';
import MedCheckbox from 'lib/elements/Checkbox';
import React from 'react';
import styled from 'styled-components';
import * as palette from 'styles/palette';

export const Container = styled.div`
  width: 100%;
`;

export const LeftDetailViewContainer = styled.div`
  padding: 0 2.8rem;
  padding-top: 1.8rem;
  min-height: 50vh;
`;

export const ItemContainer = styled.div`
  padding: 0.8rem 1.7rem;
`;

export const Title = styled.p`
  padding-top: 30px;
  margin: 0;
  font-size: 1.6rem;
  line-height: 1.9rem;
  color: ${palette.primaryDarkGreen};
  font-weight: 600;
  margin-bottom: -1.5rem;
`;
export const LargeTitle = styled.span`
  font-style: normal;
  font-weight: bold;
  font-size: 1.6rem;
  line-height: 20px;
  color: ${palette.primaryDarkGreen};

  margin-left: -10px;
`;

export const TextLine = styled.div`
  display: flex;
  padding-bottom: 0.7rem;
`;

export const TextLineTitle = styled.p`
  margin: 0;
  color: ${palette.primaryDarkGrey};
  font-weight: 700;
  padding-right: 1rem;
  letter-spacing: 0.2px;
  padding-bottom: 0.7rem;
`;

export const TextLineValue = TextLineTitle.extend`
  font-weight: 200;
`;

export const HeaderCell = styled(({ isUnknown, ...props }) => <TableCell {...props} />)`
  &&& {
    color: ${palette.tableHeaderColor};
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 1.6rem;
    padding: 2.4rem 0.9rem;
    background-color: ${(props) => (props.isUnknown ? `${palette.coldBlue}` : palette.white)};
    text-align: ${(props) => (props.isUnknown ? 'center' : 'left')};
  }
`;

export const BodyCell = styled((props) => <TableCell {...props} />)`
  &&& {
    color: ${palette.tableBodyColor};
    font-size: 1.3rem;
    line-height: 1.4rem;
    padding: 1rem 0.9rem;
  }
`;

export const SuspiciousCheckBox = styled(MedCheckbox)`
  &&& {
    padding-left: 1.4rem;
    & > span,
    svg {
      color: ${palette.primaryDarkGrey};
    }
  }
`;
