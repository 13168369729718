import PropTypes from 'prop-types';
import React from 'react';
import HabitsComment from './HabitsComment';

const HabitsModalContent = ({ count, onAddHabits, onRemoveHabits }) => {
  const a = [];
  for (let i = 0; i < count; i++) {
    a.push(i);
  }
  return (
    <div>
      {a.map((item, index) => (
        <HabitsComment
          index={item}
          key={index}
          onAddHabits={onAddHabits}
          onRemoveHabits={onRemoveHabits}
        />
      ))}
    </div>
  );
};

HabitsModalContent.propTypes = {
  count: PropTypes.number,
  onAddHabits: PropTypes.func,
  onRemoveHabits: PropTypes.func,
};

export default HabitsModalContent;
