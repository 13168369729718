import React from 'react';

import { MatomoProvider, createInstance } from '@datapunt/matomo-tracker-react';

import config from 'config';

export default function Provider(props) {
  const instance = createInstance({
    urlBase: config.MATOMO_URL,
    siteId: config.MATOMO_ENV,
    ...(props.userId && { userId: props.userId }),
  });

  return <MatomoProvider value={instance}>{props.children}</MatomoProvider>;
}
