import background from 'assets/img/medikura-background.png';
import LoginNewPassword from 'components/LoginNewPassword';
import { LoginFormWrapper, LoginHeader, LoginWrapper } from 'containers/LoginContainer/style';
import React, { Component } from 'react';

class LoginNewPasswordContainer extends Component {
  render() {
    return (
      <>
        <LoginWrapper>
          <LoginHeader />
          <LoginFormWrapper imageUrl={background}>
            <LoginNewPassword />
          </LoginFormWrapper>
        </LoginWrapper>
      </>
    );
  }
}

export default LoginNewPasswordContainer;
