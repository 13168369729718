import { isEmpty, isEqual } from 'lodash';
import React, { Component } from 'react';

import config from 'config';
import { withRouter } from 'react-router-dom';

import Provider from './Matomo/provider';

class MedTrackingProvider extends Component {
  static subscriptionData;

  constructor(props) {
    super(props);

    const { userId, companySubscriptionType } = props.config;

    MedTrackingProvider.subscriptionData = {
      isTrackerReady: true,
      isSubscriptionDataReady: !isEmpty(companySubscriptionType),
      subscriptionType: companySubscriptionType,
    };

    this.state = {
      isTrackerReady: true,
      userId: userId,
    };

    if (!config.MATOMO_URL) {
      console.info('Matomo configurations are missing.');
      this.state.isTrackerReady = false;
    }
  }

  static getSubscriptionData = () => {
    return this.subscriptionData;
  };

  componentDidUpdate(prevProps) {
    const { userId, companySubscriptionType } = this.props.config;

    if (!isEqual(prevProps.config.companySubscriptionType, companySubscriptionType)) {
      if (!isEmpty(companySubscriptionType)) {
        MedTrackingProvider.subscriptionData = {
          isTrackerReady: true,
          isSubscriptionDataReady: true,
          subscriptionType: companySubscriptionType,
        };

        this.setState({ isTrackerReady: true, userId: userId });
      }

      // Disabling the tracker on login page
      if (this.props.location.pathname.includes('login') && isEmpty(companySubscriptionType)) {
        // post logout
        this.setState({ isTrackerReady: false });
      }
    }
  }

  render() {
    if (this.state.isTrackerReady) {
      return <Provider userId={this.props.config.userId}>{this.props.children}</Provider>;
    }
    if (!this.state.isTrackerReady) {
      return this.props.children;
    }
  }
}

export default withRouter(MedTrackingProvider);
